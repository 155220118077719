import CircularProgress from "@mui/material/CircularProgress";
import { isRejected } from "@reduxjs/toolkit";
import { ChooseFieldsBlock, Option } from "components/ui/ChooseFieldsBlock";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPatch } from "redux/api/thunks";
import SearchService from "services/SearchService";

import { TabProps } from "../../types";

const CrmMappingTab = ({
  className,
  partnership,
  partner,
}: Omit<TabProps, "saving" | "afterSave" | "setSaveButtonEnabled"> & {
  className?: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedFields, setSelectedFields] = useState<Option[]>([]);
  const { classes, cx } = useStyles();

  const { records: partnershipRawCompanyMappings, loading } = useAllRecords(
    "partnership_rawcompany_mappings",
    {
      filters: { partnership: partnership.id },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSearch = useCallback(
    _.throttle(async (query: string) => {
      const service = SearchService("crm_accounts");
      const result = await service
        .autocomplete(query)
        .perform({ "page[size]": 10 });
      const options = result.records
        .filter(
          (option) =>
            !selectedFields.find((field: Option) => field.id === option.id)
        )
        .map((account) => ({
          id: account.id,
          crmLabel: account.name,
          integration: { provider: account.provider } as Integration,
        }));
      setOptions(options);
    }, 150),
    [selectedFields]
  );

  useEffect(() => {
    if (!loading) {
      const fields = partnershipRawCompanyMappings.map((field) => ({
        id: field.rawCompanyId,
        crmLabel: field.rawCompanyName,
        integration: { provider: field.rawCompanyProvider } as Integration,
      }));
      setSelectedFields(fields);
      throttledSearch("");
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = useCallback(
    async (value: Option[]) => {
      setSelectedFields(value);
      const response = await dispatch(
        rawPatch({
          id: partnership.id,
          type: "partnerships",
          path: "/set-crm-mapping/",
          payload: {
            data: value.map((option) => ({
              type: "crm_accounts",
              id: option.id,
            })),
          },
        })
      );

      if (isRejected(response)) {
        if (response.error.message === "409") {
          pushNotification(i18n.duplicateError);
        } else {
          pushNotification("default_error");
        }
      } else {
        pushNotification({ ...generic.edits_saved });
        track("Edited partnership settings CRM mapping", {
          partner: partner.name,
        });
      }
    },
    [dispatch, partner, partnership, pushNotification, track]
  );

  return loading ? (
    <div className={classes.loader}>
      <CircularProgress size={13} />
    </div>
  ) : (
    <div className={cx(classes.container, className)}>
      <T className={classes.text}>
        <FormattedMessage
          {...i18n.isLoggedAs}
          values={{ partner: partner.name }}
        />
      </T>

      <ChooseFieldsBlock
        allFields={options}
        selectedFields={selectedFields}
        setSelectedFields={(value: Option[]) => {
          handleSave(value);
        }}
        loading={loading}
        addButtonLabel={intl.formatMessage(i18n.placeholder)}
        showReset={false}
        showIntegrationLogo
        hasAsyncSearch
        onAsyncSearch={throttledSearch}
      />
    </div>
  );
};

export const useStyles = makeStyles()((theme) => ({
  loader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    backgroundColor: theme.palette.ivory,
    padding: theme.spacing(2.5),
    rowGap: 10,
  },
  text: {
    color: theme.palette.midnight,
  },
}));

const i18n = defineMessages({
  crmMapping: {
    id: "CrmMappingTab.crmMapping",
    defaultMessage: "CRM mapping",
  },
  description: {
    id: "CrmMappingTab.description",
    defaultMessage: "Link Reveal partner to corresponding CRM account.",
  },
  duplicateError: {
    id: "CrmMappingTab.duplicateError",
    defaultMessage: "This account is already linked to another partner.",
  },
  isLoggedAs: {
    id: "CrmMappingTab.isLoggedAs",
    defaultMessage: "{partner} is logged in my CRM as",
  },
  placeholder: {
    id: "CrmMappingTab.placeholder",
    defaultMessage: "Add CRM account",
  },
});

export default CrmMappingTab;
