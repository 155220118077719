import { defineMessages } from "react-intl";

import Record from "./Record";

const i18n = defineMessages({
  customer: {
    id: "models.Match.customer",
    defaultMessage: "Customer",
  },
  prospect: {
    id: "models.Match.prospect",
    defaultMessage: "Prospect",
  },
  partner: {
    id: "models.Match.partner",
    defaultMessage: "Partner",
  },
  lead: {
    id: "models.Match.lead",
    defaultMessage: "Lead",
  },
});

export type PipelineViewType =
  | typeof Match.PERSPECTIVE_MINE
  | typeof Match.PERSPECTIVE_THEIRS;

export default class Match extends Record<"matches"> {
  static STATUS_CUSTOMER = 1;
  static STATUS_PROSPECT = 2;
  static STATUS_ACTIVE_PROSPECT = 20; // Used only to describe the status of a matched account
  static STATUS_PARTNER = 4;
  static STATUS_LEAD = 5;
  static STATUS_ATTRIBUTE = "status";
  static PRIVATE_ATTRIBUTE = "private";
  static STARRED_ATTRIBUTE = "starred";
  static IS_PRIVATE = true;
  static IS_STARRED = true;
  static PERSPECTIVE_ALL = "all";
  static PERSPECTIVE_MINE = "mine" as const;
  static PERSPECTIVE_THEIRS = "theirs" as const;
  static PARTNER_CUSTOMER = 0;
  static PARTNER_OPPORTUNITY = 1;

  static STATUS_LABELS = {
    [Match.STATUS_CUSTOMER]: i18n.customer,
    [Match.STATUS_PROSPECT]: i18n.prospect,
    [Match.STATUS_PARTNER]: i18n.partner,
    [Match.STATUS_LEAD]: i18n.lead,
  };
}
