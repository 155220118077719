import FiltersBaseItem from "components/ui/filters/FiltersBaseItem";
import FiltersSelectField from "components/ui/filters/FiltersSelectField";
import { T } from "components/ui/Typography";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { defineMessages, useIntl } from "react-intl";
import {
  FieldType,
  FilterType,
  MatchField,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import FilterValueField from "./FilterValueField";

const i18n = defineMessages({
  selectColumn: {
    id: "crm.AccountMapping.Filters.Filter.FilterItem.selectColumn",
    defaultMessage: "Select a column",
  },
  selectType: {
    id: "crm.AccountMapping.Filters.Filter.FilterItem.selectType",
    defaultMessage: "Select a type",
  },
});

const useStyles = makeStyles()((theme) => ({
  margins: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const getDefaultFilter = (
  fieldname: string,
  fields: { [fieldname: string]: FieldType }
) => {
  const newFieldType = fields[fieldname].type;
  const defaultFilterType = MatchField.FILTER_TYPES[newFieldType][0];
  const defaultValue = MatchField.TYPES_DEFAULT_VALUES[newFieldType];
  return { defaultFilterType, defaultValue };
};

type FilterItemProps = {
  isDisabled?: boolean;
  label: string;
  options?: $TSFixMe[];
  index?: number;
  filter?: FilterType;
  fields?: $TSFixMe;
  handleChange: $TSFixMeFunction;
  handleDelete: $TSFixMeFunction;
};

const FilterItem = ({
  isDisabled,
  label,
  options = [],
  index,
  filter,
  fields,
  handleChange,
  handleDelete,
}: FilterItemProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const field = filter && fields?.[filter?.fieldname as string];
  const typeOptions =
    _.isEmpty(filter) || !field?.type
      ? []
      : MatchField.getFilterTypeOptions(
          field.type,
          field.allowedOperations
        ).map((option) => ({
          value: option.value,
          label: isMessageDescriptor(option.label)
            ? intl.formatMessage(option.label)
            : option.label,
        }));

  const handleChangeField = (fieldname: string) => {
    const { defaultFilterType, defaultValue } = getDefaultFilter(
      fieldname,
      fields
    );
    if (_.isUndefined(index)) {
      handleChange(fieldname, defaultFilterType, defaultValue);
    } else {
      handleChange(fieldname, defaultFilterType, defaultValue, index);
    }
  };

  const handleChangeType = (newType: MatchFilterType) => {
    if (!filter) {
      return;
    }
    handleChange(filter.fieldname, newType, filter.value, index);
  };

  const handleChangeValue = (newValue: $TSFixMe) => {
    if (!filter) {
      return;
    }
    handleChange(filter.fieldname, filter.type, newValue, index);
  };

  return (
    <FiltersBaseItem
      isDisabled={isDisabled}
      label={label}
      options={options}
      fieldname={filter?.fieldname}
      placeholder={intl.formatMessage(i18n.selectColumn)}
      handleChange={handleChangeField}
      handleDelete={handleDelete}
    >
      {!_.isEmpty(filter) && (
        <>
          <div className={classes.margins}>
            {typeOptions.length === 1 ? (
              <T>{typeOptions[0].label}</T>
            ) : (
              <FiltersSelectField
                isDisabled={isDisabled}
                value={typeOptions.filter(
                  (option) => option.value === filter!.type
                )}
                options={typeOptions}
                handleChange={handleChangeType}
                placeholder={intl.formatMessage(i18n.selectType)}
              />
            )}
          </div>
          {filter?.type && (
            <FilterValueField
              isDisabled={isDisabled}
              fieldData={fields[filter.fieldname]}
              filter={filter}
              handleChange={handleChangeValue}
            />
          )}
        </>
      )}
    </FiltersBaseItem>
  );
};

export default FilterItem;
