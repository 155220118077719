import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  MessageDescriptor,
} from "react-intl";

type TitleVariant = "small";

type Props = {
  content?: ReactNode | ReactNode[];
  title?: MessageDescriptor;
  titleSmall?: MessageDescriptor;
  titleVariant?: TitleVariant;
  values?: Record<string, number>;
  subtitle?: MessageDescriptor;
  children?: ReactNode | ReactNode[];
  hidePaper?: boolean;
  hideTitle?: boolean;
  rightPanelCentered?: boolean;
  leftImage?: string;
  leftImageLink?: string;
};

const BaseLoginPage = ({
  content,
  title,
  titleSmall,
  titleVariant,
  values,
  subtitle,
  children,
  hidePaper,
  hideTitle = false,
  rightPanelCentered = true,
  leftImage,
  leftImageLink,
}: Props) => {
  const { classes } = useStyles({
    rightPanelCentered,
    titleVariant,
    leftImageLink,
  });

  const imageDiv = (
    <div className={classes.leftImageContainer}>
      <img className={classes.leftImage} src={leftImage} alt="" />
    </div>
  );
  const imageDivLink = (
    <a
      className={classes.link}
      target="_blank"
      href={leftImageLink}
      rel="noopener noreferrer"
    >
      {imageDiv}
    </a>
  );

  return (
    <Grid container className={classes.root}>
      <a className={classes.logoContainer} href={"/"}>
        <img
          className={classes.logo}
          src={"/images/reveal-icon-purple.png"}
          alt=""
        />
        <img
          className={classes.name}
          src={"/images/reveal-logotype-purple.png"}
          alt=""
        />
      </a>
      <Hidden mdDown>
        <Grid item container md={5} sm={false} className={classes.leftPanel}>
          {leftImage ? (
            leftImageLink ? (
              imageDivLink
            ) : (
              imageDiv
            )
          ) : (
            <span>
              {!leftImage && !hideTitle && (
                <T className={classes.title}>
                  <FormattedHTMLMessage
                    {...(title ?? i18n.titleBig)}
                    values={values}
                  />
                </T>
              )}
              {!leftImage && subtitle && (
                <T h2 className={classes.subtitle}>
                  <FormattedHTMLMessage {...subtitle} />
                </T>
              )}
            </span>
          )}
          <Grid container className={classes.leftPanelContent} spacing={1}>
            {content}
          </Grid>
        </Grid>
      </Hidden>
      <Grid item md={7} sm={12} className={classes.rightPanel}>
        {hidePaper ? (
          children
        ) : (
          <>
            <Hidden mdUp>
              {!hideTitle && (
                <T className={classes.title}>
                  <FormattedHTMLMessage
                    {...(titleSmall ?? i18n.titleSmall)}
                    values={values}
                  />
                </T>
              )}
              {hideTitle && subtitle && (
                <T h2 className={classes.subtitle}>
                  <FormattedHTMLMessage {...subtitle} />
                </T>
              )}
            </Hidden>
            <Paper className={classes.paper}>{children}</Paper>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default BaseLoginPage;

export const useStyles = makeStyles<{
  rightPanelCentered: boolean;
  titleVariant?: TitleVariant;
  leftImageLink?: string;
}>()((theme, { rightPanelCentered, titleVariant }) => ({
  root: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.palette.midnight,
    },
  },
  leftPanel: {
    alignItems: "center",
    backgroundColor: theme.palette.midnight,
    flexDirection: "column",
    justifyContent: "center",
    maxHeight: "100vh",
  },
  leftPanelContent: {
    justifyContent: "center",
    padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
  },
  rightPanel: {
    display: "flex",
    justifyContent: "center",
    alignItems: rightPanelCentered ? "center" : "flex-start",
    backgroundColor: theme.palette.offWhite,
    maxHeight: "100vh",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: theme.spacing(10),
      maxHeight: `calc(100vh - ${theme.spacing(10)})`,
      width: "100%",
      backgroundColor: theme.palette.midnight,
    },
  },
  logoContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: 24,
    left: 24,
  },
  logo: {
    width: 23,
    height: 26,
  },
  name: {
    width: 83,
    height: 13,
    marginLeft: 12,
  },
  title: {
    fontFamily: "'FK Roman', serif",
    color: theme.palette.midnight,
    background: !titleVariant
      ? theme.palette.gradients.treeColor
      : theme.palette.offWhite,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontSize: !titleVariant ? 60 : 40,
    lineHeight: !titleVariant ? "66px" : "44px",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: !titleVariant ? 50 : 36,
      lineHeight: !titleVariant ? "55px" : "40px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 32,
      lineHeight: "32px",
      textAlign: "center",
      marginBottom: 40,
    },
  },
  subtitle: {
    color: theme.palette.offWhite,
    fontWeight: 600,
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(14),
    paddingRight: theme.spacing(12),
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    borderRadius: 8,
    backgroundColor: theme.palette.ivory,
    padding: 40,
    [theme.breakpoints.down("md")]: {
      padding: 24,
      width: `calc(100% - 32px)`,
    },
  },
  leftImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  leftImage: {
    width: "auto",
    height: "100%",
  },
  link: {
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
}));

const i18n = defineMessages({
  titleBig: {
    id: "partners.panel.titleBig",
    defaultMessage: "Generate<br/>revenue<br/>through<br/>your<br/>ecosystem",
  },
  titleSmall: {
    id: "partners.panel.titleSmall",
    defaultMessage: "Generate revenue<br/>through your<br/>ecosystem",
  },
});
