import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import usePushNotification from "hooks/usePushNotification";
import useRecord from "hooks/useRecord";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import Record from "models/Record";
import User from "models/User";
import { parse, stringify } from "query-string";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { AccountMappingResource } from "redux/accountMapping/types";
import { rawPost, retreive } from "redux/api/thunks";
import {
  fields as fieldsPartnership,
  included as includedPartnership,
} from "redux/init/constants";
import { selectPartnershipGetIntroIds } from "redux/init/selectors";
import { useGetIntroTemplate } from "screens/Frontoffice/screens/AccountSettings/screens/GetIntroTemplate/hooks/useGetIntroTemplate";
import {
  crmAccountsFields,
  crmAccountsIncluded,
} from "screens/Frontoffice/screens/DataTables/shared/utils";

import {
  IntroRequestSource,
  PartnerAccount,
} from "../../../../screens/Mapping360/types";
import {
  getActiveProspects,
  getCustomers,
} from "../../../../screens/Mapping360/utils";
import { Values } from "../components/RequestIntroDialog";

type Contact = {
  value: string;
  key: string;
  label: string;
};

const useRequestIntroDialogApiLogic = (
  accountId: number,
  fixedPartnershipId: number | null
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const currentSearch = parse(location.search);
  const partnershipGetIntroIds = useSelector(selectPartnershipGetIntroIds);
  const [loading, setLoading] = useState<boolean>(false);
  const [partnershipId, setPartnershipId] = useState<number | null>(null);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [hasCustomQuestions, setHasCustomQuestions] = useState<boolean>(false);
  const onAccountMapping = matchPath(
    location.pathname,
    "/partnerships/:recordId/account-mapping"
  );

  const { record: account } = useRecord("crm_accounts", accountId, false);
  const { record: fixedPartnership } = useRecord(
    "partnerships",
    fixedPartnershipId,
    false
  );
  const { introRequestForm } = useGetIntroTemplate();

  const {
    availablePartners,
    partnerNames,
    ownerNames,
    matchIds,
  } = getFormattedPartners(
    account,
    partnershipGetIntroIds,
    intl,
    profile,
    fixedPartnership
  );

  const handleClose = () => {
    if ("selected-intro" in currentSearch) {
      delete currentSearch["selected-intro"];
      if ("partner-intro" in currentSearch) {
        delete currentSearch["partner-intro"];
      }
      history.push({ ...location, search: stringify(currentSearch) });
    }
  };

  const loadAccount = useCallback(async () => {
    setLoading(true);
    const result = await dispatch(
      retreive({
        id: accountId,
        type: "crm_accounts",
        options: {
          include: crmAccountsIncluded,
          fields: crmAccountsFields,
        },
      })
    );
    if (isRejected(result)) {
      pushNotification("default_error");
    }
    setLoading(false);
  }, [accountId, dispatch, pushNotification]);

  const loadPartnership = useCallback(
    async (partnershipId: number) => {
      setLoading(true);
      const result = await dispatch(
        retreive({
          id: partnershipId,
          type: "partnerships",
          options: {
            include: includedPartnership,
            fields: fieldsPartnership,
          },
        })
      );
      if (isRejected(result)) {
        pushNotification("default_error");
      }
      setLoading(false);
    },
    [dispatch, pushNotification]
  );

  const loadContacts = useCallback(
    async (matchId: number) => {
      const result = await dispatch(
        retreive({
          id: matchId,
          type: AccountMappingResource.matches,
          options: {
            fields: {
              [AccountMappingResource.matches]: ["right_contacts_data"],
            },
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as AxiosResponse;
        const contacts: Contact[] = [];
        _.each(response.data.attributes.right_contacts_data, (contact, i) => {
          contacts.push({
            value: String(i),
            key: contact.provider_keys[0],
            label: contact.jobtitle,
          });
        });
        setContacts(contacts);
      }
    },
    [dispatch]
  );

  const submitGetIntro = useCallback(
    async (values: Values) => {
      const {
        partnershipId: partnershipIdValue,
        contact: contactValues,
        request: requestValue,
        ...dynamicComments
      } = values;
      if (account && partnershipId) {
        const contact: any = {};
        if (values.contact) {
          const contactData = contacts.find(
            (option) => option.value === values.contact
          );
          if (contactData?.key) {
            contact["provider_key"] = contactData.key;
          }
          if (contactData?.label) {
            contact["job_title"] = contactData.label;
          }
        }

        const result = await dispatch(
          rawPost({
            type: "introduction_requests",
            path: "",
            payload: {
              data: {
                type: "introduction_requests",
                attributes: {
                  contact,
                  integration_id: account.integrationId,
                  partnership_id: partnershipId,
                  requested_account_id: account.id,
                  requesting_user_id: profile.id,
                  source: onAccountMapping
                    ? IntroRequestSource.AccountMapping
                    : IntroRequestSource.Mapping360,
                  message: `${values.request}${
                    !_.isEmpty(dynamicComments)
                      ? `\n\n ${getFormattedComment(dynamicComments)}`
                      : ""
                  }`,
                },
              },
            },
          })
        );
        if (isFulfilled(result)) {
          pushNotification({
            status: NotificationStatus.success,
            message: i18n.success,
          });
        } else {
          pushNotification("default_error");
        }
      }
    },
    [
      dispatch,
      pushNotification,
      account,
      contacts,
      onAccountMapping,
      partnershipId,
      profile.id,
    ]
  );

  useEffect(() => {
    loadAccount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fixedPartnershipId !== null) {
      loadPartnership(fixedPartnershipId);
    }
  }, [fixedPartnershipId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (partnershipId !== null) {
      const matchId = matchIds[partnershipId];
      if (matchId) {
        loadContacts(matchId);
      }
      setHasCustomQuestions(
        introRequestForm?.partnershipIds.includes(partnershipId) || false
      );
    }
  }, [introRequestForm, partnershipId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    account,
    availablePartners,
    partnerName: partnershipId ? partnerNames[partnershipId] : null,
    ownerName: partnershipId ? ownerNames[partnershipId] : null,
    contacts,
    partnershipId,
    questions: hasCustomQuestions
      ? introRequestForm?.formData?.questions
      : null,
    setPartnershipId,
    submitGetIntro,
    handleClose,
  };
};

export default useRequestIntroDialogApiLogic;

// Helpers

export const getCustomerFor = (intl: IntlShape, value?: string | null) => {
  if (!value) {
    return "";
  }
  const today = new Date(Date.now());
  const startDate = new Date(value);
  let yearsDiff = today.getFullYear() - startDate.getFullYear();
  let monthsDiff = yearsDiff * 12 + (today.getMonth() - startDate.getMonth());
  if (yearsDiff > 0) {
    return ` ${intl.formatMessage(i18n.years, { count: yearsDiff })}`;
  } else if (monthsDiff > 0) {
    return ` ${intl.formatMessage(i18n.months, { count: monthsDiff })}`;
  }
  return "";
};

const getFormattedComment = (comments: { [x: string]: string | undefined }) =>
  comments
    ? Object.entries(comments)
        .map(([question, answer]) => `${question} \n ${answer}`)
        .join(" \n\n ")
    : "";

const getFormattedPartners = (
  account: Record<"crm_accounts"> | null,
  partnershipGetIntroIds: number[] | null | undefined,
  intl: IntlShape,
  profile: User,
  fixedPartnership: Record<"partnerships"> | null
) => {
  const ownerNames: { [id: number]: string } = {};
  const partnerNames: { [id: number]: string } = {};
  const matchIds: { [id: number]: number } = {};
  if (!account)
    return { availablePartners: [], partnerNames, ownerNames, matchIds };

  const customers = getCustomers(account.matches);
  const prospects = getActiveProspects(account.matches);

  const getPartnerData = (partner: PartnerAccount) => {
    const match = partner.matches[0]; // match with the highest score
    const partnershipId = +match.partnershipId;
    const owner =
      [match.partnershipDestOwner, match.partnershipInitiatorOwner].find(
        (ownerItem) => ownerItem && ownerItem.companyId === profile.company?.id
      )?.fullName ?? null;
    const isFiltered =
      !!partnershipGetIntroIds &&
      !partnershipGetIntroIds.includes(partnershipId);
    const name = partner.partner?.name || partner.ghostPartner?.companyName;

    if (partnershipId && !isFiltered) {
      partnerNames[partnershipId] = name;
      ownerNames[partnershipId] = owner;
      matchIds[partnershipId] = match.id;
    }

    return {
      name,
      partnershipId,
      isFiltered,
    };
  };

  const customerOptions = customers.map((customer) => {
    const { partnershipId, name, isFiltered } = getPartnerData(customer);

    const label =
      intl.formatMessage(i18n.customerLabel, {
        partner: name,
        account: account.name,
      }) + getCustomerFor(intl, customer.firstWonOpportAt);

    return {
      value: String(partnershipId),
      label,
      isFiltered,
    };
  });

  const prospectOptions = prospects.map((prospect) => {
    const { partnershipId, name, isFiltered } = getPartnerData(prospect);

    const label = intl.formatMessage(i18n.prospectLabel, {
      partner: name,
      account: account.name,
    });

    return {
      value: String(partnershipId),
      label,
      isFiltered,
    };
  });

  let availablePartners = [...customerOptions, ...prospectOptions].filter(
    (option) => !option.isFiltered
  );

  if (fixedPartnership) {
    const hasOption = Boolean(
      availablePartners.find(
        (option) => option.value === String(fixedPartnership.id)
      )
    );
    if (!hasOption) {
      const value: string = String(fixedPartnership.id);
      const partner = fixedPartnership.getPartner(profile);
      availablePartners.push({
        value,
        label: partner.name,
        isFiltered: false,
      });
      partnerNames[fixedPartnership.id] = partner.name;
      ownerNames[fixedPartnership.id] =
        fixedPartnership.partnershipOwner?.fullName ?? null;
    }
  }

  return {
    availablePartners,
    partnerNames,
    ownerNames,
    matchIds,
  };
};

/// I18N

const i18n = defineMessages({
  customerLabel: {
    id: "mapping360.useRequestIntroDialogApiLogic.customerLabel",
    defaultMessage: "{partner} -- {account} is a Customer",
  },
  prospectLabel: {
    id: "mapping360.useRequestIntroDialogApiLogic.prospectLabel",
    defaultMessage: "{partner} -- {account} is an Opportunity",
  },
  months: {
    id: "mapping360.useRequestIntroDialogApiLogic.months",
    defaultMessage: "({count} {count, plural, one {month} other {months}})",
  },
  years: {
    id: "mapping360.useRequestIntroDialogApiLogic.years",
    defaultMessage: "({count} {count, plural, one {year} other {years}})",
  },
  success: {
    id: "mapping360.useRequestIntroDialogApiLogic.success",
    defaultMessage: "Your Intro request was successfully sent",
  },
});
