import { guard, nullable, number, object, optional } from "decoders";
import PartnershipOverviewView from "models/PartnershipOverviewView";
import {
  FieldType,
  FilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum OverviewActions {
  initialize = "overview/initialize",
  updateView = "overview/updateView",
  updateAccountMappingView = "overview/updateAccountMappingView",
}

export interface IOverviewResponse {
  common_customers?: IOverviewCustomers;
  common_opportunities?: IOverviewProspects;
  customers_to_prospects?: IOverviewCustomers;
  nb_matched_accounts?: number;
  nb_matched_accounts_not_private?: number;
  open_opportunities_to_customers?: IOverviewProspects;
  prospects_to_customers?: IOverviewProspects;
}

export type IOverviewParameters = {
  shared?: FilterType[];
  common_customers?: FilterType[];
  common_opportunities?: FilterType[];
  common_partners?: FilterType[];
  common_other_prospects?: FilterType[];
  prospects_to_customers?: FilterType[];
  customers_to_prospects?: FilterType[];
  open_opportunities_to_customers?: FilterType[];
};

interface IOverviewCustomers {
  closed_amount: number;
  count: number;
}

interface IOverviewProspects {
  pipeline_amount: number;
  open_deals?: number | null;
  count: number;
  count_not_private?: number | null;
  active_count?: number | null;
}

interface IKPIState<T = IOverviewCustomers | IOverviewProspects | number> {
  value?: T;
  error: boolean;
  loading: boolean;
}

export interface IOverviewState {
  accountOverlap?: number;
  contactOverlap?: number;
  nbMatchedAccounts?: number;
  commonCustomers: IKPIState<IOverviewCustomers>;
  commonOpenOpportunities: IKPIState<IOverviewProspects>;
  prospectToCustomers: IKPIState<IOverviewProspects>;
  customerToProspects: IKPIState<IOverviewCustomers>;
  openOpportunitiesToCustomers: IKPIState<IOverviewProspects>;
}

export interface IPartnershipOverviewState {
  views: {
    [partnershipId: number]: {
      state: IOverviewState;
      view?: PartnershipOverviewView;
      fields: Record<string, FieldType>;
    };
  };
}

export interface RootStateWithOverview {
  overview: IPartnershipOverviewState;
}

export enum SharedFilters {
  all = "all",
  strategic = "strategic",
}

/**
 * Type guards
 */

const customer = object({
  count: number,
  closed_amount: number,
});

const prospect = object({
  active_count: optional(nullable(number)),
  count: number,
  count_not_private: optional(nullable(number)),
  open_deals: optional(nullable(number)),
  pipeline_amount: number,
});

const overviewResponseSchema = object({
  common_customers: optional(customer),
  customers_to_prospects: optional(customer),
  common_opportunities: optional(prospect),
  prospects_to_customers: optional(prospect),
  nb_matched_accounts: optional(number),
  nb_matched_accounts_not_private: optional(number),
  open_opportunities_to_customers: optional(prospect),
});

export const overviewResponseGuard = guard<IOverviewResponse>(
  overviewResponseSchema
);
