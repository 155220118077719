import { Box, Divider } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { Check, Plus } from "components/icons";
import { QuickViewFilter } from "components/ui/BaseQuickViews";
import Button from "components/ui/Button";
import FiltersPopover from "components/ui/filters/FiltersPopover";
import {
  ExtendedAccountMappingResource,
  SmartViewSegmentEvent,
  ThreeSixty,
} from "components/ui/filters/smartView/constants";
import { useSmartViewTableConfig } from "components/ui/filters/smartView/helpers";
import SmartViewCreateDialog from "components/ui/filters/smartView/SmartViewCreateDialog";
import {
  ColumnOption,
  getAvailableColumOptions,
} from "components/ui/filters/utils";
import { PayingFeatureTooltip } from "components/ui/PayingFeatureTooltip";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import { useSmartViews } from "hooks/useSmartViews";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import SavedFilter from "models/SavedFilter";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import { AvailablePipelineAttributeColumns } from "redux/pipelineAttribute/defaults";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { asJSONSerializable } from "redux/typing";
import DisplayOnlyNewUpdatesSince from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/DisplayOnlyNewUpdatesSince";
import { DisplaySinceFilterFieldname } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import MappingFilterContext from "screens/Frontoffice/screens/DataTables/shared/contexts/MappingFilterContext";
import {
  DataTableType,
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { PremiumPlanEvent, TableEvent } from "tracking";

import FilterItem from "./FilterItem";

type Props = {
  defaultFilters?: FilterType[]; // defaults uneditable/disabled filters in the widgets (ie: 360 goals)
  defaultFilterTooltip?: MessageDescriptor;
  disabled?: boolean;
  partnerName?: ReactElement | string;
  fields?: $TSFixMe;
  filters: FilterType[];
  onFilterUpdate: (filters: FilterType[]) => void;
  openedWidget?: string | null;
  setOpenedWidget: (filterType: string | null) => void;
  debounce?: number;
  hideEmptyText?: boolean;
  title?: ReactElement | string;
  filterType?: string;
  disableLeftAndRightFilters?: boolean;
  viewType: DataTableType;
  accountType?: ExtendedAccountMappingResource;
  partnershipId?: number;
  isSmall?: boolean;
  isDemo?: boolean;
  isGhost?: boolean;
};

export const FilterWidget = ({
  defaultFilters = [],
  defaultFilterTooltip,
  disabled,
  partnerName,
  fields,
  filters,
  onFilterUpdate,
  openedWidget,
  setOpenedWidget,
  debounce = 300,
  hideEmptyText = false,
  title = "",
  filterType = "filter",
  disableLeftAndRightFilters = false,
  viewType,
  accountType,
  partnershipId,
  isSmall = false,
  isDemo = false,
  isGhost = false,
  ...rest
}: Props) => {
  const { classes, cx } = useStyles();
  const { classes: popoverClasses } = usePopoverStyles();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const [shouldCompareFilters, setShouldCompareFilters] = useState(false);
  const {
    unorderedQuickFilters,
    quickFilterPresets,
    selectedSmartViewId,
    setSelectedSmartViewId,
    defaultView,
  } = useContext(MappingFilterContext);
  const intl = useIntl();
  const { view } = useSmartViewTableConfig({
    accountType: accountType as ExtendedAccountMappingResource,
  });
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { records: smartViews, totalCount, updateView } = useSmartViews(
    accountType as ExtendedAccountMappingResource,
    partnershipId
  );
  const isAMOverlap = accountType === AccountMappingResource.matches;
  // Some filters are used in backend but we don not want them displayed in the widget
  // So we separate them because their logic is handle elsewhere (ie: pipeline)
  const filterFieldnamesNotInWidget = [
    "archived",
    "perspective",
    "date_range",
    ...(viewType === DataTableType.COLLABORATE
      ? [
          "participant_user_id",
          AvailablePipelineColumns.rawCompanyOwnerId,
          AvailablePipelineColumns.partnership,
        ]
      : []),
    ...(viewType === DataTableType.ATTRIBUTE
      ? [
          AvailablePipelineAttributeColumns.status,
          "partner_attribution",
          AvailablePipelineAttributeColumns.owner,
          "partnership",
        ]
      : []),
    ...Object.values(DisplaySinceFilterFieldname),
    ...(accountType === ThreeSixty.nearboundAccounts
      ? ["open_deals_count"]
      : []),
  ];
  const isViewTypePipeline = [
    DataTableType.ATTRIBUTE,
    DataTableType.COLLABORATE,
  ].includes(viewType);

  const filtersInWidget = filters?.filter(
    (f) => !filterFieldnamesNotInWidget.includes(f.fieldname)
  );
  const filtersNotInWidget = filters?.filter((f) =>
    filterFieldnamesNotInWidget.includes(f.fieldname)
  );

  const displaySinceFilter = useMemo(
    () =>
      isAMOverlap
        ? filtersNotInWidget.filter((filter) =>
            (Object.values(DisplaySinceFilterFieldname) as string[]).includes(
              filter.fieldname
            )
          )
        : [],
    [filtersNotInWidget, isAMOverlap]
  );

  const filtersWithoutDefaults = useMemo(
    () => [
      ...filtersInWidget.filter(
        (item) =>
          !defaultFilters.some(
            (defaultFilter) => defaultFilter.fieldname === item.fieldname
          )
      ),
      // Add additional filters for selectedItemsCount/savedView button logic below
      ...displaySinceFilter,
    ],
    [filtersInWidget, defaultFilters, displaySinceFilter]
  );

  const isSmartListProUnlocked = payingFeatures.includes(
    PayingFeature.SavedFiltersPRO
  );
  const isSmartListPowerUnlocked = payingFeatures.includes(
    PayingFeature.SavedFiltersPOWER
  );
  const isLimitReached = isSmartListPowerUnlocked
    ? false
    : isSmartListProUnlocked
    ? totalCount >= 5
    : totalCount >= 1;

  const [addingFilter, setAddingFilter] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEqualToAnotherView, setIsEqualToAnotherView] = useState(false);

  let combinedFilterList: (SavedFilter | QuickViewFilter)[] = [
    ...unorderedQuickFilters,
    ...smartViews,
  ];
  const selectedSmartView = combinedFilterList.find(
    (record) =>
      ("id" in record ? record.id : record.key) === selectedSmartViewId
  );
  const getSelectedViewFilters = (
    selectedViewFilters: SavedFilter | QuickViewFilter | undefined
  ) =>
    selectedViewFilters && "presets" in selectedViewFilters
      ? selectedViewFilters.presets.filter
      : selectedViewFilters?.filters;

  const hasChanges =
    !_.isEqual(
      filtersWithoutDefaults,
      getSelectedViewFilters(
        combinedFilterList.find(
          (filter) =>
            ("id" in filter ? filter.id : filter.key) === selectedSmartViewId
        )
      )
    ) ||
    !_.isEqual(
      selectedSmartView && "presets" in selectedSmartView
        ? selectedSmartView?.presets.sort
        : selectedSmartView?.sort,
      view?.sort
    );

  const hasPermission =
    (selectedSmartView as SavedFilter)?.userId === profile.id;

  const getColumOptions = (selectedValue: FilterType | null = null) => {
    const options = getAvailableColumOptions(
      partnerName,
      fields,
      filters,
      selectedValue,
      "filter",
      defaultFilters,
      defaultView?.name && (
        <Box>
          <FormattedMessage
            {...i18n.disabledItemTooltip}
            values={{ goal: intl.formatMessage(defaultView.name ?? {}) }}
          />
          &nbsp;"{defaultView.description}"
        </Box>
      )
    );
    return options;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnFilterUpdate = useCallback(
    _.debounce(onFilterUpdate, debounce),
    [onFilterUpdate]
  );

  const handleOpenWidget = (filterType: string | null) => {
    if (filterType) {
      track(TableEvent.openFilterWidget, { location: viewType });
    }
    setOpenedWidget(filterType);
    setShouldCompareFilters(filterType ? true : false);
  };

  const handleCloseWidget = () => {
    setOpenedWidget(null);
    setShouldCompareFilters(false);
  };

  const handleAddView = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    track(SmartViewSegmentEvent.SaveViewClicked, {
      isPaidUser: profile.isPaidCustomer(payingFeatures),
    });
    setIsCreateDialogOpen(true);
  };

  const addFilter = (fieldname: string, type: MatchFilterType, value: any) => {
    onFilterUpdate([...filters, { fieldname, type, value }]);
    setAddingFilter(false);
  };

  const updateFilter = (
    fieldname: string,
    type: MatchFilterType,
    value: any,
    index: number
  ) => {
    const newFilters = [...filtersInWidget];
    newFilters[index] = { fieldname, type, value };
    const handler =
      value !== filters[index]?.value
        ? debouncedOnFilterUpdate
        : onFilterUpdate;
    handler([...filtersNotInWidget, ...newFilters]);
  };

  const deleteFilter = (fieldname: string) => {
    const newFilters = filtersInWidget.filter(
      (item) => item.fieldname !== fieldname
    );
    onFilterUpdate([...filtersNotInWidget, ...newFilters]);
  };

  const disableWithTooltip = prepareDisableWithTooltipWith(filters);

  const openPremiumWidget = () => {
    dispatch(toggleFree(false));
    dispatch(togglePro(!isSmartListProUnlocked));
    dispatch(togglePower(!isSmartListPowerUnlocked));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "notify on smart view dropdown",
    });
  };

  const handleSelectSmartView = useCallback(
    (id?: number) => {
      setSelectedSmartViewId?.(id);
    },
    [setSelectedSmartViewId]
  );

  const handleSaveChanges = async () => {
    if (!selectedSmartViewId) {
      return;
    }
    const result = await updateView(selectedSmartViewId, {
      filters: asJSONSerializable(filtersWithoutDefaults),
      sort: view?.sort,
    });
    if (isFulfilled(result)) {
      pushNotification(i18n.updatedFilter);
    }
  };

  const sharedProps = (
    filter: FilterType,
    index: number,
    fieldname: string
  ) => ({
    label: intl.formatMessage(
      index - defaultFilters.length === 0 ? i18n.where : i18n.and
    ),
    options: disableWithTooltip(
      getColumOptions(filter),
      disableLeftAndRightFilters && filters && filters.length > 1
    ),
    index,
    filter,
    fields,
    handleChange: updateFilter,
    handleDelete: () => deleteFilter(fieldname),
  });

  const filterItemsInWidget = filtersWithoutDefaults.map((item, index) => {
    if (displaySinceFilter.includes(item)) return null;
    const updatedIndex =
      index +
      defaultFilters.filter(
        (defaultFilter) =>
          !filterFieldnamesNotInWidget.includes(defaultFilter.fieldname)
      ).length;
    return (
      <FilterItem
        key={`filter-item-${updatedIndex}`}
        {...sharedProps(item, updatedIndex, item.fieldname)}
      />
    );
  });

  useEffect(() => {
    if (!_.isEmpty(fields) && filters.length > 0) {
      const newFilters: FilterType[] = [];
      _.each(filters, (item) => {
        if (
          item.fieldname in fields ||
          filterFieldnamesNotInWidget.includes(item.fieldname)
        ) {
          newFilters.push(item);
        }
      });

      if (!_.isEqual(filters, newFilters)) {
        onFilterUpdate(newFilters);
      }
    }
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewType !== DataTableType.ANALYTICS) {
      const is360Mapping =
        accountType === ThreeSixty.threeSixty ||
        accountType === ThreeSixty.nearboundProspects ||
        accountType === ThreeSixty.nearboundAccounts;
      const presets = is360Mapping
        ? Object.values(quickFilterPresets)
        : quickFilterPresets;
      const isEqualToAnotherQuickFilterPreset = presets?.some(
        (quickFilterPreset: any) =>
          _.isEqual(
            is360Mapping
              ? quickFilterPreset.filter
              : quickFilterPreset.presets.filter,
            filtersWithoutDefaults
          )
      );
      const isEqualToAnotherSmartView = smartViews.some((smartView) =>
        _.isEqual(smartView.filters, filtersWithoutDefaults)
      );
      setIsEqualToAnotherView(
        isEqualToAnotherQuickFilterPreset || isEqualToAnotherSmartView
      );
    }
  }, [
    viewType,
    quickFilterPresets,
    smartViews,
    filtersWithoutDefaults,
    accountType,
  ]);

  // when filters match a smart view, select it
  useEffect(() => {
    if (!shouldCompareFilters) {
      return;
    }
    if (filtersWithoutDefaults.length === 0) {
      const matchingDefaultView = unorderedQuickFilters.find(
        (quickFilter) =>
          quickFilter.presets.filter.length === 0 &&
          quickFilter.viewType === accountType
      );
      if (!matchingDefaultView) {
        return;
      }
      setSelectedSmartViewId(Number(matchingDefaultView.key));
      return;
    }
    const matchingSmartView = smartViews.find((smartView) =>
      _.isEqual(smartView.filters, filtersWithoutDefaults)
    );
    const matchingDefaultView = unorderedQuickFilters.find((quickFilter) =>
      _.isEqual(quickFilter.presets.filter, filtersWithoutDefaults)
    );
    if (!matchingSmartView && !matchingDefaultView) {
      return;
    }
    const id = matchingSmartView?.id || matchingDefaultView?.key;
    if (!id) {
      return;
    }
    setSelectedSmartViewId(Number(id));
  }, [
    filtersWithoutDefaults,
    setSelectedSmartViewId,
    smartViews,
    unorderedQuickFilters,
    shouldCompareFilters,
    accountType,
  ]);

  return (
    <>
      <FiltersPopover
        placement="right"
        disabled={disabled}
        filterType={filterType}
        openedWidget={openedWidget}
        setOpenedWidget={handleOpenWidget}
        selectedItemsCount={filtersWithoutDefaults.length}
        classes={popoverClasses}
        {...rest}
      >
        {title && (
          <T color={muiTheme.palette.midnight} className={classes.title}>
            {title}
          </T>
        )}
        {!hideEmptyText && filtersInWidget.length === 0 && !addingFilter && (
          <T
            // @ts-expect-error
            color={muiTheme.palette.grey.darkBlue}
            className={classes.emptyTitle}
          >
            <FormattedMessage {...i18n.emptyText} />
          </T>
        )}

        {defaultFilters.length > 0 && (
          <div
            className={cx(
              classes.filterContainer,
              classes.defaultFilterContainer
            )}
          >
            <Tooltip
              title={
                defaultFilterTooltip && (
                  <T>
                    <FormattedMessage {...defaultFilterTooltip} />
                  </T>
                )
              }
            >
              <T className={classes.defaultFiltersTitle}>
                {defaultView?.description}
              </T>
            </Tooltip>
          </div>
        )}

        <div className={classes.filterContainer}>
          {filterItemsInWidget}
          {addingFilter && (
            <FilterItem
              label={intl.formatMessage(
                filtersWithoutDefaults.length === 0 ? i18n.where : i18n.and
              )}
              options={disableWithTooltip(
                getColumOptions(null),
                disableLeftAndRightFilters &&
                  filtersInWidget &&
                  filtersInWidget.length > 0
              )}
              fields={fields}
              handleChange={addFilter}
              handleDelete={() => setAddingFilter(false)}
            />
          )}
        </div>
        {!addingFilter && (
          <div className={classes.button}>
            <Button
              disabled={addingFilter}
              onClick={() => setAddingFilter(true)}
              LeftIcon={Plus}
              label={i18n.addBtn}
              size="small"
              variant="quinary"
            />
          </div>
        )}

        {viewType === DataTableType.ACCOUNT_MAPPING && !isGhost && (
          <>
            <Divider className={classes.divider} />
            <DisplayOnlyNewUpdatesSince
              isOverlapView={accountType === AccountMappingResource.matches}
              filters={filters}
              onToggleChange={onFilterUpdate}
              isDemo={isDemo}
            />
          </>
        )}

        {viewType !== DataTableType.ANALYTICS && !isViewTypePipeline && (
          <>
            <Divider className={classes.divider} />
            <Box display="flex" justifyContent="space-between">
              <Box>
                {hasPermission && (
                  <span className={classes.saveCurrentBtnContainer}>
                    <Button
                      size="small"
                      LeftIcon={Check}
                      label={intl.formatMessage(i18n.updateCurrentView)}
                      variant={"tertiary"}
                      onClick={handleSaveChanges}
                      disabled={!hasChanges || isEqualToAnotherView}
                    />
                  </span>
                )}
                <PayingFeatureTooltip
                  active={isLimitReached}
                  message={i18n.smartListPayingFeatureTooltip}
                  onClick={openPremiumWidget}
                  placement="top"
                >
                  <span>
                    <Button
                      size="small"
                      LeftIcon={Plus}
                      label={i18n.saveAsNewView}
                      onClick={handleAddView}
                      variant={
                        isLimitReached || !hasChanges || isEqualToAnotherView
                          ? "tertiary"
                          : "primary"
                      }
                      disabled={
                        isLimitReached || !hasChanges || isEqualToAnotherView
                      }
                    />
                  </span>
                </PayingFeatureTooltip>
              </Box>
              <Button
                size="small"
                label={intl.formatMessage(i18n.close)}
                variant={"quinary"}
                onClick={handleCloseWidget}
              />
            </Box>
          </>
        )}
      </FiltersPopover>
      {isCreateDialogOpen && (
        <SmartViewCreateDialog
          accountType={accountType as ExtendedAccountMappingResource}
          isOpen={isCreateDialogOpen}
          onAddSmartView={handleSelectSmartView}
          onClose={() => setIsCreateDialogOpen(false)}
          partnershipId={partnershipId}
          selectedFilterId={selectedSmartViewId}
        />
      )}
    </>
  );
};

// HELPERS

const prepareDisableWithTooltipWith = (filters: FilterType[]) => (
  options: ColumnOption[],
  shouldDisable?: boolean
) => {
  if (!shouldDisable) {
    return options;
  }

  const LEFT = "left";
  const RIGHT = "right";

  const firstLeftOrRightFilter = filters.find(
    (filter) =>
      filter.fieldname.startsWith(LEFT) || filter.fieldname.startsWith(RIGHT)
  );

  const fieldname = firstLeftOrRightFilter?.fieldname;
  let disableSide: string | undefined, msg: MessageDescriptor;

  if (fieldname?.startsWith(LEFT)) {
    disableSide = RIGHT;
    msg = i18n.partnerOptionsDisabledTooltip;
  }

  if (fieldname?.startsWith(RIGHT)) {
    disableSide = LEFT;
    msg = i18n.yourOptionsDisabledTooltip;
  }

  return options.map((option) => {
    if (!disableSide) {
      return option;
    }

    if (
      option.value.startsWith(disableSide) ||
      // Always disable `perspective` if any other filter is active;
      // It's either redundant or will show no results.
      (disableSide && option.value === "perspective")
    ) {
      option.isDisabled = true;
      option.label = (
        <Tooltip title={<FormattedMessage {...msg} />} placement="right">
          {option.label}
        </Tooltip>
      );
    }

    return option;
  });
};

// CSS

const useStyles = makeStyles()((theme) => ({
  defaultFilterContainer: {
    background: theme.palette.greyLight050,
    borderRadius: 6,
    height: 36,
    justifyContent: "center",
    padding: "6px 12px",
  },
  defaultFiltersTitle: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "18px",
  },
  title: {
    paddingLeft: theme.spacing(1),
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    rowGap: 0,
  },
  button: {
    marginTop: 4,
  },
  saveCurrentBtnContainer: {
    marginRight: 4,
  },
  emptyTitle: {
    padding: theme.spacing(1),
  },
  divider: {
    margin: "8px 0",
  },
}));

const usePopoverStyles = makeStyles()((theme) => ({
  childrenContainer: {
    minWidth: 700,
    padding: 12,
  },
}));

// I18N

const i18n = defineMessages({
  where: {
    id: "crm.AccountMapping.Filter.FilterWidget.where",
    defaultMessage: "Where",
  },
  and: {
    id: "crm.AccountMapping.Filter.FilterWidget.and",
    defaultMessage: "And",
  },
  addBtn: {
    id: "crm.AccountMapping.Filter.FilterWidget.addBtn",
    defaultMessage: "Add filter",
  },
  disabledItemTooltip: {
    id: "crm.AccountMapping.Filter.FilterWidget.disabledItemTooltip",
    defaultMessage: 'The "{goal}" goal is pre-filtered to show ',
  },
  emptyText: {
    id: "crm.AccountMapping.Filter.FilterWidget.emptyText",
    defaultMessage: "No filters applied to this view",
  },
  yourOptionsDisabledTooltip: {
    id: "crm.AccountMapping.Filter.FilterWidget.yourOptionsDisabledTooltip",
    defaultMessage:
      "Remove the filters on partner columns to be able to filter your columns.",
  },
  partnerOptionsDisabledTooltip: {
    id: "crm.AccountMapping.Filter.FilterWidget.partnerOptionsDisabledTooltip",
    defaultMessage:
      "Remove the filters on your columns to be able to filter partner columns.",
  },
  smartListPayingFeatureTooltip: {
    defaultMessage:
      "You've reached your workspace limit. Upgrade to unlock additional saved views.",
    id: "SmartViewDropdown.smartListPayingFeatureTooltip",
  },
  saveAsNewView: {
    defaultMessage: "Save as new view",
    id: "SmartViewDropdown.saveAsNewView",
  },
  updateCurrentView: {
    defaultMessage: "Update current view",
    id: "SmartViewDropdown.updateCurrentView",
  },
  updatedFilter: {
    defaultMessage: "Updated filter",
    id: "SmartViewDropdown.updatedFilter",
  },
  all: {
    id: "crm.Ecosystem.QuickViews.all",
    defaultMessage: "All",
  },
  prospectsNoOpports: {
    id: "crm.Ecosystem.QuickViews.prospectsNoOpports",
    defaultMessage: "Prospects without opportunity",
  },
  prospectsOpports: {
    id: "crm.Ecosystem.QuickViews.prospectsOpports",
    defaultMessage: "Prospects with opportunity",
  },
  prospects: {
    id: "crm.Ecosystem.QuickViews.prospects",
    defaultMessage: "Prospects",
  },
  customers: {
    id: "crm.Ecosystem.QuickViews.customers",
    defaultMessage: "Customers",
  },
  close: {
    id: "SmartViewDropdown.close",
    defaultMessage: "Close",
  },
});
