import Grid from "@mui/material/Grid";
import OktaGoogleButton from "components/okta/OktaGoogleButton";
import { makeStyles } from "makeStyles";
import wrapper from "styles/wrapper";

const useStyles = makeStyles()((theme) => ({
  loginContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 90,
  },
  ...wrapper(theme),
}));

export const LoginBackoffice = () => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.loginContainer}>
      <Grid item className={classes.wrapper}>
        <OktaGoogleButton />
      </Grid>
    </Grid>
  );
};

export default LoginBackoffice;
