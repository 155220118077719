import {
  GridCellCheckboxRenderer,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-premium";
import { AmountFormatter } from "hooks/useAmountFormatter";
import _ from "lodash";
import Partnership from "models/Partnership";
import { IntlShape } from "react-intl";
import { AccountMappingResource } from "redux/accountMapping/types";
import { AdditionalAnalyticsColumns } from "redux/analytics/defaults";
import { AvailableDirectoryColumns } from "redux/directory/defaults";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import {
  AvailableNearboundAccountsColumns,
  PARTNER_PRESENCE_DEFAULT_WIDTH,
} from "redux/mapping360NearboundAccounts/defaults";
import { AvailableReferredAccountsColumns } from "redux/notificationWidget/defaults";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import { DisplaySinceFilterFieldname } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import { fieldFormatter } from "screens/Frontoffice/screens/DataTables/shared/fieldFormatter";
import {
  DataTableType,
  FieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import AmActionsCell, {
  isAmActionsCell,
} from "./cellRenderers/AmActionsCell/components/AmActionsCell";
import AccountMappingCell, {
  getAccountMappingCopyContent,
  isAccountMappingCell,
} from "./cellRenderers/analytics/AccountMappingCell";
import DealSizeCell, {
  isDealSizeCell,
} from "./cellRenderers/analytics/DealSizeCell";
import FavoriteCell, {
  isFavoriteCell,
} from "./cellRenderers/analytics/FavoriteCell";
import PartnerNameCell, {
  isPartnerNameCell,
} from "./cellRenderers/analytics/PartnerNameCell";
import RedirectCell, {
  isRedirectCell,
} from "./cellRenderers/analytics/RedirectCell";
import TagsCell, {
  DEFAULT_WIDTH as tagsDefaultWidth,
  getTagsCopyContent,
  isTagsCell,
} from "./cellRenderers/analytics/TagsCell";
import WinRateCell, {
  isWinRateCell,
} from "./cellRenderers/analytics/WinRateCell";
import CompanyNameCell, {
  isCompanyNameCell,
} from "./cellRenderers/CompanyNameCell";
import {
  AccountOverlapCell,
  isDirectoryAccountOverlapCell,
} from "./cellRenderers/directory/AccountOverlapCell";
import {
  CompanyNameCell as DirectoryCompanyNameCell,
  isDirectoryCompanyNameCell,
} from "./cellRenderers/directory/CompanyNameCell";
import {
  ContactOverlapCell,
  isDirectoryContactOverlapCell,
} from "./cellRenderers/directory/ContactOverlapCell";
import {
  isDirectoryMarketPresenceCell,
  MarketPresenceCell,
} from "./cellRenderers/directory/MarketPresenceCell";
import {
  isDirectoryPartnerCell,
  PartnerCell as DirectoryPartnerCell,
} from "./cellRenderers/directory/PartnerCell";
import HiddenCell from "./cellRenderers/HiddenCell";
import LeftContactsCell, {
  isLeftContactsCell,
} from "./cellRenderers/LeftContactsCell";
import LockedCell, { isLockedCell } from "./cellRenderers/LockedCell";
import {
  isMapping360ActionCell,
  Mapping360ActionCell,
} from "./cellRenderers/Mapping360ActionCell";
import {
  isMapping360NearboundProspectsActionCell,
  Mapping360NearboundProspectsActionCell,
} from "./cellRenderers/Mapping360ActionCell/components/Mapping360NearboundProspectsActionCell";
import MissingValueCell, {
  isMissingValueCell,
} from "./cellRenderers/MissingValueCell";
import {
  getNearboundProspectCustomerCopyContent,
  isNearboundProspectCustomerCell,
  NearboundProspectCustomerCell,
} from "./cellRenderers/NearboundProspectCustomerCell";
import OpenDealCell, {
  getOpenDealCopyContent,
  getOpenDealSide,
  hasNewOpportunity,
  isOpenDealCell,
} from "./cellRenderers/OpenDealCell";
import OwnerCell, { isOwnerCell } from "./cellRenderers/OwnerCell";
import { isOwnersCell, OwnersCell } from "./cellRenderers/OwnersCell";
import {
  isParticipantsCell,
  ParticipantsCell,
} from "./cellRenderers/ParticipantsCell";
import PartnerPresenceCell, {
  getPartnerPresenceCopyContent,
  isPartnerPresenceCell,
} from "./cellRenderers/PartnerPresenceCell";
import PartnerPresenceCellNearboundAccounts, {
  isPartnerPresenceCellNearboundAccounts,
} from "./cellRenderers/PartnerPresenceCellNearboundAccounts";
import PartnerSignalsCell, {
  isPartnerSignalsCell,
} from "./cellRenderers/PartnerSignalsCell";
import {
  ActivityDateCell,
  isActivityDateCell,
} from "./cellRenderers/pipeline/ActivityDateCell";
import {
  CategoryCell,
  isPipelineCategoryCell,
} from "./cellRenderers/pipeline/CategoryCell";
import {
  CommentCell,
  isCommentCell,
} from "./cellRenderers/pipeline/CommentCell";
import {
  CompanyNameCell as PipelineCompanyNameCell,
  isPipelineCompanyNameCell,
} from "./cellRenderers/pipeline/CompanyNameCell";
import KindCell, { isKindCell } from "./cellRenderers/pipeline/KindCell";
import {
  isPipelineOpportunityCell,
  OpportunityCell,
} from "./cellRenderers/pipeline/OpportunityCell";
import {
  isPartnerDiscussionCell,
  PartnerDiscussionsCell,
} from "./cellRenderers/pipeline/PartnerDiscussionsCell";
import {
  isPipelineStatusCell,
  PipelineStatusCell,
} from "./cellRenderers/pipeline/PipelineStatusCell";
import StageCell, { isStageCell } from "./cellRenderers/pipeline/StageCell";
import RightContactsCell, {
  isRightContactsCell,
} from "./cellRenderers/RightContactsCell";
import { getBoostCellCopyContent } from "./cellRenderers/shared/BoostCell";
import StatusCell, {
  getStatusSide,
  hasStatusChanged,
  isStatusCell,
} from "./cellRenderers/StatusCell";
import StrategicAccountCell, {
  isStrategicAccountCell,
} from "./cellRenderers/StrategicAccountCell";
import {
  baseMultiplierValueFormatter,
  isBaseMultiplierValue,
} from "./valueFormatters/BaseMultiplierValueFormatter";
import {
  dateIndentifiedOnFormatter,
  isDateIdentifiedOnColumn,
} from "./valueFormatters/DateIdentifiedOnValueFormatter";
import { dateValueToLocaleDateStringFormatter } from "./valueFormatters/DateValueToLocaleDateStringFormatter";
import {
  dealInfluencerFormatter,
  isDealInfluencerColumn,
} from "./valueFormatters/DealInfluencerValueFormatter";
import {
  directoryBusinessTypeValueFormatter,
  isDirectoryBusinessTypeColumn,
} from "./valueFormatters/DirectoryBusinessTypeValueFormatter";
import {
  directoryEcosystemValueFormatter,
  isDirectoryEcosystemColumn,
} from "./valueFormatters/DirectoryEcosystemValueFormatter";
import {
  isPartnerAnalyticMetrics,
  partnerAnalyticMetricValueFormatter,
} from "./valueFormatters/PartnerAnalyticMetricValueFormatter";
import {
  isPartnerAnalyticUserColumn,
  partnerAnalyticUserValueFormatter,
} from "./valueFormatters/PartnerAnalyticUserFormatter";
import {
  isPartnerAnalyticsPercentageValue,
  partnerAnalyticPercentageValueFormatter,
} from "./valueFormatters/PercentageValueFormatter";
import {
  isPipelineAttributeOwner,
  pipelineAttributeOwnerValueFormatter,
} from "./valueFormatters/PipelineAttributeOwnerValueFormatter";
import {
  isPipelineFromColumn,
  pipelineFromValueFormatter,
} from "./valueFormatters/PipelineFromValueFormatter";
import {
  isPipelineOwnerColumn,
  pipelineOwnerValueFormatter,
} from "./valueFormatters/PipelineOwnerValueFormatter";
import { revenueMultiplierValueFormatter } from "./valueFormatters/RevenueMultiplierValueFormatter";
import { xTimeAgoValueFormatter } from "./valueFormatters/XTimeAgoValueFormatter";

export type CellProps = {
  value?: any;
  fieldName: string;
  field?: FieldType;
  row?: any;
  accountType?: AccountMappingResource;
  activeSlack?: boolean;
  partnership?: Partnership;
};

const extractValue = (str: string, keys: string[]): string | null => {
  let result = str;
  keys.forEach((key) => {
    const parts = result.split(`__${key}`);
    if (parts.length > 1) {
      result = parts[0];
    }
  });

  // Extract the number part from the remaining string
  const match = result.match(/(\d+)/);
  return match ? match[0] : null;
};

export const valueFormatter = (
  params: GridValueFormatterParams,
  field: FieldType,
  row: any,
  intl: IntlShape,
  amountFormatter: AmountFormatter
) => {
  let value = params.value;
  if (!_.isEmpty(field.jsonPath) && field.jsonPath) {
    // jsonPath is for custom fields
    // Example:
    // the field name is in this structure "customFields__657__StageName"
    // jsonPath is ["custom_fields", "StageName"]
    // integration id is 657
    const integrationIdFromField = extractValue(params.field, field.jsonPath);
    if (integrationIdFromField === String(row.integrationId)) {
      const valueObj = _.get(
        row,
        [_.camelCase(field.jsonPath[0]), field.jsonPath[1]],
        {}
      );
      value = valueObj?.displayable_value ?? valueObj?.value;
    } else {
      value = undefined;
    }
  }
  const fieldName = params.field;

  if (isBaseMultiplierValue(fieldName)) {
    return baseMultiplierValueFormatter(row, fieldName);
  }
  if (isDateIdentifiedOnColumn(fieldName, row)) {
    return dateIndentifiedOnFormatter(value);
  }
  if (isDealInfluencerColumn(fieldName, row)) {
    return dealInfluencerFormatter(value);
  }
  if (fieldName === Available360MappingColumns.revenueMultiplier) {
    return revenueMultiplierValueFormatter(row);
  }
  if (fieldName === AvailableReferredAccountsColumns.rightReferralDate) {
    return xTimeAgoValueFormatter(params.value, intl);
  }
  if (isPartnerAnalyticMetrics(fieldName, row)) {
    return partnerAnalyticMetricValueFormatter(row, fieldName, amountFormatter);
  }
  if (isPartnerAnalyticsPercentageValue(fieldName, row)) {
    return partnerAnalyticPercentageValueFormatter(row, fieldName);
  }
  if (isPartnerAnalyticUserColumn(fieldName, row)) {
    return partnerAnalyticUserValueFormatter(row, fieldName);
  }
  if (fieldName === AvailablePipelineColumns.createdAt) {
    return dateValueToLocaleDateStringFormatter(value);
  }
  if (isPipelineOwnerColumn(fieldName)) {
    return pipelineOwnerValueFormatter(row);
  }
  if (isPipelineFromColumn(fieldName)) {
    return pipelineFromValueFormatter(row);
  }
  if (isDirectoryBusinessTypeColumn(fieldName)) {
    return directoryBusinessTypeValueFormatter(row, intl);
  }
  if (isDirectoryEcosystemColumn(fieldName)) {
    return directoryEcosystemValueFormatter(row, intl);
  }
  if (isPipelineAttributeOwner(fieldName, row)) {
    return pipelineAttributeOwnerValueFormatter(value);
  }
  return fieldFormatter(value, field, intl, amountFormatter);
};

export const cellRenderer = (
  fields: { [key: string]: FieldType },
  params: GridRenderCellParams,
  accountType: AccountMappingResource,
  activeSlack: boolean,
  partnership?: Partnership,
  refreshTable?: () => void,
  openDealFields?: {
    [fieldname: string]: FieldType;
  }
) => {
  const props = {
    value: params.value,
    fieldName: params.field,
    field: fields[params.field],
    row: params.row,
    accountType,
    partnership,
  };

  if (props.row.hidden)
    return (
      <HiddenCell fieldName={props.fieldName} itemCount={props.row.itemCount} />
    );

  if (isAmActionsCell(props))
    return <AmActionsCell {...props} activeSlack={activeSlack} />;

  if (isMapping360ActionCell(props))
    return <Mapping360ActionCell {...props} activeSlack={activeSlack} />;

  if (isLockedCell(props)) return <LockedCell />;

  if (isMissingValueCell(props)) return <MissingValueCell />;

  if (isCompanyNameCell(props))
    return <CompanyNameCell {...props} activeSlack={activeSlack} />;

  if (isStatusCell(props)) return <StatusCell {...props} />;

  if (isOwnersCell(props)) return <OwnersCell value={props.value} />;

  if (isOwnerCell(props)) return <OwnerCell {...props} />;

  if (isOpenDealCell(props))
    return (
      <OpenDealCell
        {...props}
        fields={fields}
        openDealFields={openDealFields}
      />
    );

  if (isLeftContactsCell(props)) return <LeftContactsCell {...props} />;

  if (isRightContactsCell(props)) return <RightContactsCell {...props} />;

  if (isPartnerSignalsCell(props)) return <PartnerSignalsCell {...props} />;

  if (isPartnerPresenceCell(props)) {
    const width = params.colDef.width ?? PARTNER_PRESENCE_DEFAULT_WIDTH;
    return <PartnerPresenceCell {...props} width={width} />;
  }
  if (isPartnerPresenceCellNearboundAccounts(props)) {
    const width = params.colDef.width ?? PARTNER_PRESENCE_DEFAULT_WIDTH;
    return <PartnerPresenceCellNearboundAccounts {...props} width={width} />;
  }
  if (isStrategicAccountCell(props)) {
    return <StrategicAccountCell {...props} />;
  }

  // Analytics Table

  if (isFavoriteCell(props)) return <FavoriteCell {...props} />;

  if (isPartnerNameCell(props)) {
    return <PartnerNameCell {...props} />;
  }

  if (isAccountMappingCell(props)) {
    return <AccountMappingCell {...props} />;
  }

  if (isTagsCell(props)) {
    const width = params.colDef.width ?? tagsDefaultWidth;
    return <TagsCell {...props} width={width} />;
  }

  if (isWinRateCell(props)) return <WinRateCell {...props} />;

  if (isDealSizeCell(props)) return <DealSizeCell {...props} />;

  if (isRedirectCell(props)) return <RedirectCell {...props} />;

  // Directory Table

  if (isDirectoryCompanyNameCell(props))
    return <DirectoryCompanyNameCell {...props} />;

  if (isDirectoryMarketPresenceCell(props)) {
    const width = params.colDef.width ?? tagsDefaultWidth;
    return <MarketPresenceCell {...props} width={width} />;
  }

  if (isDirectoryAccountOverlapCell(props))
    return <AccountOverlapCell {...props} />;

  if (isDirectoryContactOverlapCell(props))
    return <ContactOverlapCell {...props} />;

  if (isDirectoryPartnerCell(props)) return <DirectoryPartnerCell {...props} />;

  // Collaborate Table

  if (isPipelineCompanyNameCell(props)) {
    return <PipelineCompanyNameCell {...props} />;
  }

  if (isPipelineStatusCell(props)) {
    return <PipelineStatusCell {...props} />;
  }

  if (isActivityDateCell(props)) return <ActivityDateCell {...props} />;

  if (isStageCell(props)) {
    return <StageCell {...props} />;
  }

  if (isKindCell(props)) {
    return <KindCell {...props} />;
  }

  if (isCommentCell(props)) return <CommentCell {...props} />;

  if (isPipelineOpportunityCell(props)) return <OpportunityCell {...props} />;

  if (isPipelineCategoryCell(props)) return <CategoryCell {...props} />;

  if (isPartnerDiscussionCell(props)) {
    const width = params.colDef.width ?? tagsDefaultWidth;
    return <PartnerDiscussionsCell {...props} width={width} />;
  }

  if (isParticipantsCell(props)) {
    return <ParticipantsCell {...props} />;
  }

  //  360

  if (isNearboundProspectCustomerCell(props)) {
    const width = params.colDef.width ?? tagsDefaultWidth;
    return <NearboundProspectCustomerCell {...props} width={width} />;
  }
  if (isMapping360NearboundProspectsActionCell(props))
    return (
      <Mapping360NearboundProspectsActionCell
        {...props}
        activeSlack={activeSlack}
      />
    );

  return undefined;
};

export const getCopyPasteContent = (
  fieldName: string,
  row: any,
  fields: { [key: string]: FieldType },
  intl: IntlShape,
  amountFormatter: AmountFormatter
) => {
  let value = _.cloneDeep(row[fieldName]);
  let field = _.cloneDeep(_.get(fields, fieldName, {})) as FieldType;
  const params = { value, field: fieldName } as GridValueFormatterParams;
  const props = {
    value,
    fieldName,
    field,
    row,
  };

  if (isOpenDealCell(props)) {
    return getOpenDealCopyContent(props, intl);
  }

  if (isPartnerPresenceCell(props)) {
    return getPartnerPresenceCopyContent(props);
  }

  if (isAccountMappingCell(props)) {
    return getAccountMappingCopyContent(props);
  }

  if (isTagsCell(props)) {
    return getTagsCopyContent(props);
  }

  if (isWinRateCell(props)) {
    return getBoostCellCopyContent(props);
  }

  if (isDealSizeCell(props)) {
    return getBoostCellCopyContent(props);
  }

  if (isNearboundProspectCustomerCell(props)) {
    return getNearboundProspectCustomerCopyContent(props);
  }

  return valueFormatter(params, field, row, intl, amountFormatter);
};

export const checkboxCellRenderer = (params: GridRenderCellParams) => {
  return <GridCellCheckboxRenderer {...params} />;
};

export const isRowSelectable = (params: GridRowParams) => {
  return !params.row.hidden;
};

export const isRowArchived = (params: GridRowParams) => !!params.row.archivedAt;

export const isResizable = (
  columnKey: string,
  viewType: DataTableType | undefined
) => {
  if (viewType === DataTableType.ANALYTICS) {
    // Only AdditionalAnalyticsColumns aren't resizable
    return !Object.values(AdditionalAnalyticsColumns).includes(
      columnKey as AdditionalAnalyticsColumns
    );
  }

  if (viewType === DataTableType.DIRECTORY) {
    return (
      columnKey === AvailableDirectoryColumns.name ||
      columnKey === AvailableDirectoryColumns.marketPresence
    );
  }

  if (viewType === DataTableType.MAPPING_360) {
    return (
      columnKey !== Available360MappingColumns.actions &&
      columnKey !== AvailableNearboundAccountsColumns.NearboundProspectsActions
    );
  }
  return true;
};

// Display only new updates since helpers

export const getIsNewMatch = (row: any, displaySinceFilterDate: Date | null) =>
  displaySinceFilterDate &&
  row.createdAt &&
  new Date(row.createdAt) >= displaySinceFilterDate;

export const isGoldenCell = (
  fieldName: string,
  row: any,
  displaySinceFilterFieldname: DisplaySinceFilterFieldname | null,
  displaySinceFilterDate: Date | null
) => {
  if (isCompanyNameCell({ fieldName, row })) {
    return getIsNewMatch(row, displaySinceFilterDate);
  }
  if (isStatusCell({ fieldName })) {
    const side = getStatusSide(fieldName);
    return hasStatusChanged(
      side,
      row,
      displaySinceFilterFieldname,
      displaySinceFilterDate
    );
  }
  if (isOpenDealCell({ fieldName })) {
    const side = getOpenDealSide(fieldName);
    return hasNewOpportunity(
      side,
      row,
      displaySinceFilterFieldname,
      displaySinceFilterDate
    );
  }
};
