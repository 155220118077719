import { Factory } from "models";
import Partnership, {
  OverviewCustomersType,
  OverviewParameters,
  OverviewProspectsType,
  PartnershipSource,
} from "models/Partnership";
import { JSONSerializable } from "models/types";
import {
  allNewProspects,
  allOverlaps,
  commonCustomers,
  commonOpportunities,
  customerMatchingProspects,
  prospectMatchingCustomers,
  whitespaceIShare,
  whitespacePartnerShares,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/quickFilterPresets";
import { AccountMappingStandardFields } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import {
  ColumnConfigType,
  MatchFieldType,
  MatchFilter,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export const partnership = new Partnership({
  id: "demo",
  type: "partnerships",
  attributes: {
    status: "accepted",
    ready: true,
    source: PartnershipSource.Other,
  },
  relationships: {
    initiator_company: {
      data: Factory.createRecord({
        id: "initiatorDemoCompany",
        type: "companies",
        attributes: {
          name: "Demo",
          avatar_url: "/images/reveal-logo-black-on-purple.svg",
        },
      }),
    },
    dest_company: {
      data: Factory.createRecord({
        id: "destDemoCompany",
        type: "companies",
        attributes: {
          name: "My company",
          avatar_url: "/images/avatar-demo.png",
        },
      }),
    },
    initiator_user: {
      data: Factory.createRecord({
        id: "demoUser",
        type: "users",
        attributes: {
          fullName: "John Doe",
        },
      }),
    },
  },
});

// @ts-ignore
partnership.id = "demo";

partnership.isWhitespaceEnabled = true;
partnership.loadAssessment = async (
  kpiParameters: OverviewParameters,
  includeNbMatches: boolean = true
) => {
  return Object.entries(kpiParameters).reduce((acc, [kpi, kpiFilters]) => {
    let data = {} as OverviewProspectsType | OverviewCustomersType;
    let filters: { [filter: string]: JSONSerializable };
    switch (kpi) {
      case "common_customers":
        filters = MatchFilter.forAPI(kpiFilters);
        if (
          "right_key_contact_overlap.gt" in filters &&
          "left_starred.is" in filters
        ) {
          data = { count: 0, count_not_private: 0, closed_amount: 0 };
        } else if ("right_key_contact_overlap.gt" in filters) {
          data = { count: 2, count_not_private: 2, closed_amount: 200000 };
        } else if ("left_starred.is" in filters) {
          data = { count: 7, count_not_private: 7, closed_amount: 550000 };
        } else {
          data = { count: 26, count_not_private: 26, closed_amount: 2400000 };
        }
        acc[kpi] = data;
        break;
      case "common_opportunities":
        filters = MatchFilter.forAPI(kpiFilters);
        if ("right_key_contact_overlap.gt" in filters) {
          data = {
            count: 0,
            pipeline_amount: 0,
            open_deals: 0,
            active_count: 0,
            count_not_private: 0,
          };
        } else if ("left_starred.is" in filters) {
          data = {
            count: 5,
            pipeline_amount: 490000,
            open_deals: 5,
            active_count: 5,
            count_not_private: 5,
          };
        } else {
          data = {
            count: 32,
            pipeline_amount: 2800000,
            open_deals: 32,
            active_count: 5,
            count_not_private: 32,
          };
        }
        acc[kpi] = data;
        break;
      case "prospects_to_customers":
        filters = MatchFilter.forAPI(kpiFilters);
        if (
          "right_key_contact_overlap.gt" in filters &&
          "left_starred.is" in filters
        ) {
          data = {
            count: 2,
            pipeline_amount: 190000,
            open_deals: 1,
            active_count: 1,
            count_not_private: 2,
          };
        } else if ("right_key_contact_overlap.gt" in filters) {
          data = {
            count: 12,
            pipeline_amount: 1100000,
            open_deals: 4,
            active_count: 1,
            count_not_private: 12,
          };
        } else if ("left_starred.is" in filters) {
          data = {
            count: 12,
            pipeline_amount: 1300000,
            open_deals: 6,
            active_count: 1,
            count_not_private: 12,
          };
        } else {
          data = {
            count: 59,
            pipeline_amount: 4000000,
            open_deals: 29,
            active_count: 1,
            count_not_private: 59,
          };
        }
        acc[kpi] = data;
        break;
      case "customers_to_prospects":
        filters = MatchFilter.forAPI(kpiFilters);
        if (
          "right_key_contact_overlap.gt" in filters &&
          "left_starred.is" in filters
        ) {
          data = { count: 1, count_not_private: 1, closed_amount: 100 };
        } else if ("right_key_contact_overlap.gt" in filters) {
          data = { count: 5, count_not_private: 5, closed_amount: 100 };
        } else if ("left_starred.is" in filters) {
          data = { count: 8, count_not_private: 8, closed_amount: 100 };
        } else {
          data = { count: 33, count_not_private: 33, closed_amount: 100 };
        }
        acc[kpi] = data;
        acc["nb_matched_accounts"] = 1000;
        acc["nb_matched_accounts_not_private"] = 1000;
        acc["nb_shared_accounts"] = 1000;
        break;
      default:
        acc[kpi] = { count: 12, count_not_private: 12, closed_amount: 100 };
        acc["nb_matched_accounts"] = 1000;
        acc["nb_matched_accounts_not_private"] = 1000;
        acc["nb_shared_accounts"] = 1000;
        break;
    }
    return acc;
  }, {} as { [kpi: string]: OverviewProspectsType | OverviewCustomersType | number });
};
partnership.invites = [];
partnership.insights = Factory.createRecord({
  type: "insights",
  id: "demo",
  attributes: {
    matches: 238,
    strategic_accounts: 48,
    market_overlap: 0.35,
    persona_overlap: 0.41,
    common_customers: 26,
    common_active_prospects: 32,
    common_other_prospects: 72,
    common_partners: 16,
    prospect_customer: 59,
    active_prospect_customer: 29,
    customer_prospect: 33,
    customer_active_prospect: 17,
    partner_nb_of_new_prospects: 20,
    company_nb_of_new_prospects: 21,
    partner_nb_of_new_prospects_not_private: 20,
    company_nb_of_new_prospects_not_private: 21,
    open_opportunities_to_customers: 12,
  },
});
partnership.capabilities = {
  has_opportunities: true,
  has_common_opportunities: true,
  has_market_overlap: true,
  has_persona_overlap: true,
  has_prospect_overlaps: true,
  has_customer_overlaps: true,
  has_prospect_to_customer_overlaps: true,
  has_customer_to_prospect_overlaps: true,
  has_nb_of_new_prospects: true,
};
partnership.facets = {
  [allOverlaps.key]: partnership.insights.matches,
  [commonCustomers.key]: partnership.insights.commonCustomers,
  [commonOpportunities.key]: partnership.insights.commonActiveProspects,
  [customerMatchingProspects.key]: partnership.insights.customerProspect,
  [prospectMatchingCustomers.key]: partnership.insights.prospectCustomer,
};
partnership.newLeadsfacets = {
  [allNewProspects.key]:
    partnership.insights.companyNbOfNewProspects +
    partnership.insights.partnerNbOfNewProspects,
  allNewLeadsWithPrivate:
    partnership.insights.companyNbOfNewProspectsNotPrivate +
    partnership.insights.partnerNbOfNewProspectsNotPrivate,
  [whitespaceIShare.key]: partnership.insights.companyNbOfNewProspects,
  [whitespacePartnerShares.key]: partnership.insights.partnerNbOfNewProspects,
};

export const fields = {
  leftActions: {
    label: "Actions",
    type: MatchFieldType.UNKNOWN,
    options: {},
    smartField: false,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: false,
    hideHeaderMenu: true,
  },
  leftName: {
    label: "Company name",
    type: MatchFieldType.TEXT,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  leftStatus: {
    label: "Account Status",
    options: {
      1: "Customer",
      2: "Prospect",
      4: "Partner",
    },
    type: MatchFieldType.PICKLIST,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
  },
  leftActive: {
    label: "Open deal",
    type: MatchFieldType.BOOLEAN,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  leftOwnerName: {
    label: "Owner",
    type: MatchFieldType.TEXT,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  leftDomain: {
    label: "Domain",
    type: MatchFieldType.TEXT,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  leftCountryCode: {
    label: "Country",
    options: {
      be: "Belgium",
      ca: "Canada",
      de: "Germany",
      fr: "France",
      uk: "United Kingdom",
      us: "United States",
    },
    type: MatchFieldType.PICKLIST,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
  },
  leftContactCount: {
    label: "# Contacts",
    type: MatchFieldType.INTEGER,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  leftStarred: {
    label: "Is strategic",
    type: MatchFieldType.BOOLEAN,
    options: {},
    smartField: false,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: false,
    hideHeaderMenu: true,
  },
  leftOpenOpportunitiesAmountInDollars: {
    label: "Opportunity Amount",
    type: MatchFieldType.CURRENCY,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightName: {
    label: "Company name",
    type: MatchFieldType.TEXT,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightStatus: {
    label: "Account Status",
    options: {
      1: "Customer",
      2: "Prospect",
      4: "Partner",
    },
    type: MatchFieldType.PICKLIST,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
  },
  rightNewProspectActions: {
    label: "Actions",
    type: MatchFieldType.UNKNOWN,
    options: {},
    smartField: false,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: false,
    hideHeaderMenu: true,
  },
  rightActive: {
    label: "Open deal",
    type: MatchFieldType.BOOLEAN,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightOwnerName: {
    label: "Owner",
    type: MatchFieldType.TEXT,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightCountryCode: {
    label: "Country",
    options: {
      be: "Belgium",
      ca: "Canada",
      de: "Germany",
      fr: "France",
      uk: "United Kingdom",
      us: "United States",
    },
    type: MatchFieldType.PICKLIST,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
  },
  rightDomain: {
    label: "Domain",
    type: MatchFieldType.TEXT,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightContactCount: {
    label: "# Contacts",
    type: MatchFieldType.INTEGER,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightContactOverlap: {
    label: "Common contacts",
    type: MatchFieldType.INTEGER,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightExtraContacts: {
    label: "Other contacts",
    type: MatchFieldType.INTEGER,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightKeyContactOverlap: {
    label: "Known deal influencers",
    type: MatchFieldType.INTEGER,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  rightExtraKeyContacts: {
    label: "Other deal influencers",
    type: MatchFieldType.INTEGER,
    smartField: true,
    fullyImported: true,
    partnerField: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  leftCrmFieldTest1: {
    label: "Private CRM field",
    type: MatchFieldType.TEXT,
    smartField: false,
    fullyImported: true,
    isPrivate: true,
  },
  leftCrmFieldTest2: {
    label: "Shared CRM field",
    type: MatchFieldType.TEXT,
    smartField: false,
    fullyImported: true,
    isPrivate: false,
    partnershipCrmFieldId: "demo",
  },
  leftCrmField1000: {
    label: "Opportunity Amount",
    type: MatchFieldType.CURRENCY,
    column: false,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: false,
    sort: false,
    options: {},
  },
  leftCrmField1001: {
    label: "Opportunity Stage",
    type: MatchFieldType.TEXT,
    column: false,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: false,
    sort: false,
    options: {},
  },
  leftCrmField1002: {
    label: "Opportunity Close Date",
    type: MatchFieldType.DATE,
    column: false,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    partnerField: false,
    noSide: false,
    filter: false,
    sort: false,
    options: {},
  },
  rightCrmFieldTest: {
    label: "Shared partner CRM field",
    type: MatchFieldType.TEXT,
    smartField: false,
    fullyImported: true,
    isPrivate: false,
    partnerField: true,
    isDisabled: false,
    noSide: false,
    filter: true,
    sort: true,
    options: {},
  },
  perspective: {
    label: "Perspective",
    options: {
      all: "All",
      mine: "Mine",
      theirs: "Theirs",
    },
    type: MatchFieldType.PICKLIST,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    noSide: false,
    filter: true,
    sort: true,
    partnerField: false,
  },
} as AccountMappingStandardFields;

export const availableFieldsDefaultConfig: ColumnConfigType[] = [
  {
    key: "leftName",
    width: 180,
    frozen: true,
  },
  {
    key: "leftStatus",
    width: 140,
    frozen: true,
  },
  {
    key: "leftActive",
    width: 134,
    frozen: true,
  },
  {
    key: "leftOwnerName",
    width: 170,
    frozen: true,
  },
  {
    key: "rightName",
    width: 180,
    frozen: false,
  },
  {
    key: "rightStatus",
    width: 140,
    frozen: false,
  },
  {
    key: "rightActive",
    width: 116,
    frozen: false,
  },
  {
    key: "rightOwnerName",
    width: 170,
    frozen: false,
  },
  {
    key: "rightCountryCode",
    width: 130,
    frozen: false,
  },
  {
    key: "rightKeyContactOverlap",
    width: 118,
    frozen: false,
  },
  {
    key: "rightExtraKeyContacts",
    width: 118,
    frozen: false,
  },
];
