import { Box } from "@mui/material";
import { ClassNameMap, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCallbackDetails,
  GridCellParams,
  GridCellSelectionModel,
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
  GridColumnResizeParams,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridValueFormatterParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  ArrowDownAlpha,
  ArrowDownInverted,
  ArrowUpInverted,
  Info,
  LockLightInverted,
} from "components/icons";
import ActionPanel from "components/ui/ActionPanel";
import FieldsContext from "components/ui/data-grid/FieldsContext";
import HeaderRenderer from "components/ui/data-grid/HeaderRenderer";
import SelectedRowsContext from "components/ui/data-grid/SelectedRowsContext";
import { getDefaultFilter } from "components/ui/filters/filter/FilterWidget";
import { getDefaultSort } from "components/ui/filters/sort/SortItem";
import useAllRecords from "hooks/useAllRecords";
import useAmountFormatter, { AmountFormatter } from "hooks/useAmountFormatter";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import Record from "models/Record";
import {
  JSXElementConstructor,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  selectOnlyNewUpdatesSinceDate,
  selectOnlyNewUpdatesSinceFieldname,
} from "redux/accountMapping/selector";
import { AccountMappingResource } from "redux/accountMapping/types";
import { AdditionalAnalyticsColumns } from "redux/analytics/defaults";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";
import {
  ColumnConfigType,
  DataTableType,
  FieldType,
  FilterType,
  MatchSortOrder,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { getHeaderPartnerName } from "screens/Frontoffice/screens/DataTables/shared/utils";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { TableEvent } from "tracking";
import { useMergedClasses } from "tss-react";

import Alert from "../Alert";
import Tooltip from "../Tooltip";
import ActionHeaderRow, { ActionHeaderRowProps } from "./ActionHeaderRow";
import { isOverlayedCell } from "./cellRenderers/LockedCell";
import { getRandomValue } from "./cellRenderers/PartnerPresenceCell";
import { hasMultipleOpportunities } from "./cellRenderers/pipeline/OpportunityCell";
import ColumnGroupRenderer from "./ColumnGroupRenderer";
import { infoFieldsTooltips } from "./fieldTooltips";
import {
  cellRenderer,
  checkboxCellRenderer,
  getCopyPasteContent,
  isGoldenCell,
  isResizable,
  isRowArchived,
  isRowSelectable,
  valueFormatter,
} from "./utils";

export const HEADER_HEIGHT = 64;
export const PIPELINE_HEADER_HEIGHT = 36;
const ROW_HEIGHT = 44;
const PIPELINE_ROW_HEIGHT = 40;
const MIN_COL_WIDTH = 40;
const HIDDEN = { hidden: true };

type Mapping360Props = {
  manualFilterKeys: { [key: string]: string };
  manualSortKeys: { [key: string]: string };
  loading: boolean;
};

type AccountMappingProps = {
  // New Props (re-design)
  titleOffset?: number;
};

type ReferredAccountsProps = {
  showInModal: boolean;
};

type WidgetProps = {
  setOpenedWidget: (filterType: string | null) => void;
  setColumns: (columns: ColumnConfigType[]) => void;
  filters: FilterType[];
  setFilters: (filters: FilterType[]) => void;
  sort: SortType[];
  setSort: (sort: SortType[]) => void;
  sortOnHeaders?: boolean;
} & ActionHeaderRowProps;

type ActionPanelProps = {
  actionPanelContent?: React.ReactChild;
  isActionPanelOpen?: boolean;
  onActionPanelClose?: () => void;
};

type Props = {
  lockHeaders?: boolean;
  columnGroups?: [string, string][];
  CustomFooterComponent?: JSXElementConstructor<any>;
  rows?: Record[];
  rowsCount?: number;
  fields: $TSFixMe;
  openDealFields?: {
    [fieldname: string]: FieldType;
  };
  loadMore: () => void;
  fetching?: boolean;
  columns: ColumnConfigType[];
  emptyRowsView: ReactNode;
  smallScreenText?: ReactElement | string;
  rightChipName?: string;
  classes?: ClassNameMap;
  partnership?: Partnership;
  // New Props (re-design)
  title?: ReactNode;
  leftSideTitle?: ReactNode;
  rightSideTitle?: ReactNode;
  actions?: ReactNode;
  extraSettings?: ReactNode;
  hiddenRows?: number;
  hideHeaderMenus?: boolean;
  hasRowSelection?: boolean;
  noSide?: boolean;
  headerHeight?: number;
  onRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) => void;
  rowClickValidator?: (params: GridRowParams) => boolean;
  viewType?: DataTableType;
  customMenu?: (field: string) => JSX.Element | undefined;
  onCustomMenuOpen?: (el: HTMLElement | null, fieldname: string) => void;
  refreshTable?: () => void;
  headerTooltips?: { [key: string]: JSX.Element };
} & Partial<Mapping360Props> &
  Partial<AccountMappingProps> &
  Partial<ReferredAccountsProps> &
  Partial<WidgetProps> &
  Partial<ActionPanelProps>;

const BaseDataGrid = ({
  lockHeaders = false,
  columnGroups,
  CustomFooterComponent,
  manualFilterKeys,
  manualSortKeys,
  partnership,
  rows = [],
  rowsCount,
  fields,
  openDealFields,
  loadMore,
  fetching,
  columns,
  setColumns = () => {},
  columnWidget,
  filters = [],
  setFilters = () => {},
  filterWidget,
  sort,
  setSort = () => {},
  sortWidget,
  quickViews,
  emptyRowsView,
  headerHeight,
  setOpenedWidget,
  actionPanelContent,
  onActionPanelClose,
  isActionPanelOpen,
  smallScreenText,
  rightChipName,
  rightSideActionsWidget,
  loading = false,
  classes: newClasses,
  headerTooltips,
  // New Props (re-design)
  actions,
  title,
  leftSideTitle,
  rightSideTitle,
  extraSettings,
  availableSources,
  selectedSourceId,
  setSelectedSourceId,
  sortOnHeaders = false,
  hiddenRows = 0,
  showInModal = false, // For now we are assuming that only referred accounts are showed in a modal
  hideHeaderMenus = false,
  hasRowSelection = true,
  noSide = false,
  viewType,
  customMenu,
  onCustomMenuOpen,
  onRowClick,
  rowClickValidator = () => true,
  refreshTable,
}: Props) => {
  const theme = useTheme();
  const { track } = useSegment();
  const location = useLocation();
  const { profile } = useUserProfile();
  const [
    cellSelectionModel,
    setCellSelectionModel,
  ] = useState<GridCellSelectionModel>({});

  const payingFeatures = useSelector(selectActivePayingFeatures);
  const displaySinceFilterFieldname = useSelector(
    selectOnlyNewUpdatesSinceFieldname
  );
  const displaySinceFilterDate = useSelector(selectOnlyNewUpdatesSinceDate);

  const { classes: baseClasses, cx } = tableContentStyles();
  const gridApiRef = useGridApiRef();

  const classes = useMergedClasses(baseClasses, newClasses);
  const smallWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);
  const [sortDate, setSortDate] = useState<number>(new Date().getTime());

  const { selectedRowIds, setSelectedRowIds, clearSelection } = useContext(
    SelectedRowsContext
  );

  const { accountType } = useContext(ViewContext);
  const hasLimitedNewProspectsToCSVExport = !payingFeatures.includes(
    PayingFeature.ExportNewProspectToCSV
  );
  const hasLimitedRowExport = !payingFeatures.includes(
    PayingFeature.UnlimitedRowsExport
  );
  const onNewProspects = accountType === AccountMappingResource.shared_accounts;
  const disableNewProspectCopy =
    onNewProspects && hasLimitedNewProspectsToCSVExport;
  const disableMultiCellCopy = !onNewProspects && hasLimitedRowExport;
  const isAnalytics = viewType === DataTableType.ANALYTICS;
  const isPipeline = [
    DataTableType.ATTRIBUTE,
    DataTableType.COLLABORATE,
  ].includes(viewType as DataTableType);
  const isGoals360 =
    viewType === DataTableType.MAPPING_360 &&
    (payingFeatures.includes(PayingFeature.GoalBased360Limited) ||
      payingFeatures.includes(PayingFeature.GoalBased360Unlocked));
  const isDirectory = viewType === DataTableType.DIRECTORY;
  const dispatch = useDispatch();

  const showActionsHeader =
    actions ||
    columnWidget ||
    filterWidget ||
    quickViews ||
    sortWidget ||
    extraSettings ||
    selectedSourceId;

  const { records: slackCreds, loading: loadingSlackCreds } = useAllRecords(
    "slack_credentials",
    {
      include: ["user"],
    }
  );
  const activeSlack = !loadingSlackCreds && slackCreds.length > 0;

  const enableFrozen = useMediaQuery(theme.breakpoints.up("lg"));
  const pinnedColumns = enableFrozen
    ? {
        left: ["__check__", "slack"].concat(
          columns.filter((column) => column.frozen).map((column) => column.key)
        ),
      }
    : {};

  const hasFields = !_.isEmpty(fields);
  const _rows = useMemo(() => {
    if (!hasFields) {
      return [];
    }
    return [
      ...rows,
      ...Array.from({ length: hiddenRows }, (_, i) => ({
        ...HIDDEN,
        id: `hidden-${i + 1}`,
        itemCount: getRandomValue(1, 4),
      })),
    ];
  }, [rows, hiddenRows, hasFields]);

  let previousIsPartnerCell: boolean | null = null;
  const formattedColumns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => checkboxCellRenderer(params),
      width: 35,
      maxWidth: 35,
      minWidth: 35,
      headerClassName: noSide
        ? hasRowSelection
          ? "checkbox"
          : undefined
        : showInModal
        ? "partner-side-checkbox"
        : "company-side-checkbox",
    } as GridColDef,
  ].concat(
    columns
      .filter(
        (preset) => preset.key in fields && !fields[preset.key].isDisabled
      )
      .map((preset) => {
        const columnKey = preset.key;
        const partnerName = getHeaderPartnerName(columnKey, rightChipName);
        const isPartnerCell = partnerName !== undefined;
        const isFirstLeft = !noSide && previousIsPartnerCell === null;
        const isFirstRight =
          !noSide &&
          previousIsPartnerCell !== null &&
          previousIsPartnerCell !== isPartnerCell;
        previousIsPartnerCell = isPartnerCell;
        const manualFilter = manualFilterKeys?.[columnKey];
        const manualSort = manualSortKeys?.[columnKey];
        const headerRenderer = (
          <HeaderRenderer
            name={columnKey}
            isPartnerCell={isPartnerCell}
            setOpenedWidget={setOpenedWidget}
            smartField={fields[columnKey].smartField}
            fullyImported={fields[columnKey].fullyImported}
            isPrivate={fields[columnKey].isPrivate}
            isPartiallyShared={fields[columnKey].isPartiallyShared ?? false}
            fieldType={fields[columnKey].type}
            removable={preset.removable ?? true}
            filterableField={
              !!manualFilter || (fields[columnKey].filter ?? true)
            }
            sortableField={!!manualSort || (fields[columnKey].sort ?? true)}
            addFilter={() => addFilterFromHeader(manualFilter ?? columnKey)}
            addSort={() => addSortFromHeader(manualSort ?? columnKey)}
            hideColumn={() => hideColumn(columnKey)}
            hideMenu={hideHeaderMenus || fields[columnKey].hideHeaderMenu}
            isFirstLeft={isFirstLeft}
            leftSideTitle={isFirstLeft && leftSideTitle}
            isFirstRight={isFirstRight}
            rightSideTitle={isFirstRight && rightSideTitle}
            noSide={noSide}
            customMenu={customMenu}
          />
        );
        const tooltip = _.get(
          { ...infoFieldsTooltips, ...headerTooltips },
          columnKey,
          false
        );
        const isColumnGroupsActivated = columnGroups?.some(
          ([_primary, secondary]) =>
            columns.some((column) => secondary === column.key)
        );
        const isGroupColumn =
          isColumnGroupsActivated &&
          _.find(columnGroups, (columns) => columns.includes(columnKey));

        return {
          headerAlign: _.get(fields, `${columnKey}.headerAlign`, undefined),
          field: columnKey,
          flex: preset.flex,
          width: preset.width,
          colSpan: preset.colSpan,
          headerName: _.get(fields, `${columnKey}.label`, ""),
          description: _.get(fields, `${columnKey}.label`, ""),
          sortable: sortOnHeaders && _.get(fields, `${columnKey}.sort`, ""),
          resizable: isResizable(columnKey, viewType),
          renderHeader: (params: GridColumnHeaderParams) =>
            noSide && !isGoals360 && !isDirectory ? (
              isGroupColumn ? (
                <ColumnGroupRenderer
                  columnGroups={columnGroups}
                  params={params}
                  sortDate={sortDate}
                />
              ) : lockHeaders || tooltip || isAnalytics ? (
                lightHeaderRenderer(
                  lockHeaders,
                  tooltip,
                  params,
                  classes,
                  fields
                )
              ) : (
                headerRenderer
              )
            ) : (
              headerRenderer
            ),
          headerClassName: noSide
            ? customMenu?.(columnKey)
              ? "no-side clickable-header"
              : !isAnalytics
              ? "no-side visible-sort-view"
              : "no-side"
            : "",
          renderCell: (params: GridRenderCellParams) =>
            cellRenderer(
              fields,
              params,
              accountType,
              activeSlack,
              partnership,
              refreshAndResetTable,
              openDealFields
            ),
          cellClassName: (params: GridCellParams) =>
            isFirstRight
              ? "first-right"
              : isOverlayedCell({ fieldName: params.field, row: params.row })
              ? "locked"
              : Object.values(AdditionalAnalyticsColumns).includes(
                  params.field as AdditionalAnalyticsColumns
                )
              ? "purple-overlay"
              : isGoldenCell(
                  params.field,
                  params.row,
                  displaySinceFilterFieldname,
                  displaySinceFilterDate
                )
              ? "golden-overlay"
              : "",
          valueFormatter: (params: GridValueFormatterParams) => {
            const field = _.get(fields, params.field, {});
            const row = gridApiRef.current.getRow(Number(params.id));
            return valueFormatter(params, field, row, intl, amountFormatter);
          },
        };
      })
  );

  const handleSort = useCallback(
    (model: GridSortModel, _details: GridCallbackDetails<any>) => {
      const sort: SortType[] =
        model?.map((item) => ({
          fieldname: item.field,
          fieldtype: fields[item.field].type,
          order: item.sort as MatchSortOrder,
        })) ?? [];
      setSort(sort);
      setSortDate(new Date().getTime());
    },
    [fields, setSort]
  );

  const resizeColumn = (params: GridColumnResizeParams) => {
    const index = columns.findIndex(({ key }) => key === params.colDef.field);
    columns[index].width = Math.max(params.width, MIN_COL_WIDTH);
    setColumns(columns);
  };

  const addFilterFromHeader = (fieldname: $TSFixMe) => {
    if (!Boolean(filters.find((item) => item.fieldname === fieldname))) {
      const { defaultFilterType, defaultValue } = getDefaultFilter(
        fieldname,
        fields
      );
      setFilters([
        ...filters,
        { fieldname, type: defaultFilterType, value: defaultValue },
      ]);
    }
  };

  const addSortFromHeader = (fieldname: string) => {
    if (sort && !Boolean(sort.find((item) => item.fieldname === fieldname))) {
      const sortItem = getDefaultSort(fieldname, fields);
      setSort([...sort, sortItem]);

      track(TableEvent.sortApplied, {
        location: viewType,
        fieldName: sortItem.fieldname,
        order: sortItem.order,
      });
    }
  };

  const hideColumn = (fieldname: string) => {
    const newColumns = [...columns];
    const index = newColumns.findIndex((item) => item.key === fieldname);
    newColumns.splice(index, 1);
    setColumns(newColumns);
  };

  const refreshAndResetTable = (resetTable?: boolean) => {
    refreshTable && refreshTable();
    if (resetTable) {
      setSort([]);
      gridApiRef.current.scroll({ top: 0, left: 0 });
    }
  };

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
  }, [dispatch]);

  const publishEventCopiedRows = useCallback(
    (copiedRows: Record<any>[]) => {
      if (
        viewType !== DataTableType.ACCOUNT_MAPPING &&
        viewType !== DataTableType.MAPPING_360
      ) {
        return;
      }
      const service = new WebhookService();
      copiedRows.forEach((row) => {
        if (row.type === "matches") {
          service.track({
            profile: profile,
            eventName: WebhookEventName.RevealCopiedContentFromAccountMapping,
            partnership: partnership,
            match: row,
          });
        } else if (row.type === "crm_accounts") {
          service.track({
            profile: profile,
            eventName: WebhookEventName.RevealCopiedContentFrom360Mapping,
            partnership: partnership,
            crmAccount: row,
          });
        } else if (row.type === "shared_accounts") {
          service.track({
            profile: profile,
            eventName: WebhookEventName.RevealCopiedContentFromNewProspects,
            partnership: partnership,
            rawCompanyId: row.rawCompanyId,
            rawCompanyProviderKey: row.rawCompanyProviderKey,
          });
        }
        return;
      });
    },
    [partnership, profile, viewType]
  );

  const handleCellKeyDown = useCallback(
    (_params: GridCellParams, event) => {
      const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
      const selectedCells = gridApiRef.current.unstable_getSelectedCellsAsArray();
      if (event.key === "c" && isCtrlOrCmdPressed) {
        if (!selectedCells.length) {
          const selectedRows = selectedRowIds.map((id) =>
            gridApiRef.current.getRow(id)
          );
          publishEventCopiedRows(selectedRows);
          return;
        }
        event.preventDefault();
        const handleBlockCopyPasting = (
          customCopiedContent: string,
          shouldOpenPremiumWidget?: boolean
        ) => {
          (navigator as any).clipboard.writeText(customCopiedContent);
          shouldOpenPremiumWidget && openPremiumWidget();
          track(TableEvent.copiedDataDisabled, { location });
        };
        if (disableNewProspectCopy) {
          handleBlockCopyPasting(intl.formatMessage(i18n.upgradeMessage), true);
          return;
        }
        const rows = getOrderedSelectedCells(
          gridApiRef,
          fields,
          intl,
          amountFormatter
        );
        if (selectedCells.length > 1 && disableMultiCellCopy) {
          const firstCellContent = rows[0].split("\t")[0];
          const customCopiedContent = [
            firstCellContent,
            intl.formatMessage(i18n.exportFeatureMessage),
          ].join("\n");
          handleBlockCopyPasting(customCopiedContent);
          return;
        }
        const distinctRowIds = [
          ...new Set(selectedCells.map((cell) => cell.id)),
        ];
        const selectedCellRows = distinctRowIds.map((rowId) =>
          gridApiRef.current.getRow(rowId)
        );
        publishEventCopiedRows(selectedCellRows);
        (navigator as any).clipboard.writeText(rows.join("\n"));
        track(TableEvent.copiedData, {
          location,
          cells: Object.entries(selectedCells)?.length,
        });
      }
    },
    [
      amountFormatter,
      disableMultiCellCopy,
      disableNewProspectCopy,
      fields,
      gridApiRef,
      intl,
      location,
      openPremiumWidget,
      track,
      selectedRowIds,
      publishEventCopiedRows,
    ]
  );

  const handleColumnHeaderClick = (
    params: GridColumnHeaderParams,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>,
    _details: GridCallbackDetails
  ) => {
    if (!customMenu?.(params.field)) return;
    onCustomMenuOpen?.(
      (event.target as HTMLElement).closest(".MuiDataGrid-columnHeader"),
      params.field
    );
  };

  useEffect(() => {
    // Delete rows selection for action when filters & sort are updated
    clearSelection();
  }, [JSON.stringify(filters), JSON.stringify(sort)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCellSelectionModelChange = (newModel: GridCellSelectionModel) => {
    if (
      Object.keys(newModel).length === 1 &&
      Object.keys(Object.values(newModel)[0]).length === 1
    ) {
      setCellSelectionModel({});
      return; // single cell selection is prevented
    }
    setCellSelectionModel(newModel);
  };

  return (
    <FieldsContext.Provider value={fields}>
      <div className={classes.root}>
        {smallWidth ? (
          <Alert className={classes.smallWidthAlert}>{smallScreenText}</Alert>
        ) : (
          <>
            {showActionsHeader && (
              <ActionHeaderRow
                actions={actions}
                columnWidget={columnWidget}
                filterWidget={filterWidget}
                quickViews={quickViews}
                sortWidget={sortWidget}
                title={title}
                extraSettings={extraSettings}
                selectedSourceId={selectedSourceId}
                setSelectedSourceId={setSelectedSourceId}
                availableSources={availableSources}
                viewType={viewType}
              />
            )}
            <Box
              position="relative"
              height="100%"
              paddingRight={rightSideActionsWidget ? "36px" : 0}
            >
              <DataGridPremium
                disableVirtualization={process.env.NODE_ENV === "test"}
                apiRef={gridApiRef}
                checkboxSelection={hasRowSelection}
                disableRowSelectionOnClick
                unstable_cellSelection
                unstable_cellSelectionModel={
                  !!onRowClick ? cellSelectionModel : undefined
                }
                unstable_onCellSelectionModelChange={
                  !!onRowClick ? handleCellSelectionModelChange : undefined
                }
                columnHeaderHeight={
                  headerHeight ??
                  (isPipeline ? PIPELINE_HEADER_HEIGHT : HEADER_HEIGHT)
                }
                columns={formattedColumns}
                disableColumnMenu
                experimentalFeatures={{ lazyLoading: true }}
                filterMode="server"
                hideFooter={!CustomFooterComponent}
                isRowSelectable={(params) => isRowSelectable(params)}
                getRowHeight={(params) =>
                  hasMultipleOpportunities(params.model.sharedOpportunities)
                    ? "auto"
                    : null
                }
                getRowClassName={(params) =>
                  cx({
                    [classes.archived]: isRowArchived(params),
                    [classes.isClickable]:
                      !!onRowClick && rowClickValidator(params),
                  })
                }
                loading={loading}
                onCellKeyDown={handleCellKeyDown}
                onColumnWidthChange={resizeColumn}
                onColumnHeaderClick={handleColumnHeaderClick}
                onFetchRows={loadMore}
                onRowClick={onRowClick}
                onRowSelectionModelChange={(idList) =>
                  setSelectedRowIds(
                    idList.filter((id) => rows.find((row) => row.id === id))
                  )
                }
                onSortModelChange={handleSort}
                pinnedColumns={pinnedColumns}
                rowCount={rowsCount ?? 0}
                rowBuffer={10}
                rowHeight={isPipeline ? PIPELINE_ROW_HEIGHT : ROW_HEIGHT}
                rows={_rows}
                disableColumnReorder={true}
                rowsLoadingMode="server"
                rowSelectionModel={selectedRowIds}
                sortingMode="server"
                slots={{
                  noRowsOverlay: () => <>{emptyRowsView}</>,
                  columnSortedAscendingIcon: ArrowUpInverted,
                  columnUnsortedIcon: ArrowDownAlpha,
                  columnSortedDescendingIcon: ArrowDownInverted,
                  footer: CustomFooterComponent,
                }}
              />
              <ActionPanel
                isOpen={isActionPanelOpen}
                inDialog
                width={450}
                onClose={onActionPanelClose}
              >
                {actionPanelContent}
              </ActionPanel>
              {rightSideActionsWidget}
            </Box>
          </>
        )}
      </div>
    </FieldsContext.Provider>
  );
};

export default BaseDataGrid;

// Helpers

const lightHeaderRenderer = (
  lockHeaders: boolean,
  tooltip: any,
  params: GridColumnHeaderParams,
  classes: globalThis.Record<"headerIcon" | "infoIcon", string>,
  fields: any
) => {
  const wordCount = (params.colDef.headerName as string).split(/\s/).length;
  const shouldApplyLineClamp = wordCount > 2;
  const withLineClamp = (children: ReactNode) =>
    shouldApplyLineClamp ? (
      <span className="line-clamp-container">{children}</span>
    ) : (
      children
    );
  return (
    <>
      {lockHeaders && _.get(fields, `${params.colDef.field}.sort`, "") && (
        <LockLightInverted className={classes.headerIcon} />
      )}

      {withLineClamp(
        <GridColumnHeaderTitle
          columnWidth={params.colDef.width ?? 150}
          label={params.colDef.headerName ?? ""}
        />
      )}

      {tooltip && (
        <Tooltip title={tooltip}>
          <Info className={classes.infoIcon} data-testid="info-icon" />
        </Tooltip>
      )}
    </>
  );
};

const getOrderedSelectedCells = (
  gridApiRef: MutableRefObject<GridApiPremium>,
  fields: any,
  intl: IntlShape,
  amountFormatter: AmountFormatter
) => {
  const selectedCells = gridApiRef.current.unstable_getSelectedCellsAsArray();
  const visibleRows = gridPaginatedVisibleSortedGridRowIdsSelector(gridApiRef);

  // Order cells by row.
  // "getSelectedCellsAsArray" isn't giving the resutls in the right order.
  selectedCells.sort((a, b) => {
    const rowA = visibleRows.indexOf(a.id);
    const rowB = visibleRows.indexOf(b.id);

    if (rowA < rowB || rowB < 0) {
      return -1;
    } else if (rowA > rowB || rowA < 0) {
      return 1;
    } else {
      return 0;
    }
  });

  let currentRowId = selectedCells[0].id;
  let row = gridApiRef.current.getRow(currentRowId);
  let currentRowContent: string[] = [];
  let rows: string[] = [];
  _.each(selectedCells, (cell) => {
    if (cell.id !== currentRowId) {
      rows.push(currentRowContent.join("\t"));
      currentRowId = cell.id;
      currentRowContent = [];
      row = gridApiRef.current.getRow(currentRowId);
    }
    const cellContent = getCopyPasteContent(
      cell.field,
      row,
      fields,
      intl,
      amountFormatter
    );
    currentRowContent.push(cellContent);
  });
  rows.push(currentRowContent.join("\t"));

  return rows;
};

// CSS
export const tableContentStyles = makeStyles()((theme) => ({
  headerIcon: {
    height: 11,
    width: 12,
    marginTop: 5,
    marginRight: 6,
  },
  isClickable: {
    cursor: "pointer",
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  smallWidthAlert: {
    margin: 16,
    padding: 24,
  },
  infoIcon: {
    color: theme.palette.alpha500,
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
  archived: {
    // css selector to avoid opacity on cell borders
    "& :is(.MuiDataGrid-cell) p:not(.MuiPopperUnstyled-root p, .MuiButtonBase-root p), .MuiButtonBase-root, .MuiDataGrid-cellContent, .MuiAvatarGroup-root": {
      opacity: 0.33,
    },
  },
}));

// I18N

const i18n = defineMessages({
  upgradeMessage: {
    id: "crm.BaseDataGrid.upgradeMessage",
    defaultMessage: "Please upgrade to export New Prospects",
  },
  exportFeatureMessage: {
    id: "crm.BaseDataGrid.exportFeatureMessage",
    defaultMessage:
      "Extracting a large volume of data is premium feature, please use the export feature to get more Reveal's data",
  },
});
