import { SWQLNode } from "components/ui/SWQL/SWQLTypes";
import _ from "lodash";
import { JSONAPIResource } from "models/types";

export type JSONAPIOptions = {
  include?: string[];
  fields?: { [resource: string]: string[] };
  filters?: { [name: string]: FilterValue };
  page?: { size?: number; number?: number; offset?: number; limit?: number };
  sort?: string[] | { orderBy: string; order: string };
  recaptcha_token?: string | null;
};

export type JSONAPIServiceOptions = {
  url_resource?: string | null;
};

export type JSONAPIListResponse<R = string> = {
  data: JSONAPIResource<R>[];
  included?: JSONAPIResource[];
  meta?: {
    record_count?: number;
    defaults?: {
      [integrationSourceName: string]: {
        RawOpportunity?: { is_open: SWQLNode };
      };
    };
  };
  links?: {
    next?: string | null;
  };
};

export type JSONAPIResponse<R = string> = {
  data: JSONAPIResource<R>;
  included?: JSONAPIResource[];
};

export const isJSONAPIResponse = (value: any): value is JSONAPIResponse =>
  typeof value === "object" && "data" in value && !_.isArray(value.data);

export const isJSONAPIListResponse = (value: any): value is JSONAPIResponse =>
  typeof value === "object" && "data" in value && _.isArray(value.data);

export type FilterValue =
  | string
  | boolean
  | number
  | string[]
  | number[]
  | null;
