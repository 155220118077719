import SplashScreen from "components/ui/SplashScreen";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { selectViewForCurrentPartnership } from "redux/accountMapping/selector";
import { loadView, updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { retreive } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { RevealStore } from "redux/typing";

type Params = {
  recordId: string;
  resourceType: AccountMappingResource;
};

const RedirectToAccountMapping = ({ match }: RouteComponentProps<Params>) => {
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const { view } = useSelector(selectViewForCurrentPartnership);
  const currentType = view?.persisted?.activeViewType ?? null;
  const { recordId, resourceType } = match.params;
  const partnership = useSelector((state: RevealStore) =>
    _.get(_.get(state, "api.entities.partnerships"), recordId)
  );

  const isLeadOverlapsEnabled = useHasPayingFeature("LeadImport", profile);

  const validResourceType = Object.values(AccountMappingResource).includes(
    resourceType
  )
    ? resourceType !== AccountMappingResource.leadMatches ||
      isLeadOverlapsEnabled
      ? resourceType
      : AccountMappingResource.matches
    : AccountMappingResource.matches;

  const handleSelectResource = useCallback(async () => {
    await dispatch(updateView({ accountType: validResourceType }));
  }, [dispatch, validResourceType]);

  const loadPartnership = useCallback(async () => {
    await dispatch(
      retreive({
        id: +recordId,
        type: "partnerships",
        options: { include: included, fields },
      })
    );
  }, [recordId, dispatch]);

  const loadAccountMappingView = useCallback(async () => {
    await dispatch(
      loadView({
        partnership,
      })
    );
  }, [partnership, dispatch]);

  useEffect(() => {
    loadPartnership();
  }, [dispatch, loadPartnership]);

  useEffect(() => {
    if (partnership) {
      loadAccountMappingView();
    }
  }, [partnership, loadAccountMappingView]);

  useEffect(() => {
    if (currentType) {
      if (currentType !== validResourceType) {
        handleSelectResource().then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [currentType, validResourceType, handleSelectResource]);

  return loading ? (
    <SplashScreen />
  ) : (
    <Redirect to={`/partnerships/${recordId}/account-mapping`} />
  );
};

export default RedirectToAccountMapping;
