import { ChevronLeftRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { T } from "components/ui/Typography";
import { partnership as demo } from "data/demo";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { selectOverviewForPartnership } from "redux/overview/selectors";
import { initialize, updateView } from "redux/overview/thunks";
import { selectUpsidesData } from "redux/upsides/selectors";
import { loadPartnershipUpsidePotential } from "redux/upsides/thunks";
import { PartnershipUpsidePotentialData } from "redux/upsides/types";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { PartnersEvent } from "tracking";

import { getOverlapItemValues } from "../../../../../shared/utils";
import FilterButton from "./FilterButton";
import { i18n as dataLabels, OverviewData } from "./OverviewData";
import OverviewSharedFilters from "./OverviewSharedFilters";

type Props = {
  partnership: Partnership;
};

const Overview = ({ partnership }: Props) => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const { view, state: overview, fields } = useSelector(
    selectOverviewForPartnership(partnership)
  );
  const { data, status } = useSelector(selectUpsidesData);
  const {
    influenceableProspectsRevenuePotential,
    newProspectsRevenuePotential,
    sourceableProspectsRevenuePotential,
  } = getOverlapItemValues(partnership, data[partnership.id]);
  const upsides = data[partnership.id];
  const [isExpandedAccountOverlaps, setIsExpandedAccountOverlaps] = useState(
    true
  );
  const [isExpandedNewProspects, setIsExpandedNewProspects] = useState(true);
  const payingFeatures = useSelector(selectActivePayingFeatures);

  const { ready } = useSelector(selectDashboardData);

  useEffect(() => {
    if (partnership.isDemo) {
      track(PartnersEvent.viewOverviewDemo);
      return;
    }
    track(PartnersEvent.viewOverview);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready === null) {
      dispatch(initializePartnershipsAndCompanyNames());
    } else if (ready && status === null) {
      const hasAdvancedAnalyticsPayingFeature = payingFeatures.includes(
        PayingFeature.AdvancedAnalytics
      );
      dispatch(
        loadPartnershipUpsidePotential(hasAdvancedAnalyticsPayingFeature)
      );
    }
  }, [ready, status, payingFeatures, dispatch]);

  useEffect(() => {
    dispatch(initialize({ partnership }));
  }, [dispatch, partnership]);

  const onFilterChange = useCallback(
    (filters: FilterType[]) => {
      dispatch(updateView({ partnership, parameters: { shared: filters } }));
    },
    [dispatch, partnership]
  );

  return (
    <Grid container direction="column" className={classes.root} spacing={1}>
      <Grid item className={classes.dividerContainer}>
        <Box
          className={classes.dividerClickable}
          onClick={() => setIsExpandedAccountOverlaps?.((value) => !value)}
        >
          <T className={classes.dividerlabel}>
            <FormattedMessage {...i18n.accountOverlaps} />
          </T>
          {isExpandedAccountOverlaps ? (
            <ExpandMoreRounded className={classes.icon} />
          ) : (
            <ChevronLeftRounded className={classes.icon} />
          )}
        </Box>
        <Divider className={classes.divider} />
        <OverviewSharedFilters
          filters={view?.sharedFilters ?? []}
          onChange={onFilterChange}
          partnership={partnership}
        />
        <FilterButton
          filters={view?.sharedFilters}
          onChange={onFilterChange}
          fields={fields}
          partnerName={partnership.getPartner(profile).name}
        />
      </Grid>
      {isExpandedAccountOverlaps && (
        <>
          <Grid item>
            <OverviewData
              kpi="common_customers"
              partnership={partnership}
              label={dataLabels.commonCustomers}
              value={overview.commonCustomers.value?.count}
              closedAmount={overview.commonCustomers.value?.closed_amount}
              loading={overview.commonCustomers.loading}
              closedAmountLoading={overview.commonCustomers.loading}
              sharedFilters={view?.sharedFilters}
            />
          </Grid>
          <Grid item>
            <OverviewData
              kpi="open_opportunities_to_customers"
              partnership={partnership}
              label={dataLabels.openOpportunitiesToCustomers}
              value={overview.openOpportunitiesToCustomers.value?.count}
              potentialRevenue={getPotentialRevenue(
                overview.openOpportunitiesToCustomers.value?.count ?? 0,
                upsides
              )}
              loading={overview.openOpportunitiesToCustomers.loading}
              potentialRevenueLoading={
                overview.openOpportunitiesToCustomers.loading
              }
              sharedFilters={view?.sharedFilters}
              isUpsidesField
            />
          </Grid>
          <Grid item>
            <OverviewData
              kpi="common_opportunities"
              partnership={partnership}
              label={dataLabels.commonOpenOpportunities}
              value={overview.commonOpenOpportunities.value?.count}
              pipeline={overview.commonOpenOpportunities.value?.pipeline_amount}
              loading={overview.commonOpenOpportunities.loading}
              pipelineLoading={overview.commonOpenOpportunities.loading}
              sharedFilters={view?.sharedFilters}
            />
          </Grid>
          <Grid item>
            <OverviewData
              kpi="prospects_to_customers"
              partnership={partnership}
              label={dataLabels.prospectToCustomers}
              value={overview.prospectToCustomers.value?.count}
              potentialRevenue={
                (influenceableProspectsRevenuePotential ?? 0) +
                (sourceableProspectsRevenuePotential ?? 0)
              }
              loading={overview.prospectToCustomers.loading}
              potentialRevenueLoading={overview.prospectToCustomers.loading}
              sharedFilters={view?.sharedFilters}
              isUpsidesField
            />
          </Grid>
          <Grid item>
            <OverviewData
              kpi="customers_to_prospects"
              partnership={partnership}
              label={dataLabels.customerToProspects}
              value={overview.customerToProspects.value?.count}
              loading={overview.customerToProspects.loading}
              sharedFilters={view?.sharedFilters}
            />
          </Grid>
        </>
      )}
      <Grid item className={classes.dividerContainer}>
        <Box
          className={classes.dividerClickable}
          onClick={() => setIsExpandedNewProspects?.((value) => !value)}
        >
          <T className={classes.dividerlabel}>
            <FormattedMessage {...i18n.newProspects} />
          </T>
          {isExpandedNewProspects ? (
            <ExpandMoreRounded className={classes.icon} />
          ) : (
            <ChevronLeftRounded className={classes.icon} />
          )}
        </Box>
        <Divider className={classes.divider} />
      </Grid>
      {isExpandedNewProspects && (
        <>
          <Grid item>
            <OverviewData
              kpi="nb_of_new_prospects"
              partnership={partnership}
              label={dataLabels.newProspects}
              value={partnership?.insights?.companyNbOfNewProspects}
              potentialRevenue={newProspectsRevenuePotential}
              potentialRevenueLoading={status === "pending"}
              accountType={AccountMappingResource.shared_accounts}
              isUpsidesField
            />
          </Grid>
          <Grid item>
            <OverviewData
              kpi="partner_nb_of_new_prospects"
              partnership={partnership}
              label={dataLabels.partnerNewProspects}
              value={partnership?.insights?.partnerNbOfNewProspects}
              accountType={AccountMappingResource.shared_accounts}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const DemoOverview = () => <Overview partnership={demo} />;

export default Overview;

const getPotentialRevenue = (
  value: number,
  upsides: PartnershipUpsidePotentialData
) => {
  return value * (upsides?.avgWinRate ?? 0) * (upsides?.avgDealSize ?? 0);
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  divider: {
    color: theme.palette.greyLight100,
    flexGrow: 1,
  },
  dividerContainer: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    minHeight: theme.spacing(6),
  },
  dividerClickable: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    gap: theme.spacing(1),
  },
  dividerlabel: {
    color: theme.palette.midnight,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "10px",
  },
  icon: {
    color: theme.palette.purple700,
    height: 15,
    width: 15,
    marginLeft: -4,
    marginRight: theme.spacing(1),
  },
  root: {
    padding: 24,
  },
}));

// I18n

const i18n = defineMessages({
  accountOverlaps: {
    id: "Overview.accountOverlaps",
    defaultMessage: "ACCOUNT OVERLAPS",
  },
  newProspects: {
    id: "Overview.newProspects",
    defaultMessage: "NEW PROSPECTS",
  },
  close: {
    id: "Overview.close",
    defaultMessage: "Close",
  },
});
