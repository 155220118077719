import { getValidatedEnvironment } from "./schema";

const env = getValidatedEnvironment();

export const askApiHost = env.services.http.ask;
export const crmExporterApiHost = env.services.http.crmExporter;
export const insightsApiHost = env.services.http.insights;
export const messagesApiHost = env.services.http.messages;
export const messagingApiHost = env.services.http.messaging;
export const messagingWebsocketHost = env.services.websocket.messaging;
export const notificationsApiHost = env.services.http.notifications;
export const diggerApiHost = env.services.http.digger;
export const searchHost = env.services.http.search;
export const segmentTag = env.segmentTag;
export const facebookId = env.facebookId;
export const reCaptchaSiteKey = env.reCaptchaSiteKey;
export const slackApiHost = env.services.http.slack;
export const synchronizerApiHost = env.services.http.synchronizer;
export const upsidesApiHost = env.services.http.upsides;
export const webhooksApiHost = env.services.http.webhooks;

export const gsheetClientId = env.crmProviders.gsheet;
export const hubspotClientId = env.crmProviders.hubspot;
export const msdynamicsClientId = env.crmProviders.ms_dynamics;
export const pipedriveClientId = env.crmProviders.pipedrive;
export const salesforceClientId = env.crmProviders.salesforce;
export const zohoClientId = env.crmProviders.zoho;
export const slackClientId = env.slackClientId;
export const partnerStackClientId = env.partnerStackClientId;

export const backofficeBase = env.hosts.backoffice;
export const partnersBase = env.hosts.frontoffice;

if (askApiHost === undefined) {
  throw new Error("Ask API host must be defined");
}
if (crmExporterApiHost === undefined) {
  throw new Error("Crm Exporter API host must be defined");
}
if (insightsApiHost === undefined) {
  throw new Error("Insights API host must be defined");
}
if (diggerApiHost === undefined) {
  throw new Error("Digger API host must be defined");
}
if (messagesApiHost === undefined) {
  throw new Error("Messages API host must be defined");
}
if (messagingApiHost === undefined) {
  throw new Error("Messaging API host must be defined");
}
if (notificationsApiHost === undefined) {
  throw new Error("Notifications API host must be defined");
}
if (slackApiHost === undefined) {
  throw new Error("Slack API host must be defined");
}
if (synchronizerApiHost === undefined) {
  throw new Error("Synchronizer API host must be defined");
}
if (upsidesApiHost === undefined) {
  throw new Error("Upsides API host must be defined");
}
if (webhooksApiHost === undefined) {
  throw new Error("Webhooks API host must be defined");
}

export const servicesMap: { [resource: string]: string } = {
  account_mapping_settings: diggerApiHost,
  account_mapping_sharing_settings: diggerApiHost,
  account_mapping_views: diggerApiHost,
  allowed_accounts_filters: diggerApiHost,
  chat_room_handles: messagingApiHost,
  chat_rooms: messagingApiHost,
  collaborate_reports: diggerApiHost,
  company_notification_settings: diggerApiHost,
  conversations: messagesApiHost,
  crm_accounts: diggerApiHost,
  crm_fields: diggerApiHost,
  crm_exports: diggerApiHost,
  cta: diggerApiHost,
  default_channels: slackApiHost,
  company_channels: slackApiHost,
  directory_companies: insightsApiHost,
  discussion_messages: messagesApiHost,
  nearbound_accounts: insightsApiHost,
  nearbound_prospects: insightsApiHost,
  dollar_exchange_rates: diggerApiHost,
  ghost_integrations: diggerApiHost,
  ghost_sources: diggerApiHost,
  ghost_source_configurations: diggerApiHost,
  integrations: diggerApiHost,
  integration_administrations: diggerApiHost,
  integration_syncs: synchronizerApiHost,
  introduction_notification_settings: diggerApiHost,
  introduction_requests: diggerApiHost,
  introduction_request_forms: diggerApiHost,
  invitation_urls: askApiHost,
  lead_matches: diggerApiHost,
  license_requests: askApiHost,
  mapping_rules: diggerApiHost,
  matches: diggerApiHost,
  messages: messagingApiHost,
  my_ecosystem_views: diggerApiHost,
  sales_digest_reports: diggerApiHost,
  nearbound_account_reports: insightsApiHost,
  nearbound_prospect_reports: insightsApiHost,
  notifications: diggerApiHost,
  onboarding_skips: askApiHost,
  opportunity_sharing_level_attributions: diggerApiHost,
  opportunity_sharing_level_definitions: diggerApiHost,
  page_views: diggerApiHost,
  partner_analytics: insightsApiHost,
  potential_partner_overlaps: diggerApiHost,
  referred_accounts: diggerApiHost,
  restricted_accounts_filters: diggerApiHost,
  reports: diggerApiHost,
  runs: diggerApiHost,
  saved_filters: diggerApiHost,
  shared_accounts: diggerApiHost,
  slack_channels: slackApiHost,
  slack_credentials: askApiHost,
  slack_messages: diggerApiHost,
  sources: diggerApiHost,
  source_configurations: diggerApiHost,
  status_configs: diggerApiHost,
  suggested_partners: diggerApiHost,
  summary_metrics: upsidesApiHost,
  sync_to_crm_settings: diggerApiHost,
  partner_connections: diggerApiHost,
  attributed_opportunities: insightsApiHost,
  partner_connection_sync_rules: diggerApiHost,
  partner_field_mappings: crmExporterApiHost,
  partner_field_mapping_syncs: crmExporterApiHost,
  partner_stack_credentials: askApiHost,
  partnership_administrations: diggerApiHost,
  partnership_channels: slackApiHost,
  partnership_crm_fields: diggerApiHost,
  partnership_invites: diggerApiHost,
  partnership_tags: diggerApiHost,
  partnership_overview_views: diggerApiHost,
  partnership_upside_potentials: upsidesApiHost,
  partnership_rawcompany_mappings: diggerApiHost,
  partnerships: diggerApiHost,
  partnerstack: diggerApiHost,
  widget_partner_fields: crmExporterApiHost,
};

export const resourcesWithV2Enabled = ["crm_accounts"];

export const languages = [
  ["en", "language__english"],
  ["fr", "language__french"],
];

const slackScopes = [
  "chat:write",
  "chat:write.customize",
  "im:write",
  "mpim:write",
  "users:read",
  "users:read.email",
  "channels:read",
  "channels:join",
  "groups:read",
];

const partnerStackScopes = [
  "lead:read",
  "lead:write",
  "form_template:read",
  "group:read",
  "partnership:read",
  "partnership:write",
  "deal:read",
  "deal:write",
];

// This order is important, it defines the order of the items
// in the "Connect a data source" page.
export enum ProviderType {
  gsheet = "gsheet",
  salesforce = "salesforce",
  hubspot = "hubspot",
  msDynamics = "ms_dynamics",
  pipedrive = "pipedrive",
  zoho = "zoho",
  stripe = "stripe",
  freshsales = "freshsales",
  upsales = "upsales",
  file = "file",
  slack = "slack",
  partnerStack = "partnerstack",
}

export const isProviderType = (value: any): value is ProviderType => {
  return (
    typeof value === "string" &&
    Object.values(ProviderType).includes(value as ProviderType)
  );
};

type ProviderConfig = {
  name: string;
  logo: string;
  oauth?: string;
  oauthSandbox?: string;
  clientId?: string;
  scope?: string;
};

export type CrmProviderType = { [provider in ProviderType]: ProviderConfig };

export const crmProviders: CrmProviderType = {
  [ProviderType.gsheet]: {
    name: "Google Sheets",
    logo: "/images/providers/Gsheet.png",
    oauth:
      "https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&",
    clientId: gsheetClientId,
  },
  [ProviderType.hubspot]: {
    name: "HubSpot",
    logo: "/images/providers/Hubspot.png",
    oauth: "https://app.hubspot.com/oauth/authorize?",
    clientId: hubspotClientId,
  },
  [ProviderType.msDynamics]: {
    name: "Microsoft Dynamics",
    logo: "/images/providers/MSDynamics.png",
    oauth: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?",
    clientId: msdynamicsClientId,
  },
  [ProviderType.pipedrive]: {
    name: "Pipedrive",
    logo: "/images/providers/Pipedrive.png",
    oauth: "https://oauth.pipedrive.com/oauth/authorize?",
    clientId: pipedriveClientId,
  },
  [ProviderType.salesforce]: {
    name: "Salesforce",
    logo: "/images/providers/Salesforce.png",
    oauth: "https://login.salesforce.com/services/oauth2/authorize?",
    oauthSandbox: "https://test.salesforce.com/services/oauth2/authorize?",
    clientId: salesforceClientId,
  },
  [ProviderType.zoho]: {
    name: "Zoho",
    logo: "/images/providers/Zoho.png",
    oauth: "https://accounts.zoho.com/oauth/v2/auth?",
    clientId: zohoClientId,
    scope:
      "ZohoCRM.modules.READ,ZohoCRM.settings.READ,ZohoCRM.users.READ,ZohoCRM.org.READ",
  },
  [ProviderType.upsales]: {
    name: "Upsales",
    logo: "/images/providers/Upsales.png",
  },
  [ProviderType.freshsales]: {
    name: "Freshsales",
    logo: "/images/providers/Freshsales.png",
  },
  [ProviderType.file]: {
    name: "CSV",
    logo: "/images/providers/CSV.png",
  },
  [ProviderType.stripe]: {
    name: "Stripe",
    logo: "/images/providers/Stripe.png",
  },
  [ProviderType.slack]: {
    name: "",
    logo: "",
    oauth: "https://slack.com/oauth/v2/authorize?",
    clientId: slackClientId,
    scope: slackScopes.join(","),
  },
  [ProviderType.partnerStack]: {
    name: "",
    logo: "",
    oauth: "https://auth.partnerstack.com/oauth/authorize?response_type=code&",
    clientId: partnerStackClientId,
    scope: partnerStackScopes.join(" "),
  },
};

export const getLogo = (provider: ProviderType | null) => {
  switch (provider) {
    case ProviderType.file:
      return "/images/providers/CSV.png";
    case null:
      return undefined;
    default:
      const details = crmProviders[provider];
      if (details) {
        return details.logo;
      }
      return undefined;
  }
};

export const syncToCRMConfigurableProviders = [
  ProviderType.salesforce,
  ProviderType.hubspot,
];
export const exportToCRMProviders = [ProviderType.salesforce];
export const salesNotificationsProviders = [ProviderType.salesforce];
export const customFieldMappingProviders = [
  ProviderType.salesforce,
  ProviderType.hubspot,
];

export const BLANK = "(Blank)";
export const NOT_IN_REVEAL = "(Other)";

// Free plan limits
export const MAPPING_360_PARTNER_LIMIT = 10;
export const MAPPING_360_ROWS_LIMIT = 5;
export const WEEKLY_DIGEST_LIMIT = 5;
export const SHARED_PIPELINE_LIMIT = 3;
export const OFFLINE_ACCOUNT_MAPPING_LIMIT = 3;
export const DATA_EXPORT_LIMIT = 300;
export const SMART_VIEW_FREE_LIMIT = 1;
export const SMART_VIEW_PRO_LIMIT = 5;

export const APPROVAL_REQUEST_PERIOD_IN_MILLIS = 24 * 60 * 60 * 1000;
export const LICENSE_REQUEST_PERIOD_IN_MILLIS = 48 * 60 * 60 * 1000;
