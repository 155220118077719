import { Divider, Grid, Popover } from "@mui/material";
import { ChevronDown } from "components/icons";
import { QuickViewFilter } from "components/ui/BaseQuickViews";
import Button from "components/ui/Button";
import DraggableContainer from "components/ui/DraggableContainer";
import DraggableItem from "components/ui/DraggableItem";
import useSegment from "hooks/useSegment";
import { useSmartViews } from "hooks/useSmartViews";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import SavedFilter from "models/SavedFilter";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { asJSONSerializable } from "redux/typing";
import { unPinnableFilters } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/quickFilterPresets";
import { isIGoalPreset } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/goalsPresets";
import MappingFilterContext from "screens/Frontoffice/screens/DataTables/shared/contexts/MappingFilterContext";
import {
  Facets,
  FilterType,
  Preset,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  ExtendedAccountMappingResource,
  SmartViewSegmentEvent,
} from "./constants";
import { useSmartViewTableConfig } from "./helpers";
import { SmartViewDropdownFooter } from "./SmartViewDropdownFooter";
import { SmartViewDropdownHeader } from "./SmartViewDropdownHeader";
import { SmartViewDropdownListItem } from "./SmartViewDropdownListItem";
import { savedFilterTypeFromViewType } from "./utils";

type Props = {
  accountType: ExtendedAccountMappingResource;
  defaultFilterList?: QuickViewFilter[];
  facets?: Facets;
  filterList?: QuickViewFilter[];
  applyPreset: (
    preset: Preset,
    viewType: ExtendedAccountMappingResource
  ) => void;
  onSelectSmartView: (id?: number) => void;
  partnershipId?: number;
  selectedSmartViewId?: number;
};

export const SmartViewDropdown = ({
  accountType,
  applyPreset,
  defaultFilterList,
  facets,
  filterList,
  onSelectSmartView,
  partnershipId,
  selectedSmartViewId,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { records } = useSmartViews(accountType, partnershipId);
  const { classes: popoverClasses } = usePopoverStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const [sortedList, setSortedList] = useState<SavedFilter[] | null>(null);
  const [isSettingTableFilters, setIsSettingTableFilters] = useState(false);
  const { orderArray, updateViewThunk, view } = useSmartViewTableConfig({
    accountType,
  });
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { defaultView } = useContext(MappingFilterContext);

  const pinnedSortedFilters =
    filterList?.map(
      (filter) =>
        records.find((record) => record.id === filter.key) ??
        new SavedFilter({
          id: filter.key.toString(),
          type: "saved_filters",
          attributes: {
            filters: asJSONSerializable(filter.presets.filter),
            sort: asJSONSerializable(filter.presets.sort),
            has_custom_partner_field: false,
            name:
              typeof filter.name === "object"
                ? intl.formatMessage(filter.name)
                : filter.name,
            view_type: savedFilterTypeFromViewType(
              filter.viewType ?? AccountMappingResource.matches
            ),
            shared_to_company: false,
          },
        })
    ) ?? [];
  const unpinnedDefaultFilters =
    defaultFilterList
      ?.filter(
        (defaultItem) =>
          !filterList?.some((item) => item.key === defaultItem.key)
      )
      ?.map(
        (filter) =>
          new SavedFilter({
            id: filter.key.toString(),
            type: "saved_filters",
            attributes: {
              filters: asJSONSerializable(filter.presets.filter),
              sort: asJSONSerializable(filter.presets.sort),
              has_custom_partner_field: false,
              name:
                typeof filter.name === "object"
                  ? intl.formatMessage(filter.name)
                  : filter.name,
              view_type: savedFilterTypeFromViewType(
                filter.viewType ?? AccountMappingResource.matches
              ),
              shared_to_company: false,
            },
          })
      ) ?? [];
  const unPinnedFilters = unpinnedDefaultFilters.concat(
    records.filter(
      (smartView) =>
        !pinnedSortedFilters.some((filter) => filter.id === smartView.id)
    )
  );
  const selectedSmartFilter = [...pinnedSortedFilters, ...unPinnedFilters].find(
    (record) => record.id === selectedSmartViewId
  );

  useEffect(() => {
    if (selectedSmartViewId && !selectedSmartFilter) {
      onSelectSmartView(undefined);
    }
  }, [onSelectSmartView, selectedSmartFilter, selectedSmartViewId]);

  useEffect(() => {
    if (
      isSettingTableFilters &&
      selectedSmartViewId &&
      _.isEqual(
        isIGoalPreset(defaultView)
          ? view?.filters.filter(
              (item: FilterType) =>
                !defaultView.presets.filter.some(
                  (defaultFilter) => defaultFilter.fieldname === item.fieldname
                )
            )
          : view?.filters,
        selectedSmartFilter?.filters
      )
    ) {
      setIsSettingTableFilters(false);
    }
  }, [
    isSettingTableFilters,
    selectedSmartFilter?.filters,
    selectedSmartViewId,
    view?.filters,
    defaultView,
  ]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    cancelEvent(event);
    setAnchorEl(event.currentTarget);
    track(SmartViewSegmentEvent.ViewsButtonClicked, {
      isPaidUser: profile.isPaidCustomer(payingFeatures),
    });
  };

  const handleClose = (event: MouseEvent) => {
    cancelEvent(event);
    setAnchorEl(null);
  };

  const reorder = async (startIndex: number, endIndex: number) => {
    const sortedList = _.cloneDeep(pinnedSortedFilters);
    const [removedView] = sortedList.splice(startIndex, 1);
    sortedList.splice(endIndex, 0, removedView);
    setSortedList(sortedList);
    await dispatch(
      updateViewThunk({
        filterOrderList: sortedList.map((item) => item.id),
      })
    );
    setSortedList(null);
  };

  const handleSelectSavedFilter = (id: number) => {
    setIsSettingTableFilters(true);
    onSelectSmartView(id);
    setAnchorEl(null);
  };

  const handleUpdateOrder = (id: number) => async (newIndex?: number) => {
    const smartView = [...pinnedSortedFilters, ...unPinnedFilters].find(
      (record) => record.id === id
    );
    if (
      !smartView ||
      unPinnableFilters.some((item) => Number(item.key) === smartView.id)
    ) {
      return;
    }
    const order = _.cloneDeep(orderArray);
    if (typeof newIndex === "number") {
      order.splice(newIndex, 0, id);
    } else {
      order.splice(order.indexOf(id), 1);
    }
    await dispatch(
      updateViewThunk({
        filterOrderList: order,
      })
    );
  };

  return (
    <div>
      <Button
        RightIcon={ChevronDown}
        label={i18n.more}
        onClick={handleClick}
        size="small"
      />
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={popoverClasses}
      >
        <div className={classes.popupContainer}>
          <div className={classes.scrollContainer}>
            {pinnedSortedFilters.length > 0 && (
              <Grid container direction="column">
                <SmartViewDropdownHeader
                  text={intl.formatMessage(i18n.pinnedViews)}
                />
                <DraggableContainer handleDragEnd={reorder}>
                  {(sortedList ?? pinnedSortedFilters).map(
                    (filter, index: number) => (
                      // @ts-expect-error ts-migrate(2786) FIXME: 'DraggableItem' cannot be used as a JSX component.
                      <DraggableItem
                        classes={{ dragIcon: classes.dragIcon }}
                        key={filter.id}
                        id={filter.id.toString()}
                        index={index}
                        isLoading={sortedList !== null}
                      >
                        <SmartViewDropdownListItem
                          applyPreset={applyPreset}
                          facets={facets}
                          isPinned={true}
                          key={`smart-view-dropdown-list-pinned-item-${filter.id}`}
                          onOrderChange={handleUpdateOrder(filter.id)}
                          onSelect={handleSelectSavedFilter}
                          savedFilter={filter}
                          selectedFilterId={selectedSmartViewId}
                          accountType={accountType}
                          partnershipId={partnershipId}
                          onAddSmartView={onSelectSmartView}
                        />
                      </DraggableItem>
                    )
                  )}
                </DraggableContainer>
              </Grid>
            )}
            <Grid container direction="column">
              {unPinnedFilters.length > 0 && (
                <>
                  <SmartViewDropdownHeader
                    text={intl.formatMessage(i18n.others)}
                  />
                  {unPinnedFilters.map((filter) => (
                    <SmartViewDropdownListItem
                      applyPreset={applyPreset}
                      key={`smart-view-dropdown-list-item-${filter.id}`}
                      onSelect={handleSelectSavedFilter}
                      onOrderChange={handleUpdateOrder(filter.id)}
                      savedFilter={filter}
                      selectedFilterId={selectedSmartViewId}
                      accountType={accountType}
                      partnershipId={partnershipId}
                      onAddSmartView={onSelectSmartView}
                    />
                  ))}
                </>
              )}
            </Grid>
          </div>
          <Grid container direction="column">
            {(unPinnedFilters.length > 0 || pinnedSortedFilters.length > 0) && (
              <Divider className={classes.divider} />
            )}
            <SmartViewDropdownFooter
              isLoading={isSettingTableFilters}
              handleClose={handleClose}
            />
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

// To prevent calling the parent's toggle button group's onClick
const cancelEvent = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const i18n = defineMessages({
  more: {
    id: "SmartViewDropdown.more",
    defaultMessage: "Saved views",
  },
  others: {
    id: "SmartViewDropdown.others",
    defaultMessage: "Others",
  },
  pinnedViews: {
    id: "SmartViewDropdown.pinnedViews",
    defaultMessage: "Pinned views",
  },
});

const useStyles = makeStyles()((theme) => ({
  divider: {
    marginBottom: 8,
  },
  dragIcon: {
    padding: 0,
    margin: 0,
  },
  popupContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  scrollContainer: {
    minHeight: 0,
    overflow: "auto",
  },
}));

const usePopoverStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: 10,
    boxShadow: `-2px 3px 6px ${theme.palette.taupe} !important`,
    width: "fit-content",
    minWidth: 300,
    background: theme.palette.ivory,
    padding: theme.spacing(1),
    display: "flex",
    maxHeight: 445,
  },
}));
