import { isProviderType, ProviderType } from "config/constants";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Record from "models/Record";
import { JSONAPIResource } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import JSONAPIService from "services/JSONAPIService";
import SearchService from "services/SearchService";

export type CrmAccountSearchResult = {
  id: number;
  name: string;
  status: number | null;
  ownerFullName: string | null;
  provider: ProviderType | null;
  source: Record;
};

const LEGACY_RESOURCE = "crm_accounts";
const NEARBOUND_RESOURCE = "nearbound_accounts";

export const useCrmAccountsSearch = (
  query: string,
  setOpen: (isOpen: boolean) => void,
  isActive: boolean = true
) => {
  const { profile } = useUserProfile();
  const is360NearboundAccountsUnlocked = useHasPayingFeature(
    PayingFeature.UseNew360,
    profile
  );
  const resource = is360NearboundAccountsUnlocked
    ? NEARBOUND_RESOURCE
    : LEGACY_RESOURCE;

  const [integrationProviderMap, setIntegrationProviderMap] = useState<
    Map<number, ProviderType>
  >(new Map<number, ProviderType>());
  const [options, setOptions] = useState<Record[]>([]);
  const [loading, setLoading] = useState(false);

  const mapToCrmAccountSearchResult = useCallback(
    (record: Record): CrmAccountSearchResult => {
      if (record.type === LEGACY_RESOURCE) {
        return {
          id: +record.id,
          name: String(record.name),
          status: record.status ? +record.status : null,
          ownerFullName: record.owner?.full_name
            ? String(record.owner.full_name)
            : null,
          provider: isProviderType(record.provider) ? record.provider : null,
          source: record,
        };
      }
      return {
        id: +record.rawCompanyId,
        name: String(record.name),
        status: record.status ? +record.status : null,
        ownerFullName: record.ownerFullName
          ? String(record.ownerFullName)
          : null,
        provider: integrationProviderMap.get(+record.integrationId) ?? null,
        source: { ...record, id: +record.rawCompanyId },
      };
    },
    [integrationProviderMap]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const fetchOptions = useCallback(
    _.debounce(
      (input: string, callback: (value: { records: Record[] }) => void) => {
        if (input !== "") {
          setLoading(true);
          SearchService(resource)
            .autocomplete(input)
            .perform({ "page[size]": 5 })
            .then(callback)
            .finally(() => setLoading(false));
        }
      },
      250
    ),
    [resource]
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    let active = true;
    if (query === "") {
      setOptions([]);
      return undefined;
    } else {
      fetchOptions(query, ({ records }: { records: Record[] }) => {
        if (active) {
          setOptions(records);
          records.length > 0 && setOpen(true);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [fetchOptions, query, setOpen]);

  useEffect(() => {
    let active = true;
    if (resource !== NEARBOUND_RESOURCE || !isActive) {
      return;
    }
    const service = new JSONAPIService("integrations");
    service
      .index({ fields: { integrations: ["id", "provider"] } })
      .then((response) => {
        if (active) {
          const mapping = new Map<number, ProviderType>();
          response.data.data.forEach((integration: JSONAPIResource) => {
            const provider = integration.attributes?.provider;
            if (isProviderType(provider)) {
              mapping.set(+integration.id, provider);
            }
          });
          setIntegrationProviderMap(mapping);
        }
      });
    return () => {
      active = false;
    };
  }, [resource, isActive]);

  const exposedOptions = useMemo(
    () => options.map(mapToCrmAccountSearchResult),
    [options, mapToCrmAccountSearchResult]
  );

  return {
    options: exposedOptions,
    loading,
  };
};
