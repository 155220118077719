import {
  array,
  boolean,
  constant,
  guard,
  iso8601,
  number,
  object,
  optional,
  string,
} from "decoders";
import {
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  filterDecoder,
  sortDecoder,
} from "screens/Frontoffice/screens/DataTables/shared/types/decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const apiSavedFilterDecoder = object({
  type: constant("saved_filters" as const),
  id: string,
  attributes: object({
    name: string,
    view_type: number,
    updated_at: optional(iso8601),
    created_at: optional(iso8601),
    shared_to_company: boolean,
    has_custom_partner_field: boolean,
    filters: array(filterDecoder),
    sort: array(sortDecoder),
    user_id: optional(number),
  }),
});
const apiSavedFilterGuard = guard(apiSavedFilterDecoder);

export enum SavedFilterType {
  AccountMapping,
  LeadOverlaps,
  NewProspects,
  MyEcosystem,
  NearboundProspects,
  NearboundAccounts,
}

export default class SavedFilter extends Record {
  name: string;
  company: Record | null = null;
  partnership: Record | null = null;
  viewType: SavedFilterType;
  updatedAt?: Date | null = null;
  createdAt?: Date | null = null;
  sharedToCompany: boolean = false;
  hasCustomPartnerField: boolean = false;
  filters: FilterType[] = [];
  sort: SortType[] = [];
  userId?: number;

  constructor(data: JSONAPIResourceOrRecord<"saved_filters">) {
    super(data);
    const { attributes } = apiSavedFilterGuard(data);

    this.name = attributes.name;
    if (this.relationships.company?.data instanceof Record) {
      this.company = this.relationships.company.data;
    }
    if (this.relationships.partnership?.data instanceof Record) {
      this.partnership = this.relationships.partnership.data;
    }
    this.viewType = attributes.view_type;
    this.updatedAt = attributes?.updated_at;
    this.createdAt = attributes?.created_at;
    this.sharedToCompany = attributes.shared_to_company;
    this.hasCustomPartnerField = attributes.has_custom_partner_field;
    this.filters = attributes.filters;
    this.sort = attributes.sort;
    this.userId = attributes.user_id;
  }
}
