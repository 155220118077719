import { isRejected } from "@reduxjs/toolkit";
import { getUpdatedFiltersAndSort } from "components/ui/BaseQuickViews";
import { DiffObject } from "helpers/diffObjects";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { NewLeadsShare } from "models/AccountMappingSettings";
import { ShareOwner } from "models/AccountMappingSharingSettings";
import Partnership, { ShareAccounts } from "models/Partnership";
import Record from "models/Record";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectViewForCurrentPartnership } from "redux/accountMapping/selector";
import { updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { create, rawPost, retreive, update } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { setCompany } from "redux/user/actions";
import { overlapsQuickViewFilteredFields } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/QuickFilters";
import { quickFilterPresets } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/quickFilterPresets";
import { PartnershipEvent } from "tracking";

export const useUpdateDataSharing = () => {
  const { track } = useSegment();
  const { tableConfig } = useSelector(selectViewForCurrentPartnership);
  const dispatch = useDispatch();
  const { profile } = useUserProfile();

  const updateSharedAccountMappingCount = useCallback(
    (isShared: boolean) => {
      const company = _.cloneDeep(profile.company);
      company.nbSharedAccountMapping += isShared ? 1 : -1;
      dispatch(setCompany(company));
    },
    [profile.company, dispatch]
  );

  return useCallback(
    async (
      changes: DiffObject,
      partnership: Partnership,
      company: Record,
      partnerName: string
    ) => {
      let mayUpdateSharing = () => Promise.resolve();
      let mayUpdateSharingSettings = () => Promise.resolve();
      let errors: $TSFixMe = [];

      if (changes.dataSharingStatus) {
        const {
          newValue: newStatus,
          oldValue: oldStatus,
        } = changes.dataSharingStatus;

        const shareAccountsChanged = Partnership.hasSharingOverlappingChanged(
          newStatus,
          oldStatus
        );
        const shareCommonCustomersChanged = Partnership.hasSharingCommonCustomersChanged(
          newStatus,
          oldStatus
        );
        const shareNewLeadsChanged = Partnership.hasSharingWhitespaceChanged(
          newStatus,
          oldStatus
        );

        if (
          shareAccountsChanged ||
          shareCommonCustomersChanged ||
          shareNewLeadsChanged
        ) {
          const shareAccounts = Partnership.getAccountStatusSharingFromDataSharingStatus(
            newStatus
          );
          const shareNewLeads = Partnership.isSharingWhitespace(newStatus);

          mayUpdateSharing = async () => {
            const response = await dispatch(
              rawPost({
                type: "partnerships",
                id: partnership.id,
                path: "/update-account-mapping-settings/",
                payload: {
                  data: {
                    sharing_settings: {
                      share_accounts: shareAccounts,
                      share_new_leads: shareNewLeads
                        ? NewLeadsShare.Always
                        : NewLeadsShare.Never,
                    },
                    type: "partnerships",
                    id: partnership.id,
                  },
                },
                options: {
                  config: {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  },
                  fields,
                  include: included,
                },
              })
            );
            if (isRejected(response)) {
              errors.push(response.payload);
            }

            if (shareAccountsChanged) {
              updateSharedAccountMappingCount(
                shareAccounts !== ShareAccounts.Never
              );
              track(
                partnership.getTrackingMessageForOverlappingChange(company),
                {
                  partner: partnerName,
                  partnerId: partnership.getPartner(profile).id,
                  from: "Partner settings",
                }
              );
            }
            if (
              shareCommonCustomersChanged &&
              Partnership.isSharingCommonCustomers(newStatus)
            ) {
              // Apply common customers quick filter to existing filters
              const updatedFiltersAndSort = getUpdatedFiltersAndSort(
                quickFilterPresets.commonCustomers,
                tableConfig.filters,
                overlapsQuickViewFilteredFields
              );
              dispatch(
                updateView({
                  accountType: AccountMappingResource.matches,
                  filters: updatedFiltersAndSort.filter,
                  sort: updatedFiltersAndSort.sort,
                })
              );
            }
            if (shareNewLeadsChanged) {
              let trackingMessage = PartnershipEvent.disabledNP;
              if (shareNewLeads) {
                trackingMessage = PartnershipEvent.enabledNP;
              }
              track(trackingMessage, {
                partnerName: partnerName,
                partnerId: partnership.getPartner(profile).id,
                from: "Partner settings",
              });
            }
          };
        }
      }

      if (changes.shareOwner || changes.shareOpports) {
        const data: { share_owner?: ShareOwner; share_opports?: boolean } = {};
        if (changes.shareOwner) {
          data.share_owner = changes.shareOwner.newValue;
        }
        if (changes.shareOpports) {
          data.share_opports = changes.shareOpports.newValue;
        }
        const sharingSettings = partnership.getSharingSettings(company);

        mayUpdateSharingSettings = async () => {
          if (!sharingSettings) {
            const response = await dispatch(
              create({
                type: "account_mapping_sharing_settings",
                attributes: data,
                relationships: {
                  partnership: {
                    id: partnership.id + "",
                    type: "partnerships",
                  },
                  company: {
                    id: company.id + "",
                    type: "companies",
                  },
                },
              })
            );

            if (isRejected(response)) {
              errors.push(response.payload);
            }

            await dispatch(
              retreive({
                type: "partnerships",
                id: partnership.id,
                options: {
                  fields,
                  include: included,
                },
              })
            );
          } else {
            const response = await dispatch(
              update({
                type: "account_mapping_sharing_settings",
                id: sharingSettings.id,
                attributes: data,
              })
            );

            if (isRejected(response)) {
              errors.push(response.payload);
            }
          }
        };
      }

      await mayUpdateSharing();
      await mayUpdateSharingSettings();

      return errors;
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
};
