import { Grid } from "@mui/material";
import ArrowRight from "components/icons/ArrowRight";
import {
  IDropdownGroup,
  IDropdownOption,
} from "components/ui/Dropdown/components/types";
import { FormikErrors, FormikTouched } from "formik";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import searchServiceFactory from "services/SearchService";

import { RevealOption } from "../../../hooks/useMappingFields";
import { FieldRow, MappedRow } from "../../ExportCrmDialogManager";
import FieldSelector from "./FieldSelector";

const NUMBER_OF_MATCHING_VALUES_TO_DISPLAY = 10;

type Props = {
  fieldIndex?: number;
  integrationId: number;
  errors?: FormikErrors<FieldRow>;
  touched?: FormikTouched<FieldRow>;
  onChange?: (event: {
    target: {
      name: string;
      value: string | boolean | MappedRow[];
    };
    type: string;
  }) => void;
};

const OwnerRow = ({
  fieldIndex,
  integrationId,
  errors = {},
  touched = {},
  onChange = () => {},
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [valueDisplay, setValueDisplay] = useState<string>("");
  const [loadedOptions, setLoadedOptions] = useState<RevealOption[]>([]);

  const loadOptions = useCallback(
    (query) => {
      setIsLoading(true);
      searchServiceFactory("crm-users")
        .by_integration(integrationId)
        .autocomplete(query)
        .perform({
          "page[size]": NUMBER_OF_MATCHING_VALUES_TO_DISPLAY,
        })
        .then((response) => {
          setLoadedOptions(
            response?.records?.map((record: Record) => ({
              value: record.providerKey,
              label: record.fullName,
            })) ?? []
          );
          setIsLoading(false);
        });
    },
    [integrationId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSearch = _.throttle(loadOptions, 400);

  useEffect(() => loadOptions(""), []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectUser = (fieldName: string, value: any) => {
    if (value !== valueDisplay) {
      setValueDisplay(value);
      const user = loadedOptions.find((s) => s.label === value);
      if (user && user.value) {
        onChange({
          target: {
            name: `fields[${fieldIndex}].reveal_field`,
            value: user.value,
          },
          type: "change",
        });
      }
    }
  };

  const userGroup: IDropdownOption = {
    id: _.uniqueId(`reveal_${fieldIndex}`),
    group: loadedOptions.map(
      (option) =>
        ({
          id: option.label.toString(),
          name: option.label.toString(),
        } as IDropdownGroup)
    ),
    name: "",
  };

  return (
    <Grid item container direction="column" rowGap={3}>
      <Grid item container columnSpacing={1}>
        <Grid item xs key={`left-${fieldIndex}`}>
          <FieldSelector
            name={`reveal_field_${fieldIndex}`}
            error={Boolean(touched.reveal_field && errors.reveal_field)}
            placeholder={intl.formatMessage(i18n.selectUser)}
            options={[userGroup]}
            showSearchBar={true}
            value={valueDisplay}
            onChange={selectUser}
            customLabel={valueDisplay}
            classes={{ btn: classes.leftField }}
            isLoading={isLoading}
            hasAsyncSearch
            onAsyncSearch={throttledSearch}
          />
        </Grid>
        <Grid item xs={"auto"} key={`middle-${fieldIndex}`}>
          <ArrowRight className={classes.arrowRight} />
        </Grid>
        <Grid item xs key={`right-${fieldIndex}`}>
          <FieldSelector
            name={`crm_field_${fieldIndex}`}
            value={intl.formatMessage(i18n.owner)}
            customLabel={intl.formatMessage(i18n.owner)}
            readOnly={true}
            classes={{ btn: classes.rightField }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OwnerRow;

/// CSS

const useStyles = makeStyles()((theme) => ({
  arrowRight: {
    marginTop: "50%",
    color: theme.palette.midnight,
    width: 16,
    height: 16,
  },
  leftField: {
    maxWidth: "280px",
  },
  rightField: {
    maxWidth: "280px",
  },
}));

/// I18N

const i18n = defineMessages({
  owner: {
    id: "ExportOptions.OwnerRow.owner",
    defaultMessage: "Owner",
  },
  selectUser: {
    id: "ExportOptions.OwnerRow.selectUser",
    defaultMessage: "Select user",
  },
  selectCrmField: {
    id: "ExportOptions.OwnerRow.selectCrmField",
    defaultMessage: "Select CRM field",
  },
});
