import AccountMappingSettings from "./AccountMappingSettings";
import AccountMappingSharingSettings from "./AccountMappingSharingSettings";
import AccountMappingView from "./AccountMappingView";
import CompanyPayingFeatureSubscription from "./CompanyPayingFeatureSubscription";
import CrmCredential from "./CrmCredential";
import CrmField from "./CrmField";
import DiscussionParticipant from "./DiscussionParticipant";
import FileSource from "./FileSource";
import Integration from "./Integration";
import IntroductionRequestForm from "./IntroductionRequestForm";
import InvitationURL from "./InvitationURL";
import Match from "./Match";
import Message from "./Message";
import MyEcosystemView from "./MyEcosystemView";
import Notification from "./Notification";
import PageView from "./PageView";
import PartnerConnection from "./PartnerConnection";
import Partnership from "./Partnership";
import PartnershipAllowedAccountsFilter from "./PartnershipAllowedAccountsFilter";
import PartnershipAnalyticsQuickFiltersMetrics from "./PartnershipAnalyticsQuickFiltersMetrics";
import PartnershipOverviewView from "./PartnershipOverviewView";
import PotentialPartnerOverlap from "./PotentialPartnerOverlap";
import RawOpportunity from "./RawOpportunity";
import Record from "./Record";
import SavedFilter from "./SavedFilter";
import SourceConfiguration from "./SourceConfiguration";
import SuggestedPartner from "./SuggestedPartner";
import SummaryMetrics from "./SummaryMetrics";
import {
  isIntegration_JSONAPIResource,
  isPartnership_JSONAPIResource,
  JSONAPIResource,
  JSONAPIResourceOrRecord,
  SuggestedPartner_JSONAPIResource,
} from "./types";
import User from "./User";

/**
 * Overrides to make sure to propagate type properly in the app
 */
function createRecord(
  rawData: JSONAPIResourceOrRecord<"account_mapping_settings">
): AccountMappingSettings;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"account_mapping_sharing_settings">
): AccountMappingSharingSettings;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"account_mapping_views">
): AccountMappingView;
function createRecord(rawData: JSONAPIResourceOrRecord<"page_views">): PageView;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"allowed_account_filters">
): PartnershipAllowedAccountsFilter;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"crm_credentials">
): CrmCredential;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"company_paying_feature_subscriptions">
): CompanyPayingFeatureSubscription;
function createRecord(rawData: JSONAPIResourceOrRecord<"crm_fields">): CrmField;
function createRecord(rawData: JSONAPIResourceOrRecord<"matches">): Match;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"my_ecosystem_views">
): MyEcosystemView;
function createRecord(rawData: JSONAPIResourceOrRecord<"messages">): Message;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"introduction_request_forms">
): IntroductionRequestForm;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"invitation_urls">
): InvitationURL;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"notifications">
): Notification;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"partner_connections">
): PartnerConnection;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"partnership_overview_views">
): PartnershipOverviewView;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"saved_filters">
): SavedFilter;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"potential_partner_overlaps">
): PotentialPartnerOverlap;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"raw_opportunities">
): RawOpportunity;
function createRecord(
  rawData: JSONAPIResourceOrRecord<"suggested_partners">
): SuggestedPartner;

function createRecord(
  rawData: JSONAPIResourceOrRecord<"summary_metrics">
): SummaryMetrics;
function createRecord(rawData: JSONAPIResourceOrRecord<"users">): User;
function createRecord(rawData: JSONAPIResource | Record): Record;
/**
 * Create record from raw data or pre-existing record
 * @param rawData data from API or existing record
 * @returns properly typed record
 */
function createRecord(rawData: JSONAPIResource | Record): Record {
  switch (rawData.type) {
    case "account_mapping_settings":
      return new AccountMappingSettings(
        rawData as JSONAPIResourceOrRecord<"account_mapping_settings">
      );
    case "account_mapping_sharing_settings":
      return new AccountMappingSharingSettings(
        rawData as JSONAPIResourceOrRecord<"account_mapping_sharing_settings">
      );
    case "account_mapping_views":
      return new AccountMappingView(
        rawData as JSONAPIResourceOrRecord<"account_mapping_views">
      );
    case "allowed_accounts_filters":
      return new PartnershipAllowedAccountsFilter(
        rawData as JSONAPIResourceOrRecord<"allowed_accounts_filters">
      );
    case "company_paying_feature_subscriptions":
      return new CompanyPayingFeatureSubscription(
        rawData as JSONAPIResourceOrRecord<"company_paying_feature_subscriptions">
      );
    case "crm_credentials":
      return new CrmCredential(
        rawData as JSONAPIResourceOrRecord<"crm_credentials">
      );
    case "crm_fields":
      return new CrmField(rawData as JSONAPIResourceOrRecord<"crm_fields">);
    case "integrations":
      /** If resource matches only, otherwise return default Record type */
      if (isIntegration_JSONAPIResource(rawData)) {
        return new Integration(rawData);
      }
      return new Record(rawData);
    case "introduction_request_forms":
      return new IntroductionRequestForm(
        rawData as JSONAPIResourceOrRecord<"introduction_request_forms">
      );
    case "invitation_urls":
      return new InvitationURL(
        rawData as JSONAPIResourceOrRecord<"invitation_urls">
      );
    case "matches":
      return new Match(rawData as JSONAPIResourceOrRecord<"matches">);
    case "my_ecosystem_views":
      return new MyEcosystemView(
        rawData as JSONAPIResourceOrRecord<"my_ecosystem_views">
      );
    case "messages":
      return new Message(rawData as JSONAPIResourceOrRecord<"messages">);
    case "page_views":
      return new PageView(rawData as JSONAPIResourceOrRecord<"page_views">);
    case "partner_connections":
      return new PartnerConnection(
        rawData as JSONAPIResource<"partner_connections">
      );
    case "partnerships":
      /** If resource matches only, otherwise return default Record type */
      if (isPartnership_JSONAPIResource(rawData)) {
        return new Partnership(rawData);
      }
      return new Record(rawData);
    case "partnership_analytics_quick_filters_metrics":
      return new PartnershipAnalyticsQuickFiltersMetrics(
        rawData as JSONAPIResourceOrRecord<"partnership_analytics_quick_filters_metrics">
      );
    case "partnership_overview_views":
      return new PartnershipOverviewView(
        rawData as JSONAPIResourceOrRecord<"partnership_overview_views">
      );
    case "potential_partner_overlaps":
      return new PotentialPartnerOverlap(
        rawData as JSONAPIResourceOrRecord<"potential_partner_overlaps">
      );
    case "saved_filters":
      return new SavedFilter(
        rawData as JSONAPIResourceOrRecord<"saved_filters">
      );
    case "sources":
      return new FileSource(rawData);
    case "source_configurations":
      return new SourceConfiguration(rawData);
    case "suggested_partners":
      return new SuggestedPartner(rawData as SuggestedPartner_JSONAPIResource);
    case "summary_metrics":
      return new SummaryMetrics(
        rawData as JSONAPIResourceOrRecord<"summary_metrics">
      );
    case "users":
      return new User(rawData as JSONAPIResourceOrRecord<"users">);
    case "raw_opportunities":
      return new RawOpportunity(
        rawData as JSONAPIResource<"raw_opportunities">
      );
    case "discussion_participants":
      return new DiscussionParticipant(
        rawData as JSONAPIResource<"discussion_participants">
      );
    case "notifications":
      return new Notification(rawData as JSONAPIResource<"notifications">);
    default:
      return new Record(rawData);
  }
}

export const Factory = {
  createRecord,
};
