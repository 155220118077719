import { AxiosResponse } from "axios";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import fileSaver from "file-saver";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { Factory } from "models";
import Record from "models/Record";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages } from "react-intl";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";

import { Parameters } from "../components/ActionBtn/components/ExportOptions";
import { ReportType, XlsxReportStatus } from "../utils";

const REPORT_TYPE_NAME = {
  [ReportType.ACCOUNT_MAPPING]: "Account mapping",
  [ReportType.MY_ACCOUNTS]: "My accounts",
  [ReportType.NEW_LEADS]: "New leads",
  [ReportType.COLLABORATE]: "Collaborate",
  [ReportType.NEW_PROSPECTS_360_XLSX]: "Nearbound prospects",
  [ReportType.NEARBOUND_ACCOUNTS_XLSX]: "Nearbound accounts",
};

export const useSharedExport = (
  reportType: ReportType,
  parameters: Parameters,
  downloadableCallback?: () => void
) => {
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const [pollCount, setPollCount] = useState(0);
  const [report, setReport] = useState<Record | null>(null);
  const pushNotification = usePushNotification();
  const isNearboundXlsxReport =
    reportType === ReportType.NEW_PROSPECTS_360_XLSX;
  const isNearboundAccountsXlsxReport =
    reportType === ReportType.NEARBOUND_ACCOUNTS_XLSX;
  const isCollaborateExport = reportType === ReportType.COLLABORATE;
  const isXlsxReport =
    isNearboundXlsxReport ||
    isCollaborateExport ||
    isNearboundAccountsXlsxReport;
  const [errorDownloading, setErrorDownloading] = useState(false);
  const [ready, setReady] = useState(false);

  const service = useMemo(
    () =>
      new JSONAPIService(
        isNearboundXlsxReport
          ? "nearbound_prospect_reports"
          : isNearboundAccountsXlsxReport
          ? "nearbound_account_reports"
          : isCollaborateExport
          ? "collaborate_reports"
          : "reports"
      ),
    [isCollaborateExport, isNearboundXlsxReport, isNearboundAccountsXlsxReport]
  );

  const isDownloadable =
    report &&
    (isXlsxReport
      ? report.status === XlsxReportStatus.READY
      : !!report.generatedAt);
  const isGenerating =
    report &&
    (isXlsxReport
      ? report.status !== XlsxReportStatus.READY &&
        report.status !== XlsxReportStatus.FAILED
      : report.generatedAt === null);

  const baseDeps = {
    company_id: profile.company.id,
    report_type: reportType,
  };

  const deps = JSON.stringify(
    isNearboundXlsxReport || isNearboundAccountsXlsxReport
      ? {
          columns: parameters.columns,
          filters: parameters.filters,
          sorts: parameters.sort,
          report_name: `Reveal Nearbound ${
            isNearboundAccountsXlsxReport ? "Account" : "Prospect"
          } Report - ${moment().utc().format("YYYY-MM-DD")}`,
        }
      : isCollaborateExport
      ? {
          filters: parameters.filters,
          report_name: `Reveal Collaborate Report - ${moment()
            .utc()
            .format("YYYY-MM-DD")}`,
        }
      : { ...baseDeps, parameters }
  );

  const generateReport = useCallback(async () => {
    setPollCount(0);
    track(`${REPORT_TYPE_NAME[reportType]} generation`);
    pushNotification({
      status: NotificationStatus.loading,
      message: isNearboundXlsxReport
        ? i18n.generatingNearboundXlsxReport
        : isCollaborateExport
        ? i18n.generatingCollaborateXlsxReport
        : i18n.generatingCsv,
    });
    const response = await service.create(
      {
        ...JSON.parse(deps),
      },
      {}
    );
    setReport(Factory.createRecord(response.data.data));
  }, [
    deps,
    isCollaborateExport,
    isNearboundXlsxReport,
    pushNotification,
    reportType,
    service,
    track,
  ]);

  const verifyState = useCallback(
    async (callback) => {
      const response = await service.rawPost("", "last/", {
        data: {
          type: isNearboundXlsxReport
            ? "nearbound_prospect_reports"
            : isNearboundAccountsXlsxReport
            ? "nearbound_account_reports"
            : isCollaborateExport
            ? "collaborate_reports"
            : "reports",
          attributes: {
            ...JSON.parse(deps),
          },
        },
      });
      callback(response);
    },
    [
      deps,
      isCollaborateExport,
      isNearboundXlsxReport,
      service,
      isNearboundAccountsXlsxReport,
    ]
  );

  const downloadReport = (report: Record) => {
    track(`${REPORT_TYPE_NAME[reportType]} download`);
    const options = service.authenticate({
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "multipart/form-data",
      },
    });
    options.responseType = "blob";
    service.axios
      .get(`${report.id}/download${isXlsxReport ? "/xlsx/" : "/"}`, options)
      .then(({ data }: $TSFixMe) =>
        fileSaver(
          data,
          isXlsxReport ? report.reportName : report.reportFileName
        )
      )
      .catch(() => {
        setErrorDownloading(true);
      });
  };

  const handleClick =
    report && isDownloadable
      ? async () => downloadReport(report)
      : generateReport;

  useEffect(() => {
    let isCancelled = false;
    verifyState((response: $TSFixMe) => {
      if (!isCancelled) {
        if (response.data.data) {
          setReport(Factory.createRecord(response.data.data));
        } else {
          setReport(null);
        }
        setReady(true);
      }
    });
    return () => {
      isCancelled = true;
    };
  }, [verifyState]);

  // Check if the report is ready every seconds and then download it
  useEffect(() => {
    let isCancelled = false;
    if (isGenerating) {
      const timeout = setTimeout(
        () =>
          verifyState((response: AxiosResponse<JSONAPIResponse, any>) => {
            if (!isCancelled) {
              if (response.data.data) {
                const newReport = Factory.createRecord(response.data.data);
                setReport(newReport);
                if (
                  isXlsxReport
                    ? newReport.status === XlsxReportStatus.READY
                    : newReport.generatedAt
                ) {
                  downloadableCallback?.();
                  downloadReport(newReport);
                  if (!errorDownloading) {
                    pushNotification(
                      {
                        status: NotificationStatus.success,
                        message: isXlsxReport
                          ? i18n.exportedXlsx
                          : i18n.exportedCsv,
                      },
                      undefined,
                      undefined
                    );
                  } else {
                    pushNotification("default_error");
                  }
                } else {
                  setPollCount(pollCount + 1);
                  // If report is still generating after 15s (poll is incremented every second)
                  // We display a new notification with more details
                  if (pollCount === 15) {
                    downloadableCallback?.();
                    pushNotification(
                      {
                        status: NotificationStatus.loading,
                        message: isNearboundXlsxReport
                          ? i18n.generatingNearboundXlsxReport
                          : isNearboundAccountsXlsxReport
                          ? i18n.generatingNearboundAccountsXlsxReport
                          : isCollaborateExport
                          ? i18n.generatingCollaborateXlsxReport
                          : i18n.generatingCsv,
                        description: isNearboundXlsxReport
                          ? i18n.generatingNearboundXlsxReportDescription
                          : isNearboundAccountsXlsxReport
                          ? i18n.generatingNearboundAccountsXlsxReportDescription
                          : isCollaborateExport
                          ? i18n.generatingCollaborateXlsxReportDescription
                          : i18n.generatingCsvDescription,
                      },
                      undefined,
                      undefined
                    );
                  }
                }
              }
            }
          }),
        1000
      );
      return () => {
        clearTimeout(timeout);
        isCancelled = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenerating, pollCount]);

  return {
    handleClick,
    isGenerating,
    ready,
  };
};

const i18n = defineMessages({
  exportedCsv: {
    id: "crm.DataTables.useSharedExport.exportedCsv",
    defaultMessage: "Successfully exported to CSV",
  },
  exportedXlsx: {
    id: "crm.DataTables.useSharedExport.exportedXlsx",
    defaultMessage: "Successfully exported to XLSX",
  },
  generatingCsv: {
    id: "crm.DataTables.useSharedExport.generatingCsv",
    defaultMessage: "Generating CSV export",
  },
  generatingCsvDescription: {
    id: "crm.DataTables.useSharedExport.generatingCsvDescription",
    defaultMessage:
      "Your CSV export is in progress. You will receive an email when the file is ready.",
  },
  generatingCollaborateXlsxReport: {
    id: "crm.DataTables.useSharedExport.generatingCollaborateXlsxReport",
    defaultMessage: "Generating Collaborate .xlsx export",
  },
  generatingCollaborateXlsxReportDescription: {
    id:
      "crm.DataTables.useSharedExport.generatingCollaborateXlsxReportDescription",
    defaultMessage:
      "Your Collaborate .xlsx export is in progress. You will receive an email when the file is ready.",
  },
  generatingNearboundXlsxReport: {
    id: "crm.DataTables.useSharedExport.generatingNearboundXlsxReport",
    defaultMessage: "Generating Nearbound prospects .xlsx export",
  },
  generatingNearboundXlsxReportDescription: {
    id:
      "crm.DataTables.useSharedExport.generatingNearboundXlsxReportDescription",
    defaultMessage:
      "Your Nearbound prospects .xlsx export is in progress. You will receive an email when the file is ready.",
  },
  generatingNearboundAccountsXlsxReport: {
    id: "crm.DataTables.useSharedExport.generatingNearboundAccountsXlsxReport",
    defaultMessage: "Generating Nearbound accounts .xlsx export",
  },
  generatingNearboundAccountsXlsxReportDescription: {
    id:
      "crm.DataTables.useSharedExport.generatingNearboundAccountsXlsxReportDescription",
    defaultMessage:
      "Your Nearbound accounts .xlsx export is in progress. You will receive an email when the file is ready.",
  },
});

export const _private = { i18n };
