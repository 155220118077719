import { CircularProgress } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import Popup from "components/ui/Popup";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost } from "redux/api/thunks";
import { ViewType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { AccountReferralEvent } from "tracking";

import ConfirmationContent from "./ConfirmationContent";
import ConfirmationTitle from "./ConfirmationTitle";
import ReferContent from "./ReferContent";
import ReferTitle from "./ReferTitle";

export enum ReferType {
  MULTIPLE_TO_MULTIPLE = "MULTIPLE_TO_MULTIPLE",
  MULTIPLE_TO_ONE = "MULTIPLE_TO_ONE",
  ONE_TO_MULTIPLE = "ONE_TO_MULTIPLE",
  ONE_TO_ONE = "ONE_TO_ONE",
}

export type ReferFunctionProps = {
  selectedPartners?: Partnership[];
  comment: string;
};

export interface RecordsToRefer extends Record {
  type: "matches" | "crm_accounts" | "nearbound_accounts"; // matches for Account Mapping & crm_accounts/nearbound_accounts for Mapping 360
}

type Props = {
  // For Account Mapping
  partnership?: Partnership | null;
  // For Account Mapping and Mapping 360
  isOpen: boolean;
  handleClose: () => void;
  recordsToRefer: RecordsToRefer[];
  sourceType: ViewType;
};

const ReferDialog = ({
  isOpen,
  handleClose,
  recordsToRefer,
  partnership,
  sourceType,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(
    false
  );

  const partnershipId = partnership?.id;
  const isDemo = (recordsToRefer?.[0]?.partnershipId as any) === "demo";
  const partner = partnership?.getPartner(profile);
  const recordsToReferCount = recordsToRefer.length;
  const severalAccounts = recordsToReferCount > 1;
  const severalPartners = !Boolean(partner); // 360 Mapping does not include partner property

  const [selectedPartners, setSelectedPartners] = useState<Partnership[]>([]);
  const [optionalMessage, setOptionalMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // From 360 Mapping
  const referAccountsToPartners = async (
    selectedPartners: Partnership[],
    comment: string
  ) => {
    const result = await dispatch(
      rawPost({
        type: "referred_accounts",
        path: "bulk-create-referred-accounts/",
        payload: {
          data: {
            crm_accounts: recordsToRefer.map((record) => {
              return {
                type: "crm_accounts",
                id:
                  record.type === "nearbound_accounts"
                    ? record.rawCompanyId
                    : record.id,
              };
            }),
            partnerships: selectedPartners.map((partnership) => {
              return { type: "partnerships", id: partnership.id };
            }),
            comment,
          },
        },
      })
    );
    if (!isFulfilled(result)) {
      pushNotification("default_error");
    } else {
      trackReferredAccounts(
        selectedPartners,
        recordsToRefer.length,
        Boolean(comment)
      );
    }
  };

  // From Account Mapping
  const referAccountsToPartner = async (comment: string) => {
    if (partnership) {
      const result = await dispatch(
        rawPost({
          type: "referred_accounts",
          path: "bulk-create-referred-accounts-from-matches/",
          payload: {
            data: {
              matches: recordsToRefer.map((record) => {
                return { type: "matches", id: record.id };
              }),
              partnership: { type: "partnerships", id: partnershipId },
              comment,
            },
          },
        })
      );
      if (!isFulfilled(result)) {
        pushNotification("default_error");
      } else {
        trackReferredAccounts(
          [partnership],
          recordsToRefer.length,
          Boolean(comment)
        );
      }
    }
  };

  const trackReferredAccounts = (
    partnerships: Partnership[],
    numberOfReferrals: number,
    withComment: boolean
  ) => {
    partnerships.forEach((partnership) => {
      const destPartner = partnership.getPartner(profile);
      track(AccountReferralEvent.referredAccountsToPipeline, {
        from: sourceType,
        referringCompanyId: profile.company.id,
        refereeCompanyId: destPartner.id,
        partnershipId: partnership.id,
        numberOfReferrals,
        withComment,
      });
    });
  };

  const referAccountsFunction = ({
    selectedPartners = [],
    comment,
  }: ReferFunctionProps) => {
    if (!Boolean(partner)) {
      return referAccountsToPartners(selectedPartners, comment);
    }
    return referAccountsToPartner(comment);
  };

  const referType =
    severalAccounts && severalPartners
      ? ReferType.MULTIPLE_TO_MULTIPLE
      : severalAccounts
      ? ReferType.MULTIPLE_TO_ONE
      : severalPartners && !isDemo
      ? ReferType.ONE_TO_MULTIPLE
      : ReferType.ONE_TO_ONE;

  const onClose = () => {
    setShowConfirmationDialog(false);
    handleClose();
    setSelectedPartners([]);
    setOptionalMessage("");
  };

  const onSave = async () => {
    if (isDemo) {
      pushNotification({ ...i18n.notOnDemo });
      return;
    }
    let referAccountsArgs = severalPartners
      ? { selectedPartners, comment: optionalMessage }
      : { comment: optionalMessage };
    setIsSubmitting(true);
    await referAccountsFunction(referAccountsArgs);
    setIsSubmitting(false);
    setShowConfirmationDialog(true);
  };

  return (
    <Popup
      isOpen={isOpen}
      center
      title={
        !showConfirmationDialog ? (
          <ReferTitle {...{ recordsToRefer, partner, isDemo }} />
        ) : (
          <ConfirmationTitle
            {...{ recordsToRefer, partner, selectedPartners }}
          />
        )
      }
      handleClose={onClose}
      handleSave={onSave}
      hideActions={showConfirmationDialog}
      maxWidth="sm"
      saveButtonText={
        <FormattedMessage
          {...i18n.saveButton}
          values={{ count: recordsToReferCount }}
        />
      }
      disableSave={
        (severalPartners && selectedPartners.length === 0) || isSubmitting
      }
      variant="secondary"
    >
      {isSubmitting ? (
        <div className={classes.centerElement}>
          <CircularProgress color="primary" size={40} />
        </div>
      ) : !showConfirmationDialog ? (
        <ReferContent
          {...{
            referType,
            selectedPartners,
            setSelectedPartners,
            isSubmitting,
            optionalMessage,
            setOptionalMessage,
          }}
        />
      ) : (
        <ConfirmationContent
          {...{ recordsToReferCount, partner, selectedPartners, onClose }}
        />
      )}
    </Popup>
  );
};

export default ReferDialog;

// CSS

const useStyles = makeStyles()(() => ({
  centerElement: {
    textAlign: "center",
  },
}));

// I18N

const i18n = defineMessages({
  saveButton: {
    id: "DataTables.ActionBtn.ReferOption.ReferDialog.saveButton",
    defaultMessage: "Refer {count, plural, one {account} other {# accounts}}",
  },
  notOnDemo: {
    id: "DataTables.ActionBtn.ReferOption.ReferDialog.notOnDemo",
    defaultMessage: "Not available on demo",
  },
});
