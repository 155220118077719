import { QuickViewFilter } from "components/ui/BaseQuickViews";
import _ from "lodash";
import Match from "models/Match";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  defaultAccountMappingSort,
  defaultLeadMatchesSort,
} from "redux/accountMapping/defaults";
import { AccountMappingResource } from "redux/accountMapping/types";
import {
  FilterType,
  MatchFilterType,
  Preset,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { OverlapViewName } from "./types";

const i18n = defineMessages({
  allOverlaps: {
    id: "crm.AccountMapping.QuickFilters.allOverlaps",
    defaultMessage: "All Overlaps",
  },
  commonCustomers: {
    id: "crm.AccountMapping.QuickFilters.commonCustomers",
    defaultMessage: "Common customers",
  },
  commonOpportunities: {
    id: "crm.AccountMapping.QuickFilters.commonOpportunities",
    defaultMessage: "Common opportunities",
  },
  openOpportunitiesToCustomers: {
    id: "crm.AccountMapping.QuickFilters.openOpportunitiesToCustomers",
    defaultMessage: "My Opportunities vs Partner's customers",
  },
  prospectMatchingCustomers: {
    id: "crm.AccountMapping.QuickFilters.prospectMatchingCustomers",
    defaultMessage: "My Prospects vs Customers",
  },
  whitespacePartnerShares: {
    id: "crm.AccountMapping.QuickFilters.whitespacePartnerShares",
    defaultMessage: "Partner's Customers not in my CRM",
  },
  customerMatchingProspects: {
    id: "crm.AccountMapping.QuickFilters.customerMatchingProspects",
    defaultMessage: "My Customers vs Prospects",
  },
  allNewProspects: {
    id: "crm.AccountMapping.QuickFilters.allNewProspects",
    defaultMessage: "All New Prospects",
  },
  whitespaceIShare: {
    id: "crm.AccountMapping.QuickFilters.whitespaceIShare",
    defaultMessage: "My Customers not in Partner's CRM",
  },
  allLeads: {
    id: "crm.AccountMapping.QuickFilters.allLeads",
    defaultMessage: "All Leads",
  },
  leadsMatchingCustomers: {
    id: "crm.AccountMapping.QuickFilters.leadsMatchingCustomers",
    defaultMessage: "Leads vs Customers",
  },
  leadsMatchingProspects: {
    id: "crm.AccountMapping.QuickFilters.leadsMatchingProspects",
    defaultMessage: "Leads vs Prospects",
  },
});

export const quickFilterPresets = {
  commonCustomers: {
    filter: [
      {
        fieldname: "leftStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_CUSTOMER)],
      },
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_CUSTOMER)],
      },
    ],
    sort: defaultAccountMappingSort,
  },
  commonOpportunities: {
    filter: [
      {
        fieldname: "leftStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_PROSPECT)],
      },
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_PROSPECT)],
      },
      { fieldname: "leftActive", type: MatchFilterType.IS, value: true },
      { fieldname: "rightActive", type: MatchFilterType.IS, value: true },
    ],
    sort: defaultAccountMappingSort,
  },
  prospectMatchingCustomers: {
    filter: [
      {
        fieldname: "leftStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_PROSPECT)],
      },
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_CUSTOMER)],
      },
    ],
    sort: defaultAccountMappingSort,
  },
  customerMatchingProspects: {
    filter: [
      {
        fieldname: "leftStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_CUSTOMER)],
      },
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_PROSPECT)],
      },
    ],
    sort: defaultAccountMappingSort,
  },
  leadsMatchingCustomers: {
    filter: [
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_CUSTOMER)],
      },
    ],
    sort: defaultLeadMatchesSort,
  },
  leadsMatchingProspects: {
    filter: [
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_PROSPECT)],
      },
    ],
    sort: defaultLeadMatchesSort,
  },
  whitespaceIShare: {
    filter: [
      {
        fieldname: "perspective",
        type: MatchFilterType.IS,
        value: Match.PERSPECTIVE_MINE,
      },
    ],
    sort: defaultAccountMappingSort,
  },
  whitespaceIShareDemo: {
    filter: [
      {
        fieldname: "leftName",
        type: MatchFilterType.CONTAINS,
        value: "",
      },
    ],
    sort: defaultAccountMappingSort,
  },
  whitespacePartnerShares: {
    filter: [
      {
        fieldname: "perspective",
        type: MatchFilterType.IS,
        value: Match.PERSPECTIVE_THEIRS,
      },
    ],
    sort: defaultAccountMappingSort,
  },
  whitespacePartnerSharesDemo: {
    filter: [
      {
        fieldname: "rightName",
        type: MatchFilterType.CONTAINS,
        value: "",
      },
    ],
    sort: defaultAccountMappingSort,
  },
  openOpportunitiesToCustomers: {
    filter: [
      {
        fieldname: "leftStatus",
        type: MatchFilterType.NONE_OF,
        value: [String(Match.STATUS_CUSTOMER), String(Match.STATUS_PARTNER)],
      },
      {
        fieldname: "rightStatus",
        type: MatchFilterType.ANY_OF,
        value: [String(Match.STATUS_CUSTOMER)],
      },
      {
        fieldname: "leftActive",
        type: MatchFilterType.IS,
        value: true,
      },
    ],
    sort: defaultAccountMappingSort,
  },
} as { [key: string]: Preset };

export const overlapsSavedViews = Object.values(OverlapViewName).reduce(
  (result: { name: string; filter: FilterType[] }[], name: string) => {
    if (quickFilterPresets[name]) {
      result.push({
        name,
        filter: quickFilterPresets[name].filter,
      });
    }
    return result;
  },
  []
);

export const allOverlaps = {
  key: -1,
  presets: {
    filter: [],
    sort: defaultAccountMappingSort,
  },
  label: <FormattedMessage {...i18n.allOverlaps} />,
  name: i18n.allOverlaps,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.matches,
};
export const commonCustomers = {
  key: -2,
  presets: quickFilterPresets.commonCustomers,
  label: <FormattedMessage {...i18n.commonCustomers} />,
  name: i18n.commonCustomers,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.matches,
};
export const commonOpportunities = {
  key: -3,
  presets: quickFilterPresets.commonOpportunities,
  label: <FormattedMessage {...i18n.commonOpportunities} />,
  name: i18n.commonOpportunities,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.matches,
};
export const prospectMatchingCustomers = {
  key: -4,
  presets: quickFilterPresets.prospectMatchingCustomers,
  label: <FormattedMessage {...i18n.prospectMatchingCustomers} />,
  name: i18n.prospectMatchingCustomers,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.matches,
};
export const whitespacePartnerShares = {
  key: -5,
  presets: quickFilterPresets.whitespacePartnerShares,
  label: <FormattedMessage {...i18n.whitespacePartnerShares} />,
  name: i18n.whitespacePartnerShares,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.shared_accounts,
};
export const customerMatchingProspects = {
  key: -6,
  presets: quickFilterPresets.customerMatchingProspects,
  label: <FormattedMessage {...i18n.customerMatchingProspects} />,
  name: i18n.customerMatchingProspects,
  hideOnSmallScreens: true,
  viewType: AccountMappingResource.matches,
};
export const allNewProspects = {
  key: -7,
  presets: {
    filter: [],
    sort: defaultAccountMappingSort,
  },
  label: <FormattedMessage {...i18n.allNewProspects} />,
  name: i18n.allNewProspects,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.shared_accounts,
};
export const whitespaceIShare = {
  key: -8,
  presets: quickFilterPresets.whitespaceIShare,
  label: <FormattedMessage {...i18n.whitespaceIShare} />,
  name: i18n.whitespaceIShare,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.shared_accounts,
};
export const allLeads = {
  key: -9,
  presets: {
    filter: [],
    sort: defaultLeadMatchesSort,
  },
  label: <FormattedMessage {...i18n.allLeads} />,
  name: i18n.allLeads,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.leadMatches,
};
export const leadsMatchingCustomers = {
  key: -10,
  presets: quickFilterPresets.leadsMatchingCustomers,
  label: <FormattedMessage {...i18n.leadsMatchingCustomers} />,
  name: i18n.leadsMatchingCustomers,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.leadMatches,
};
export const leadsMatchingProspects = {
  key: -11,
  presets: quickFilterPresets.leadsMatchingProspects,
  label: <FormattedMessage {...i18n.leadsMatchingProspects} />,
  name: i18n.leadsMatchingProspects,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.leadMatches,
};
export const openOpportunitiesToCustomers = {
  key: -12,
  presets: quickFilterPresets.openOpportunitiesToCustomers,
  label: <FormattedMessage {...i18n.openOpportunitiesToCustomers} />,
  name: i18n.openOpportunitiesToCustomers,
  hideOnSmallScreens: false,
  viewType: AccountMappingResource.matches,
};

export const defaultAccountMappingFilters: QuickViewFilter[] = [
  allOverlaps,
  commonCustomers,
  commonOpportunities,
  prospectMatchingCustomers,
  whitespacePartnerShares,
  customerMatchingProspects,
  allNewProspects,
  whitespaceIShare,
  allLeads,
  leadsMatchingCustomers,
  leadsMatchingProspects,
  openOpportunitiesToCustomers,
];

export const onlyCommonCustomersAccountMappingFilters: QuickViewFilter[] = [
  commonCustomers,
  whitespacePartnerShares,
  allNewProspects,
  whitespaceIShare,
  allLeads,
  leadsMatchingCustomers,
  leadsMatchingProspects,
  openOpportunitiesToCustomers,
];

export const unPinnableFilters: QuickViewFilter[] = [
  allOverlaps,
  allNewProspects,
  allLeads,
];

// Whitespace Demo Filters
export const whitespaceIShareDemoFilter = _.cloneDeep(whitespaceIShare);
whitespaceIShareDemoFilter.presets = quickFilterPresets.whitespaceIShareDemo;
export const whitespacePartnerSharesDemoFilter = _.cloneDeep(
  whitespacePartnerShares
);
whitespacePartnerSharesDemoFilter.presets =
  quickFilterPresets.whitespacePartnerSharesDemo;

export const defaultAccountMappingFiltersDemo: QuickViewFilter[] = [
  allOverlaps,
  commonCustomers,
  commonOpportunities,
  prospectMatchingCustomers,
  whitespacePartnerSharesDemoFilter,
  customerMatchingProspects,
  allNewProspects,
  whitespaceIShareDemoFilter,
  allLeads,
  leadsMatchingCustomers,
  leadsMatchingProspects,
  openOpportunitiesToCustomers,
];

export const demoFilterOrderList: number[] = defaultAccountMappingFiltersDemo.map(
  (item) => Number(item.key)
);
