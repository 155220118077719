import AccountMappingView from "models/AccountMappingView";
import Partnership from "models/Partnership";

export enum AccountMappingResource {
  matches = "matches",
  leadMatches = "lead_matches",
  shared_accounts = "shared_accounts",
}

export enum AccountMappingActions {
  // Persisted View
  loadView = "accountMapping/loadView",
  updateView = "accountMapping/updateView",
  // Local View
  setAccountType = "accountMapping/setAccountType",
  setSelectedSourceId = "accountMapping/setSelectedSourceId",
  setLastVisitedAt = "accountMapping/setLastVisitedAt",
}

export interface AccountMappingViewState {
  persisted?: AccountMappingView;
  accountType: AccountMappingResource;
  selectedSourceId: number | null;
  lastVisitedAt: Date | null;
}

export interface PartnershipAccountMappingState {
  views: {
    [partnershipId: number]: AccountMappingViewState;
  };
  currentPartnership: Partnership | null;
}

export interface RootStateWithAccountMapping {
  accountMapping: PartnershipAccountMappingState;
}
