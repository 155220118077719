import { Skeleton } from "@mui/material";
import { ArrowRight, CircleError } from "components/icons";
import Box from "components/ui/Box";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useAmountFormatter from "hooks/useAmountFormatter";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import React, { ReactElement, useCallback } from "react";
import {
  defineMessages,
  FormatNumberOptions,
  FormattedMessage,
  FormattedNumber,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectCurrentPartnershipReady } from "redux/accountMapping/selector";
import { updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectFirst3PartnershipIds } from "redux/init/selectors";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  OverlapItem,
  OverlapItemType,
} from "screens/Frontoffice/screens/Partners/shared/components/OverlapItem";

import {
  getBaseFilters,
  hasAllRequiredCapabilities,
  requiredCapabilitiesForKPI,
} from "../utils";

type Props = {
  partnership: Partnership;
  value?: number;
  label: MessageDescriptor;
  error?: boolean;
  isUpsidesField?: boolean;
  loading?: boolean;

  // For Customer KPIs
  closedAmount?: number;
  closedAmountLoading?: boolean;

  // For Prospect KPIs
  pipeline?: number;
  pipelineLoading?: boolean;

  potentialRevenue?: number | null;
  potentialRevenueLoading?: boolean;

  // Filters
  kpi: string;
  sharedFilters?: FilterType[];

  // For Partner New Leads KPI
  clickable?: boolean;
  accountType?: AccountMappingResource;
};

export const OverviewData = ({
  isUpsidesField,
  kpi,
  label,
  value,
  partnership,
  pipeline,
  potentialRevenue,
  closedAmount,
  error = false,
  loading = false,
  closedAmountLoading = false,
  pipelineLoading = false,
  potentialRevenueLoading = false,
  sharedFilters,
  accountType = AccountMappingResource.matches,
  clickable = true,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const history = useHistory();
  const first3PartnershipIds = useSelector(selectFirst3PartnershipIds);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isPartnershipReady = useSelector(selectCurrentPartnershipReady);
  const hasAdvancedAnalyticsPayingFeature = payingFeatures.includes(
    PayingFeature.AdvancedAnalytics
  );
  const partnerName = partnership.getPartner(profile).name;

  const isLocked =
    isUpsidesField &&
    !hasAdvancedAnalyticsPayingFeature &&
    !first3PartnershipIds.includes(partnership.id) &&
    !partnership.isDemo;

  const onClick = useCallback(async () => {
    if (clickable) {
      if (!partnership.isDemo) {
        await dispatch(
          updateView({
            accountType: accountType,
            filters: [...getBaseFilters(kpi), ...(sharedFilters ?? [])],
            partnership,
          })
        );
      }
      history.push(
        `/partnerships/${partnership.id}/account-mapping?kpi=${kpi}`
      );
    }
  }, [
    dispatch,
    history,
    partnership,
    kpi,
    sharedFilters,
    clickable,
    accountType,
  ]);

  const hasCapabilities = hasAllRequiredCapabilities(
    partnership.capabilities,
    requiredCapabilitiesForKPI[kpi]
  );
  const hasInsightCapabilities = hasAllRequiredCapabilities(
    partnership.capabilities,
    _.concat(
      requiredCapabilitiesForKPI["insight"],
      requiredCapabilitiesForKPI[kpi] ?? []
    )
  );

  return (
    <Box
      id={kpi}
      variant={clickable ? "hover" : "default"}
      containerClassName={classes.container}
      onClick={onClick}
    >
      <div className={classes.root}>
        <div className={classes.left}>
          <div className={classes.kpi}>
            <KPI
              value={hasCapabilities ? value : undefined}
              loading={hasCapabilities && loading}
              error={error}
              tooltip={
                !isPartnershipReady ? (
                  <FormattedMessage {...i18n.notReady} />
                ) : hasCapabilities ? undefined : (
                  <FormattedMessage {...i18n.capabilityLimitation} />
                )
              }
              noDataDisplay={!isPartnershipReady}
            />
          </div>
          <div className={classes.label}>
            <T className={classes.labelText}>
              <FormattedMessage {...label} values={{ partnerName }} />
            </T>
            {hasInsightCapabilities ? (
              <>
                <Insight
                  type="closed"
                  loading={closedAmountLoading}
                  value={closedAmount}
                />
                <Insight
                  type="pipeline"
                  loading={pipelineLoading}
                  value={pipeline}
                />
                <Insight
                  type="potentialRevenue"
                  loading={potentialRevenueLoading}
                  value={potentialRevenue}
                  isLocked={isLocked}
                  partnership={partnership}
                />
              </>
            ) : null}
          </div>
          <div className={classes.iconAbsolute}>
            <ArrowRight />
          </div>
        </div>
      </div>
    </Box>
  );
};

// Sub Component

type KPIProps = Pick<Props, "error" | "loading" | "value"> & {
  tooltip?: React.ReactNode;
  noDataDisplay: boolean | undefined;
};

const KPI = ({ error, loading, value, tooltip, noDataDisplay }: KPIProps) => {
  let content: ReactElement;
  if (error) {
    content = <CircleError />;
  } else if (loading) {
    content = <Skeleton height={36} data-testid="loading-kpi" />;
  } else if (noDataDisplay) {
    content = <span>--</span>;
  } else if (value === undefined) {
    content = (
      <span>
        <FormattedMessage {...i18n.notAvailable} />
      </span>
    );
  } else {
    content = <FormattedNumber value={value} {...getFormattingProps(value)} />;
  }
  if (tooltip) {
    content = (
      <Tooltip title={tooltip} placement="left">
        {content}
      </Tooltip>
    );
  }
  return (
    <T h4 bold>
      {content}
    </T>
  );
};

type LockedInsightProps = {
  isLocked?: boolean;
  type: "potentialRevenue";
  loading: boolean;
  partnership: Partnership;
  value?: number | null;
};

type UnlockedInsightProps = {
  isLocked?: false;
  type: "pipeline" | "closed";
  loading: boolean;
  partnership?: Partnership;
  value?: number | null;
};

type InsightProps = LockedInsightProps | UnlockedInsightProps;

const Insight = ({
  isLocked,
  type,
  loading,
  partnership,
  value,
}: InsightProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);

  if (loading) {
    return (
      <Skeleton
        className={classes.subtitleSkeleton}
        data-testid={`loading-${type}-kpi`}
      />
    );
  }

  if (isLocked) {
    return (
      <OverlapItem
        value={null}
        partnership={partnership as Partnership}
        valueInProgress={false}
        type={OverlapItemType.revenuePotential}
        loading={loading}
        classes={{
          lockedOverlapItem: classes.lockedOverlapItem,
          overlapContainer: classes.overlapContainer,
        }}
      />
    );
  }
  if (value !== undefined) {
    return (
      <T className={classes.alphaText}>
        <FormattedMessage
          {...(type === "pipeline"
            ? i18n.pipelineSubtitle
            : type === "potentialRevenue"
            ? i18n.potentialRevenueSubtitle
            : i18n.closedAmountSubtitle)}
          values={{
            amount: amountFormatter(value ?? undefined, "USD"),
          }}
        />
      </T>
    );
  }
  return null;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  alphaText: {
    color: theme.palette.alpha750,
  },
  container: {
    padding: `${theme.spacing(2, 3)} !important`,
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: theme.spacing(0.5),
  },
  iconAbsolute: {
    "& svg": {
      height: 16,
      width: 16,
    },
    color: theme.palette.midnight,
    position: "absolute",
    right: 24,
    top: "50%",
    transform: "translate(0, -50%)",
  },
  lockedOverlapItem: {
    "& > svg": {
      color: theme.palette.alpha800,
      height: "12px !important",
      marginLeft: 4,
    },
    "& p": {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
      color: theme.palette.alpha750,
    },
    borderBottom: `1px dashed ${theme.palette.greyLight300}`,
    borderRadius: 4,
    width: "fit-content",
    padding: "3px 6px 3px 0px",
    marginLeft: 0,
    marginTop: 6,
  },
  overlapContainer: {
    "& > p": {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  left: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  kpi: {
    color: theme.palette.midnight,
    width: 100,
    minWidth: 100,
    textAlign: "center",
    marginRight: 16,
  },
  label: {
    paddingLeft: 16,
  },
  labelText: {
    color: theme.palette.midnight,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "21px",
  },
  subtitleSkeleton: {
    height: 18,
  },
}));

/// I18n

export const i18n = defineMessages({
  commonCustomers: {
    id: "Overview.OverviewData.commonCustomers",
    defaultMessage: "Common Customers",
  },
  commonOpenOpportunities: {
    id: "Overview.OverviewData.commonOpenOpportunities",
    defaultMessage: "Common Opportunities",
  },
  prospectToCustomers: {
    id: "Overview.OverviewData.prospectToCustomers",
    defaultMessage: "My prospects vs {partnerName}'s customers",
  },
  customerToProspects: {
    id: "Overview.OverviewData.customerToProspects",
    defaultMessage: "My customers vs {partnerName}'s prospects",
  },
  newProspects: {
    id: "Overview.OverviewData.newProspects",
    defaultMessage: "{partnerName}'s customers not in my CRM",
  },
  closedAmountSubtitle: {
    id: "Overview.OverviewData.closedAmountSubtitle",
    defaultMessage: "{amount} won in past 12 months",
  },
  openOpportunitiesToCustomers: {
    id: "Overview.OverviewData.openOpportunitiesToCustomers",
    defaultMessage: "My opportunities vs {partnerName}'s customers",
  },
  partnerNewProspects: {
    id: "Overview.OverviewData.partnerNewProspects",
    defaultMessage: "My Customers not in {partnerName}'s CRM",
  },
  pipelineSubtitle: {
    id: "Overview.OverviewData.pipelineSubtitle",
    defaultMessage: "{amount} Open Pipeline",
  },
  potentialRevenueSubtitle: {
    id: "Overview.OverviewData.potentialRevenueSubtitle",
    defaultMessage: "{amount} Potential Revenue",
  },
  notAvailable: {
    id: "Overview.OverviewData.notAvailable",
    defaultMessage: "N/A",
  },
  capabilityLimitation: {
    id: "Overview.OverviewData.capabilityLimitation",
    defaultMessage:
      "This figure is not available because your or your partner's data source does not provide this level of information.",
  },
  notReady: {
    id: "Overview.OverviewData.notReady",
    defaultMessage: "Your data is currently being computed",
  },
  unlock: {
    id: "Overview.OverviewData.unlock",
    defaultMessage: "Unlock",
  },
});

/// Utils

const getFormattingProps = (value?: number): Partial<FormatNumberOptions> => {
  if (value && value >= 1000000) {
    return {
      notation: "compact",
      compactDisplay: "short",
      maximumSignificantDigits: 2,
    };
  }
  return {};
};
