import { getViewTypeFromAccountMappingResource } from "models/AccountMappingView";
import CrmField from "models/CrmField";
import { asJSONSerializable } from "redux/typing";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import {
  asPersistedColumn,
  ColumnConfigType,
  MatchSortOrder,
  moveColumnAfter,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { AccountMappingResource } from "./types";

const defaultMatchesColumnKeys = [
  AccountMappingStandardFieldName.leftName,
  AccountMappingStandardFieldName.leftActions,
  AccountMappingStandardFieldName.leftStarred,
  AccountMappingStandardFieldName.leftStatus,
  AccountMappingStandardFieldName.leftActive,
  AccountMappingStandardFieldName.leftOwnerName,
  AccountMappingStandardFieldName.leftDomain,
  AccountMappingStandardFieldName.rightName,
  AccountMappingStandardFieldName.rightStatus,
  AccountMappingStandardFieldName.rightActive,
  AccountMappingStandardFieldName.rightOwnerName,
  AccountMappingStandardFieldName.rightDomain,
  AccountMappingStandardFieldName.rightKeyContactOverlap,
  AccountMappingStandardFieldName.rightExtraKeyContacts,
];

const defaultLeadMatchesColumnKeys = [
  AccountMappingStandardFieldName.leftName,
  AccountMappingStandardFieldName.leftActions,
  AccountMappingStandardFieldName.leftStarred,
  AccountMappingStandardFieldName.leftStatus,
  AccountMappingStandardFieldName.leftOwnerName,
  AccountMappingStandardFieldName.rightName,
  AccountMappingStandardFieldName.rightStatus,
  AccountMappingStandardFieldName.rightActive,
  AccountMappingStandardFieldName.rightOwnerName,
];

export const accountMappingColumnsConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AccountMappingStandardFieldName.leftName]: {
    key: AccountMappingStandardFieldName.leftName,
    width: 180,
    frozen: true,
    fixedPosition: 1,
    removable: false,
  },
  [AccountMappingStandardFieldName.leftActions]: {
    key: AccountMappingStandardFieldName.leftActions,
    width: 144,
    frozen: true,
    fixedPosition: 2,
    removable: false,
  },
  [AccountMappingStandardFieldName.leftStarred]: {
    key: AccountMappingStandardFieldName.leftStarred,
    width: 84,
    frozen: true,
    fixedPosition: 3,
    removable: false,
  },
  [AccountMappingStandardFieldName.leftStatus]: {
    key: AccountMappingStandardFieldName.leftStatus,
    width: 140,
    frozen: false,
  },
  [AccountMappingStandardFieldName.leftActive]: {
    key: AccountMappingStandardFieldName.leftActive,
    width: 134,
    frozen: false,
  },
  [AccountMappingStandardFieldName.leftOwnerName]: {
    key: AccountMappingStandardFieldName.leftOwnerName,
    width: 170,
    frozen: false,
  },
  [AccountMappingStandardFieldName.leftCountryCode]: {
    key: AccountMappingStandardFieldName.leftCountryCode,
    width: 130,
    frozen: false,
  },
  [AccountMappingStandardFieldName.leftDomain]: {
    key: AccountMappingStandardFieldName.leftDomain,
    width: 200,
    frozen: false,
  },
  [AccountMappingStandardFieldName.leftContactCount]: {
    key: AccountMappingStandardFieldName.leftContactCount,
    width: 101,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightName]: {
    key: AccountMappingStandardFieldName.rightName,
    width: 180,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightNewProspectActions]: {
    key: AccountMappingStandardFieldName.rightNewProspectActions,
    frozen: false,
    width: 108,
    removable: false,
  },
  [AccountMappingStandardFieldName.rightStatus]: {
    key: AccountMappingStandardFieldName.rightStatus,
    width: 140,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightActive]: {
    key: AccountMappingStandardFieldName.rightActive,
    width: 116,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightOwnerName]: {
    key: AccountMappingStandardFieldName.rightOwnerName,
    width: 170,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightCountryCode]: {
    key: AccountMappingStandardFieldName.rightCountryCode,
    width: 130,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightDomain]: {
    key: AccountMappingStandardFieldName.rightDomain,
    width: 200,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightContactCount]: {
    key: AccountMappingStandardFieldName.rightContactCount,
    width: 101,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightContactOverlap]: {
    key: AccountMappingStandardFieldName.rightContactOverlap,
    width: 104,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightExtraContacts]: {
    key: AccountMappingStandardFieldName.rightExtraContacts,
    width: 104,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightKeyContactOverlap]: {
    key: AccountMappingStandardFieldName.rightKeyContactOverlap,
    width: 118,
    frozen: false,
  },
  [AccountMappingStandardFieldName.rightExtraKeyContacts]: {
    key: AccountMappingStandardFieldName.rightExtraKeyContacts,
    width: 118,
    frozen: false,
  },
};

export const defaultAccountMappingColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 8 * Math.max(field.label.length, 10),
  frozen: false,
});

export const applyAccountMappingColumnOrder = (columns: ColumnConfigType[]) => {
  const leftColumns = columns.filter((column: ColumnConfigType) =>
    column.key.startsWith("left")
  );
  const rightColumns = columns.filter((column: ColumnConfigType) =>
    column.key.startsWith("right")
  );
  return [
    ...orderColumns(leftColumns),
    ...moveColumnAfter(
      orderColumns(rightColumns),
      "rightNewProspectActions",
      "rightName"
    ),
  ];
};

export const defaultAccountMappingColumns = applyAccountMappingColumnOrder(
  defaultMatchesColumnKeys.map((key) => accountMappingColumnsConfig[key])
);

export const defaultLeadMatchesColumns = applyAccountMappingColumnOrder(
  defaultLeadMatchesColumnKeys.map((key) => accountMappingColumnsConfig[key])
);

export const defaultAccountMappingSort = [
  {
    fieldname: "leftStarred",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_BOOLEAN,
  },
  {
    fieldname: "leftOpenOpportunitiesAmountInDollars",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_CURRENCY,
  },
];

export const defaultLeadMatchesSort = [
  {
    fieldname: "rightStatus",
    order: MatchSortOrder.ORDER_ASC,
    fieldtype: CrmField.TYPE_PICKLIST,
  },
  {
    fieldname: "rightActive",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_BOOLEAN,
  },
];

export const defaultAccountMappingViewData = {
  active_view_type: getViewTypeFromAccountMappingResource(
    AccountMappingResource.matches
  ),
  matches_columns: asJSONSerializable(
    defaultAccountMappingColumns.map(asPersistedColumn)
  ),
  matches_filters: [],
  matches_sort: defaultAccountMappingSort,
  shared_accounts_columns: asJSONSerializable(
    defaultAccountMappingColumns.map(asPersistedColumn)
  ),
  shared_accounts_filters: [],
  shared_accounts_sort: defaultAccountMappingSort,
  lead_matches_columns: asJSONSerializable(
    defaultLeadMatchesColumns.map(asPersistedColumn)
  ),
  lead_matches_filters: [],
  lead_matches_sort: defaultLeadMatchesSort,
};
