import muiTheme from "config/theme";
import _ from "lodash";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { AccountMappingResource } from "redux/accountMapping/types";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import { booleanSharingSettingsColumnsMap } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/utils";
import { i18n as analyticsTooltips } from "screens/Frontoffice/screens/DataTables/screens/Analytics/constants";

import { PartnerSignalsPopupContent } from "./popups/PartnerSignalsPopup";

const i18n = defineMessages({
  dealInfluencersTooltip: {
    id: "crm.360Mapping.config.dealInfluencersTooltip",
    defaultMessage:
      "Job titles of the contacts that were listed on the opportunity when won by your partner. They are a good indicator of the person to target.",
  },
  openDealTooltip: {
    id: "crm.AccountMapping.config.openDealTooltip",
    defaultMessage: "Has at least 1 open opportunity.",
  },
  openDealPrivateTooltip: {
    id: "crm.AccountMapping.config.openDealPrivateTooltip",
    defaultMessage: "You’re not sharing anything about your open deals.",
  },
  openDealPartiallySharedTooltip: {
    id: "crm.AccountMapping.config.openDealPartiallySharedTooltip",
    defaultMessage:
      "You're only sharing whether the account has an open deal or not.",
  },
  openDealSharedTooltip: {
    id: "crm.AccountMapping.config.openDealSharedTooltip",
    defaultMessage: "You’re currently sharing open deal data.",
  },
  ownerPartiallySharedTooltip: {
    id: "crm.AccountMapping.config.ownerPartiallySharedTooltip",
    defaultMessage:
      "You are not sharing the contact info with your partner, only you can see it. However, your partner can see the owners' full name.",
  },
  commonContactsTooltip: {
    id: "crm.AccountMapping.config.commonContactsTooltip",
    defaultMessage: "Contacts listed in both your CRM and your partner CRM.",
  },
  otherContactsTooltip: {
    id: "crm.AccountMapping.config.otherContactsTooltip",
    defaultMessage: `Contacts of your partner that don't exist in your CRM`,
  },
  KnownPrimaryContactsTooltip: {
    id: "crm.AccountMapping.config.KnownPrimaryContactsTooltip",
    defaultMessage:
      '"Known deal influencers" are contacts listed by your partners at the opportunity level, that are also in your CRM.',
  },
  otherPrimaryContactsTooltip: {
    id: "crm.AccountMapping.config.otherPrimaryContactsTooltip",
    defaultMessage:
      '"Other deal influencers" are contacts listed by your partners at the opportunity level, that aren\'t in your CRM.',
  },
  shared: {
    id: "dataGrid.fieldTooltip.shared",
    defaultMessage: "Shared",
  },
  sharedEditatable: {
    id: "dataGrid.fieldTooltip.sharedEditatable",
    defaultMessage: "Shared.",
  },
  alwaysShared: {
    id: "dataGrid.fieldTooltip.alwaysShared",
    defaultMessage: "Always shared",
  },
  private: {
    id: "dataGrid.fieldTooltip.private",
    defaultMessage: "Private",
  },
  editSettings: {
    id: "dataGrid.fieldTooltip.editSettings",
    defaultMessage: "Edit settings.",
  },
});

// Field description tooltips

export const infoFieldsTooltips = {
  [AccountMappingStandardFieldName.leftActive]: (
    <FormattedMessage {...i18n.openDealTooltip} />
  ),
  [AccountMappingStandardFieldName.rightActive]: (
    <FormattedMessage {...i18n.openDealTooltip} />
  ),
  [AccountMappingStandardFieldName.rightContactOverlap]: (
    <FormattedMessage {...i18n.commonContactsTooltip} />
  ),
  [AccountMappingStandardFieldName.rightExtraContacts]: (
    <FormattedMessage {...i18n.otherContactsTooltip} />
  ),
  [AccountMappingStandardFieldName.rightKeyContactOverlap]: (
    <FormattedMessage {...i18n.KnownPrimaryContactsTooltip} />
  ),
  [AccountMappingStandardFieldName.rightExtraKeyContacts]: (
    <FormattedMessage {...i18n.otherPrimaryContactsTooltip} />
  ),
  [Available360MappingColumns.active]: (
    <FormattedMessage {...i18n.openDealTooltip} />
  ),
  [Available360MappingColumns.dealInfluencerList]: (
    <FormattedMessage {...i18n.dealInfluencersTooltip} />
  ),
  [AvailableAnalytisColumns.winRate]: (
    <FormattedMessage {...analyticsTooltips.winRateHeaderTooltip} />
  ),
  [AvailableAnalytisColumns.dealSize]: (
    <FormattedMessage {...analyticsTooltips.dealSizeHeaderTooltip} />
  ),
  [AvailableAnalytisColumns.revenuePotential]: (
    <FormattedMessage {...analyticsTooltips.revenuePotentialHeaderTooltip} />
  ),
};

// Field description popup dialog

export const infoFieldsPopups = (onClose: () => void) => ({
  [Available360MappingColumns.partnerSignals]: (
    <PartnerSignalsPopupContent onClose={onClose} />
  ),
  [Available360MappingColumns.customerPresenceHighlightsCount]: (
    <PartnerSignalsPopupContent onClose={onClose} />
  ),
  [AvailableNearboundAccountsColumns.PartnerSignalsCount]: (
    <PartnerSignalsPopupContent onClose={onClose} />
  ),
});

// Field status tooltips

const availableSemiPrivacyFieldsTooltip = {
  leftActive: <FormattedMessage {...i18n.openDealPartiallySharedTooltip} />,
  leftOwnerName: <FormattedMessage {...i18n.ownerPartiallySharedTooltip} />,
};

const availablePrivateFieldsTooltip = {
  leftActive: <FormattedMessage {...i18n.openDealPrivateTooltip} />,
};

const availableSharedFieldsTooltip = {
  leftActive: <FormattedMessage {...i18n.openDealSharedTooltip} />,
};

export const getFieldStatusTooltip = (
  status: "shared" | "partiallyShared" | "private" | "hidden",
  fieldKey: string,
  isSmartField: boolean,
  partnershipId: number,
  accountType: AccountMappingResource
) => {
  // If true, the field can be edited in partnership settings.
  // If false, current field only have two states: shared or private and can be edited in the widget.
  const editableInSettings =
    accountType !== AccountMappingResource.leadMatches &&
    !Boolean(booleanSharingSettingsColumnsMap[fieldKey]);

  const editSettingsLink = (
    <>
      &nbsp;
      <Link
        style={{ color: muiTheme.palette.white, textDecoration: "underline" }}
        to={`/partnerships/${partnershipId}/settings#general`}
      >
        <FormattedMessage {...i18n.editSettings} />
      </Link>
    </>
  );

  let content;
  switch (status) {
    case "shared":
      const sharedEditableTitle = _.get(
        availableSharedFieldsTooltip,
        fieldKey,
        <FormattedMessage {...i18n.sharedEditatable} />
      );
      content = isSmartField ? (
        editableInSettings ? (
          <>
            {sharedEditableTitle}
            {editSettingsLink}
          </>
        ) : (
          <FormattedMessage {...i18n.alwaysShared} />
        )
      ) : (
        <FormattedMessage {...i18n.shared} />
      );
      break;
    case "private":
      const privateTitle = _.get(
        availablePrivateFieldsTooltip,
        fieldKey,
        <FormattedMessage {...i18n.private} />
      );
      content = (
        <>
          {privateTitle}
          {editableInSettings ? editSettingsLink : null}
        </>
      );
      break;
    case "partiallyShared":
      const partiallySharedTitle = _.get(
        availableSemiPrivacyFieldsTooltip,
        fieldKey,
        ""
      );
      if (Boolean(partiallySharedTitle)) {
        content = (
          <>
            {partiallySharedTitle}
            {editSettingsLink}
          </>
        );
      }
      break;
  }
  return content;
};
