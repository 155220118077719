import { QuickViewFilter } from "components/ui/BaseQuickViews";
import {
  ExtendedAccountMappingResource,
  ThreeSixty,
} from "components/ui/filters/smartView/constants";
import { useSmartViewTableConfig } from "components/ui/filters/smartView/helpers";
import useUserProfile from "hooks/useUserProfile";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Match from "models/Match";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { createContext, ReactNode, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  defaultMapping360Sort,
  Mapping360WithGrowthKPIsSort,
} from "redux/mapping360/defaults";

import {
  defaultAccountMappingFilters,
  defaultAccountMappingFiltersDemo,
  demoFilterOrderList,
  onlyCommonCustomersAccountMappingFilters,
} from "../../screens/AccountMapping/shared/quickFilterPresets";
import { AccountMappingStandardFields } from "../../screens/AccountMapping/shared/types";
import { IGoal } from "../../screens/Mapping360/goalsPresets";
import { FilterType, MatchFilterType, Preset } from "../types";

const MappingFilterContext = createContext<{
  defaultView?: IGoal;
  selectedSmartViewId: number | undefined;
  setDefaultView: SetState<IGoal | undefined>;
  setSelectedSmartViewId: SetState<number | undefined>;
  openedWidget: string | null;
  setOpenedWidget: SetState<string | null>;
  titleWidth: number;
  quickFilterPresets: $TSFixMe;
  unorderedQuickFilters: QuickViewFilter[];
  isSmall?: boolean;
  orderArray: number[];
}>({
  selectedSmartViewId: undefined,
  setDefaultView: () => {},
  setSelectedSmartViewId: () => {},
  openedWidget: null,
  setOpenedWidget: () => {},
  titleWidth: 0,
  quickFilterPresets: undefined,
  unorderedQuickFilters: [],
  isSmall: undefined,
  orderArray: [],
});

export const MappingFilterProvider = ({
  children,
  defaultView: defaultViewProp,
  isSmall = false,
  titleWidth = 0,
  hasGrowthKpisFeature = false,
  partnership,
  viewType,
  fields,
  selectedSmartViewId: selectedSmartViewIdProp,
}: {
  children: ReactNode;
  defaultView?: IGoal;
  isSmall?: boolean;
  titleWidth?: number;
  hasGrowthKpisFeature?: boolean;
  partnership?: Partnership;
  viewType: ExtendedAccountMappingResource;
  fields?: $TSFixMe;
  selectedSmartViewId?: number;
}) => {
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  let { orderArray } = useSmartViewTableConfig({
    accountType: viewType,
  });

  const [selectedSmartViewId, setSelectedSmartViewId] = useState<
    number | undefined
  >(selectedSmartViewIdProp);
  const [openedWidget, setOpenedWidget] = useState<string | null>(null);
  const isGoalBased360Limited = payingFeatures.includes(
    PayingFeature.GoalBased360Limited
  );
  const isGoalBased360Unlocked = payingFeatures.includes(
    PayingFeature.GoalBased360Unlocked
  );
  const isGoalBased360Available =
    isGoalBased360Limited || isGoalBased360Unlocked;
  const [defaultView, setDefaultView] = useState<IGoal | undefined>(
    isGoalBased360Available ? defaultViewProp : undefined
  );

  let quickFilterPresets;
  let unorderedQuickFilters: QuickViewFilter[] = [];

  if (
    viewType === ThreeSixty.threeSixty ||
    viewType === ThreeSixty.nearboundProspects ||
    viewType === ThreeSixty.nearboundAccounts
  ) {
    quickFilterPresets = getQuickFilterPresets(hasGrowthKpisFeature);
    unorderedQuickFilters = isGoalBased360Available
      ? []
      : getQuickViews(quickFilterPresets, isSmall);
  } else {
    quickFilterPresets = [];
    const isLeadImportUnlocked = payingFeatures.includes(
      PayingFeature.LeadImport
    );
    if (partnership && partnership.isDemo) {
      unorderedQuickFilters = defaultAccountMappingFiltersDemo.map((f) => {
        return {
          ...f,
          presets: {
            sort: f.presets.sort,
            filter: (f.presets.filter as FilterType[]).map(
              (item: FilterType) => {
                if (item.type === MatchFilterType.ANY_OF) {
                  let itemWithStringValue = { ...item };
                  itemWithStringValue["value"] = [item["value"].toString()];
                  return itemWithStringValue;
                }
                return item;
              }
            ),
          },
        };
      });
      orderArray = demoFilterOrderList;
    } else {
      unorderedQuickFilters = getAMQuickFilters(
        partnership as Partnership,
        profile.company,
        fields,
        isSmall,
        isLeadImportUnlocked
      );
    }
  }

  return (
    <MappingFilterContext.Provider
      value={{
        defaultView,
        selectedSmartViewId,
        setSelectedSmartViewId,
        openedWidget,
        setOpenedWidget,
        quickFilterPresets,
        unorderedQuickFilters,
        isSmall,
        setDefaultView,
        titleWidth,
        orderArray,
      }}
    >
      {children}
    </MappingFilterContext.Provider>
  );
};

export default MappingFilterContext;

// Helpers

const getQuickFilterPresets = (hasGrowthKpisFeature: boolean) => {
  const sort = hasGrowthKpisFeature
    ? Mapping360WithGrowthKPIsSort
    : defaultMapping360Sort;

  return {
    all: {
      filter: [],
      sort: sort,
    },
    prospectsNoOpports: {
      filter: [
        {
          fieldname: "status",
          type: MatchFilterType.ANY_OF,
          value: [String(Match.STATUS_PROSPECT)],
        },
        { fieldname: "active", type: MatchFilterType.IS, value: false },
      ],
      sort: sort,
    },
    prospectsOpports: {
      filter: [
        {
          fieldname: "status",
          type: MatchFilterType.ANY_OF,
          value: [String(Match.STATUS_PROSPECT)],
        },
        { fieldname: "active", type: MatchFilterType.IS, value: true },
      ],
      sort: sort,
    },
    prospects: {
      filter: [
        {
          fieldname: "status",
          type: MatchFilterType.ANY_OF,
          value: [String(Match.STATUS_PROSPECT)],
        },
      ],
      sort: sort,
    },
    customers: {
      filter: [
        {
          fieldname: "status",
          type: MatchFilterType.ANY_OF,
          value: [String(Match.STATUS_CUSTOMER)],
        },
      ],
      sort: sort,
    },
  };
};

const getQuickViews = (
  presets: { [name: string]: Preset },
  isSmall: boolean = false
) =>
  [
    {
      key: -1,
      presets: presets.all,
      label: <FormattedMessage {...i18n.all} />,
      name: i18n.all,
      hideOnSmallScreens: false,
    },
    {
      key: -2,
      presets: presets.prospectsNoOpports,
      label: <FormattedMessage {...i18n.prospectsNoOpports} />,
      name: i18n.prospectsNoOpports,
      hideOnSmallScreens: true,
    },
    {
      key: -3,
      presets: presets.prospectsOpports,
      label: <FormattedMessage {...i18n.prospectsOpports} />,
      name: i18n.prospectsOpports,
      hideOnSmallScreens: true,
    },
    {
      key: -4,
      presets: presets.prospects,
      label: <FormattedMessage {...i18n.prospects} />,
      name: i18n.prospects,
      hideOnSmallScreens: false,
    },
    {
      key: -5,
      presets: presets.customers,
      label: <FormattedMessage {...i18n.customers} />,
      name: i18n.customers,
      hideOnSmallScreens: false,
    },
  ].filter((item) => !isSmall || !item.hideOnSmallScreens);

const getAMQuickFilters = (
  partnership: Partnership,
  company: Record,
  fields?: AccountMappingStandardFields,
  isSmall: boolean = false,
  isLeadImportUnlocked: boolean = false
) => {
  const partnerHidesOpportunities = fields?.rightActive?.isDisabled;
  const partnerHidesStatus = fields?.rightStatus?.isDisabled;
  const onlyCommonCustomers =
    partnership.onlyCommonCustomersShared() &&
    (partnership.partnerOnlySharingCommonCustomers(company) ||
      !partnership.asymmetricalPartnership);

  let filterList = defaultAccountMappingFilters.filter((value) =>
    isSmall ? !value.hideOnSmallScreens : true
  );
  if (onlyCommonCustomers) {
    filterList = onlyCommonCustomersAccountMappingFilters;
  }
  if (partnerHidesStatus) {
    filterList = filterList.filter(
      (quickFilter) =>
        !quickFilter.presets.filter.find(
          (preset) => preset.fieldname === "rightStatus"
        )
    );
  }
  if (partnerHidesOpportunities) {
    filterList = filterList.filter(
      (quickFilter) =>
        !quickFilter.presets.filter.find(
          (preset) => preset.fieldname === "rightActive"
        )
    );
  }
  if (!isLeadImportUnlocked) {
    filterList = filterList.filter(
      (value) => value.viewType !== AccountMappingResource.leadMatches
    );
  }
  return filterList;
};

// I18N

const i18n = defineMessages({
  all: {
    id: "crm.Ecosystem.QuickViews.all",
    defaultMessage: "All",
  },
  prospectsNoOpports: {
    id: "crm.Ecosystem.QuickViews.prospectsNoOpports",
    defaultMessage: "Prospects without opportunity",
  },
  prospectsOpports: {
    id: "crm.Ecosystem.QuickViews.prospectsOpports",
    defaultMessage: "Prospects with opportunity",
  },
  prospects: {
    id: "crm.Ecosystem.QuickViews.prospects",
    defaultMessage: "Prospects",
  },
  customers: {
    id: "crm.Ecosystem.QuickViews.customers",
    defaultMessage: "Customers",
  },
});
