import { Box } from "@mui/material";
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-premium";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import BaseDataGrid from "components/ui/data-grid/BaseDataGrid";
import { SelectedRowsProvider } from "components/ui/data-grid/SelectedRowsContext";
import { WidgetFooter } from "components/ui/filters/column/ColumnWidgetFooter";
import { FilterWidget } from "components/ui/filters/filter/FilterWidget";
import { ThreeSixty } from "components/ui/filters/smartView/constants";
import SortWidget from "components/ui/filters/sort/SortWidget";
import { QuickSearch } from "components/ui/QuickSearch";
import useAllRecords from "hooks/useAllRecords";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import { useQuickSearch } from "hooks/useQuickSearch";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Integration from "models/Integration";
import Match from "models/Match";
import { PageType } from "models/PageView";
import Partnership from "models/Partnership";
import RecordModel from "models/Record";
import { Ref, useCallback, useContext, useEffect, useMemo } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import countryList from "react-select-country-list";
import { selectAllPartnerships } from "redux/api/selectors";
import {
  apply360MappingColumnOrder,
  Available360MappingColumns,
  default360MappingColumnConfig,
  mapping360ColumnsConfig,
} from "redux/mapping360/defaults";
import { selectView, selectViews } from "redux/mapping360/selectors";
import { addView, loadView, updateView } from "redux/mapping360/thunks";
import {
  applyNearboundAccountsColumnOrder,
  AvailableNearboundAccountsColumns,
  defaultNearboundAccountsColumnConfig,
  nearboundAccountsColumnsConfig,
} from "redux/mapping360NearboundAccounts/defaults";
import {
  selectView as selectViewNearboundAccounts,
  selectViews as selectViewsNearboundAccounts,
} from "redux/mapping360NearboundAccounts/selectors";
import {
  addView as addViewNearboundAccounts,
  loadView as loadViewNearboundAccounts,
  updateView as updateViewNearboundAccounts,
} from "redux/mapping360NearboundAccounts/thunks";
import { Mapping360NearboundAccountsViewParameters } from "redux/mapping360NearboundAccounts/typing";
import {
  ColumnConfigType,
  DataTableType,
  FieldType,
  FilterType,
  MatchFieldType,
  MatchFilterType,
  Preset,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { isCrmFileType } from "screens/Frontoffice/shared/helpers/types";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { AppDispatch } from "store";

import { ColumnWidget } from "../../../shared/components/ColumnWidget";
import MappingFilterContext from "../../../shared/contexts/MappingFilterContext";
import { comparePresetAgainstFields } from "../../../shared/utils";
import {
  IGoal,
  isIGoalPreset,
  mapping360Goals,
  nearboundAccountsGoals,
} from "../goalsPresets";
import {
  GoalBased360Event,
  goalBased360EventName,
  MANUAL_KEY_TO_FILTER,
  MANUAL_KEY_TO_SORT,
  mapping360ColumnsConfigForLegacyPage,
} from "../utils";
import { DemoContent } from "./DemoContent";
import EmptyRowView from "./EmptyRowView";
import HeaderActions from "./HeaderActions";
import { Mapping360Goals } from "./Mapping360Goals";
import QuickViews from "./QuickViews";
import SideTitle from "./SideTitle";
import Title from "./Title";

const getIsImporting = (sources: Integration[]) => {
  // We consider that a source is still importing data
  // if there are no statistics or if the count for all accounts is 0
  let isImporting = false;
  _.each(sources, (source) => {
    if (source.statistics) {
      if (source.statistics.numberOfAccounts === 0) {
        isImporting = true;
      }
    } else {
      isImporting = true;
    }
  });
  return isImporting;
};

type Props = {
  rows?: RecordModel[];
  rowsCount?: number | null;
  filters: FilterType[];
  columns: ColumnConfigType[];
  sort: SortType[];
  fields?: {
    [fieldname: string]: FieldType;
  };
  openDealFields?: {
    [fieldname: string]: FieldType;
  };
  setView: (
    value: Mapping360NearboundAccountsViewParameters
  ) => AsyncThunkAction<void, Mapping360NearboundAccountsViewParameters, {}>;
  selectedSourceId: number | null;
  setSelectedSourceId: (value: number | null) => void;
  fetching?: boolean;
  loadMore: () => void;
  loading?: boolean;
  hiddenRows?: number;
  dialogRef?: Ref<HTMLElement>;
};

const COUNTRY_OPTIONS: Record<string, string> = countryList()
  .getData()
  .reduce((acc: Record<string, string>, country) => {
    acc[country.value] = country.label;
    return acc;
  }, {});

const EcosystemSignalsTableContent = ({
  rows = [],
  rowsCount,
  loading,
  filters,
  columns,
  sort = [],
  selectedSourceId,
  fields,
  fetching,
  loadMore,
  hiddenRows,
  openDealFields,
  // Actions
  setSelectedSourceId,
  setView,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const history = useHistory();
  const service = useMemo(() => new WebhookService(), []);
  const partnerDataLabel = intl.formatMessage(i18n.partnerDataLabel);
  const {
    openedWidget,
    setOpenedWidget,
    defaultView,
    setDefaultView,
    setSelectedSmartViewId,
  } = useContext(MappingFilterContext);
  const { records: sources } = useAllRecords<Integration>("integrations", {
    include: ["statistics"],
  });
  const is360NearboundAccountsUnlocked = useHasPayingFeature(
    PayingFeature.UseNew360,
    profile
  );
  const view = useSelector(
    is360NearboundAccountsUnlocked ? selectViewNearboundAccounts : selectView
  );
  const views = useSelector(
    is360NearboundAccountsUnlocked ? selectViewsNearboundAccounts : selectViews
  );
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];

  const isGoalBased360Limited = useHasPayingFeature(
    PayingFeature.GoalBased360Limited,
    profile
  );
  const isGoalBased360Unlocked = useHasPayingFeature(
    PayingFeature.GoalBased360Unlocked,
    profile
  );
  const isNewProspects360Unlocked = useHasPayingFeature(
    PayingFeature.NewProspects360,
    profile
  );
  const isImporting = getIsImporting(sources);

  const setColumns = useCallback(
    (columns: ColumnConfigType[]) => {
      if (!view) return;
      setView({
        columns: columns,
        id: view.id,
      });
    },
    [setView, view]
  );

  const setFilters = useCallback(
    (filters: FilterType[]) => {
      if (!view) return;
      setView({
        filters,
        id: view.id,
      });
    },
    [setView, view]
  );

  const setSort = useCallback(
    (sort: SortType[]) => {
      if (!view) return;
      setView({
        id: view.id,
        sort,
      });
    },
    [setView, view]
  );

  const { searchValue, handleChangeSearch, handleClearSearch } = useQuickSearch(
    {
      fieldName: Available360MappingColumns.name,
      filters,
      setFilters,
    }
  );

  useEffect(() => {
    if (!view || !!defaultView) {
      return;
    }
    setDefaultView(
      [...mapping360Goals, ...nearboundAccountsGoals].find(
        (goal) => goal.pageType === view?.pageType
      )
    );
  }, [view, defaultView, setDefaultView]);

  const selectQuickFilter = (data: Preset) => {
    if (!view || !fields) return;
    const adjustedPreset = comparePresetAgainstFields(data, fields);
    if (!isIGoalPreset(defaultView)) {
      setView({
        filters: adjustedPreset.filter,
        sort: adjustedPreset.sort,
        id: view.id,
      });
      return;
    }
    setView({
      filters: [
        ...(defaultView.presets.filter ?? []),
        ...adjustedPreset.filter.filter(
          (item) =>
            !(defaultView.presets.filter ?? []).some(
              (defaultItem) => defaultItem.fieldname === item.fieldname
            )
        ),
      ],
      sort: [
        ...(defaultView.presets.sort ?? []),
        ...adjustedPreset.sort.filter(
          (item) =>
            !(defaultView.presets.sort ?? []).some(
              (defaultItem) => defaultItem.fieldname === item.fieldname
            )
        ),
      ],
      id: view.id,
    });
  };

  const isGoalBasedViewEnabled =
    isGoalBased360Limited || isGoalBased360Unlocked;
  let availableFields = isGoalBasedViewEnabled
    ? is360NearboundAccountsUnlocked
      ? nearboundAccountsColumnsConfig
      : mapping360ColumnsConfig
    : mapping360ColumnsConfigForLegacyPage();

  const headerActions = (
    <>
      <HeaderActions
        rows={rows}
        columns={columns}
        filters={filters}
        fields={fields}
        sort={sort}
        selectedSourceId={selectedSourceId}
        setSelectedSourceId={setSelectedSourceId}
      />
      <QuickSearch
        value={searchValue}
        onChange={handleChangeSearch}
        onClear={handleClearSearch}
      />
    </>
  );

  const handleSetDefaultView = (goal: IGoal) => {
    if (!view) return;
    if (defaultView?.id === goal.id) {
      return view.id;
    }
    const eventName = goalBased360EventName(
      GoalBased360Event.GoalSelected,
      goal.name
    );
    track(eventName);
    const selectedViewId = views.find((view) => view.pageType === goal.pageType)
      ?.id;
    const loadFunc = is360NearboundAccountsUnlocked
      ? loadViewNearboundAccounts
      : loadView;
    dispatch(
      loadFunc({
        isGoalBased360Limited: true,
        isGoalBased360Unlocked,
        isNewProspects360Unlocked,
        pageType: goal.pageType,
      })
    );
    setDefaultView(goal);
    setSelectedSmartViewId(undefined);
    return selectedViewId;
  };

  const handlePin = async (pageType: PageType) => {
    const goal = [...mapping360Goals, ...nearboundAccountsGoals].find(
      (goal) => goal.pageType === pageType
    );
    if (!goal) return;
    const clickedView = views.find((view) => view.pageType === pageType);
    const pinnedView = views.find((view) => view.pinned);
    const addFunc = is360NearboundAccountsUnlocked
      ? addViewNearboundAccounts
      : addView;
    const updateFunc = is360NearboundAccountsUnlocked
      ? updateViewNearboundAccounts
      : updateView;

    if (!clickedView) {
      dispatch(addFunc({ pageType, pinned: true })); // creates the missing view, with pinned true
      handleSetDefaultView(goal);
    } else {
      dispatch(updateFunc({ id: clickedView.id, pinned: !clickedView.pinned })); // sets pinned true for the existing view
      if (!clickedView.pinned) {
        handleSetDefaultView(goal);
      }
    }

    if (pinnedView && pinnedView.id !== clickedView?.id) {
      dispatch(updateFunc({ id: pinnedView.id, pinned: false })); // sets pinned false for previously pinned view
    }
  };

  const isExpandToNewMarketsView =
    defaultView?.pageType === PageType.mapping360Expand;
  const isExpandToNewMarketsDemoView =
    isExpandToNewMarketsView && !isNewProspects360Unlocked;
  const isDemoView =
    isExpandToNewMarketsDemoView ||
    (isGoalBased360Limited &&
      !isGoalBased360Unlocked &&
      defaultView?.pageType !== PageType.mapping360Influence &&
      defaultView?.pageType !== PageType.mapping360InfluenceNearboundAccounts);

  const handleOpenDrawer = useCallback(
    (
      params: GridRowParams,
      event: MuiEvent<React.MouseEvent<HTMLElement>>,
      _details: GridCallbackDetails
    ) => {
      if ((event.target as HTMLElement).classList.contains("MuiDataGrid-row")) {
        return;
      }
      if (isExpandToNewMarketsView) {
        history.push({
          hash: `np-${params.row.countryIsoCode ?? ""}-${
            params.row.smartDomainKey
          }`,
        });
      } else {
        const recordId = is360NearboundAccountsUnlocked
          ? +params.row.rawCompanyId
          : params.row.id;
        const tab = (event.target as HTMLElement)?.closest(
          `div[data-field='${
            is360NearboundAccountsUnlocked
              ? "openOpportunityFor"
              : "partnerOpportunities"
          }']`
        )
          ? String(Match.STATUS_PROSPECT)
          : String(Match.STATUS_CUSTOMER);
        history.push({ hash: `na-${recordId}&tab=${tab}` });
      }
      service.track({
        profile: profile,
        eventName: WebhookEventName.RevealOpenedDetailDrawerFrom360Mapping,
        crmAccount: params.row,
      });
    },
    [
      service,
      history,
      profile,
      isExpandToNewMarketsView,
      is360NearboundAccountsUnlocked,
    ]
  );

  const partnershipOptions = useMemo(
    () =>
      partnerships
        .filter((partnership) => {
          const dataSharingStatus = partnership.getDataSharingStatus(
            profile.company
          );
          return (
            partnership.isGhost() ||
            Partnership.isSharingWhitespace(dataSharingStatus)
          );
        })
        .reduce((acc, partnership) => {
          acc[partnership.id] = partnership.getPartner(profile).name;
          return acc;
        }, {} as Record<number, string>),
    [partnerships, profile]
  );
  const partnershipOptionsForNearboundAccounts = useMemo(
    () =>
      partnerships
        .filter((partnership) => {
          const dataSharingStatus = partnership.getDataSharingStatus(
            profile.company
          );
          return (
            partnership.isGhost() ||
            Partnership.isSharingOverlapping(dataSharingStatus)
          );
        })
        .reduce((acc, partnership) => {
          acc[partnership.id] = partnership.getPartner(profile).name;
          return acc;
        }, {} as Record<number, string>),
    [partnerships, profile]
  );

  const enrichedFieldsForFilterAndSort:
    | {
        [fieldname: string]: FieldType;
      }
    | undefined =
    isExpandToNewMarketsView && !!fields && Object.values(fields).length > 0
      ? {
          ...fields,
          countryIsoCode: {
            allowedOperations: [MatchFilterType.ANY_OF],
            label: intl.formatMessage(i18n.country),
            type: MatchFieldType.PICKLIST,
            options: COUNTRY_OPTIONS,
            smartField: false,
            fullyImported: true,
            isDisabled: false,
            noSide: true,
            isPrivate: false,
            partnerField: false,
            sort: false,
            filter: true,
            column: false,
          },
          averageHeadcount: {
            label: intl.formatMessage(i18n.numberOfEmployees),
            type: MatchFieldType.NUMBER,
            options: {},
            smartField: false,
            fullyImported: true,
            isDisabled: false,
            noSide: true,
            isPrivate: false,
            partnerField: false,
            sort: true,
            filter: false,
            column: false,
          },
          customerPresence: {
            allowedOperations: [MatchFilterType.CONTAINS_ANY],
            label: intl.formatMessage(i18n.isACustomerof),
            type: MatchFieldType.MULTI_PICKLIST,
            options: partnershipOptions,
            smartField: false,
            fullyImported: true,
            isDisabled: false,
            noSide: true,
            isPrivate: false,
            partnerField: false,
            sort: false,
            filter: true,
            column: false,
          },
          customerPresenceCount: {
            label: intl.formatMessage(i18n.numberIsCustomerOf),
            type: MatchFieldType.NUMBER,
            options: {},
            smartField: false,
            fullyImported: true,
            isDisabled: false,
            noSide: true,
            isPrivate: false,
            partnerField: false,
            sort: true,
            filter: false,
            column: false,
          },
        }
      : is360NearboundAccountsUnlocked &&
        !isExpandToNewMarketsView &&
        !!fields &&
        Object.values(fields).length > 0
      ? {
          ...fields,
          ...(!!fields[AvailableNearboundAccountsColumns.CustomerOfIds] && {
            [AvailableNearboundAccountsColumns.CustomerOfIds]: {
              ...fields[AvailableNearboundAccountsColumns.CustomerOfIds],
              options: partnershipOptionsForNearboundAccounts,
            },
          }),
          ...(!!fields[
            AvailableNearboundAccountsColumns.OpenOpportunityForIds
          ] && {
            [AvailableNearboundAccountsColumns.OpenOpportunityForIds]: {
              ...fields[
                AvailableNearboundAccountsColumns.OpenOpportunityForIds
              ],
              options: partnershipOptionsForNearboundAccounts,
            },
          }),
        }
      : fields;
  const enrichedFieldsWithActions: {
    [fieldname: string]: FieldType;
  } = {
    ...fields,
    [Available360MappingColumns.actions]: {
      label: intl.formatMessage(
        is360NearboundAccountsUnlocked ? i18n.collabs : i18n.actions
      ),
      type: MatchFieldType.UNKNOWN,
      options: {},
      smartField: false,
      fullyImported: true,
      isDisabled: false,
      noSide: true,
      isPrivate: false,
      partnerField: false,
      sort: false,
      filter: false,
      column: false,
      hideHeaderMenu: true,
    },
    [AvailableNearboundAccountsColumns.NearboundProspectsActions]: {
      label: intl.formatMessage(i18n.collabs),
      type: MatchFieldType.UNKNOWN,
      options: {},
      smartField: false,
      fullyImported: true,
      isDisabled: false,
      noSide: true,
      isPrivate: false,
      partnerField: false,
      sort: false,
      filter: false,
      column: false,
      hideHeaderMenu: true,
    },
  };

  const enrichedColumns =
    columns.length > 0 && !isExpandToNewMarketsView
      ? columns.slice(0, 1).concat(
          [
            {
              key: Available360MappingColumns.actions,
              width: is360NearboundAccountsUnlocked
                ? 160
                : profile.ownsPipeline
                ? 78
                : 64,
              frozen: true,
              fixedPosition: 2,
              removable: false,
            },
          ],
          columns.slice(1)
        )
      : columns.length > 0 && isExpandToNewMarketsView
      ? columns.slice(0, 1).concat(
          [
            {
              key: AvailableNearboundAccountsColumns.NearboundProspectsActions,
              width: 160,
              frozen: true,
              fixedPosition: 2,
              removable: false,
            },
          ],
          columns.slice(1)
        )
      : columns;

  return (
    <>
      <Box m={2.5}>
        <Title />
      </Box>
      <SelectedRowsProvider>
        {isGoalBasedViewEnabled && (
          <Box className={classes.goalsContainer}>
            <Mapping360Goals
              onSetDefaultView={handleSetDefaultView}
              onPin={handlePin}
              sources={sources}
              selectedSourceId={selectedSourceId}
            />
          </Box>
        )}
        {isDemoView && <DemoContent />}
        {!isDemoView && (
          <BaseDataGrid
            noSide={isGoalBasedViewEnabled}
            manualSortKeys={MANUAL_KEY_TO_SORT}
            manualFilterKeys={MANUAL_KEY_TO_FILTER}
            headerHeight={isGoalBasedViewEnabled ? 36 : undefined}
            rows={rows}
            rowsCount={rowsCount ?? 0}
            fields={enrichedFieldsWithActions}
            headerTooltips={
              isExpandToNewMarketsView
                ? {
                    [Available360MappingColumns.createdAt]: (
                      <FormattedMessage
                        {...i18n.openFirstIdentificationDateTooltip}
                      />
                    ),
                  }
                : undefined
            }
            openDealFields={openDealFields}
            fetching={fetching}
            loadMore={loadMore}
            columns={enrichedColumns}
            setColumns={setColumns}
            filters={filters}
            setFilters={setFilters}
            filterWidget={
              <FilterWidget
                defaultFilters={
                  isIGoalPreset(defaultView)
                    ? defaultView?.presets.filter
                    : undefined
                }
                defaultFilterTooltip={i18n.defaultFilterTooltip}
                partnerName={partnerDataLabel}
                fields={enrichedFieldsForFilterAndSort}
                filters={filters}
                onFilterUpdate={setFilters}
                openedWidget={openedWidget}
                setOpenedWidget={setOpenedWidget}
                viewType={DataTableType.MAPPING_360}
                accountType={
                  isExpandToNewMarketsView
                    ? ThreeSixty.nearboundProspects
                    : is360NearboundAccountsUnlocked
                    ? ThreeSixty.nearboundAccounts
                    : ThreeSixty.threeSixty
                }
              />
            }
            rightSideActionsWidget={
              <ColumnWidget
                columns={columns}
                headerHeight={isGoalBasedViewEnabled ? 36 : undefined}
                orderColumns={
                  is360NearboundAccountsUnlocked
                    ? applyNearboundAccountsColumnOrder
                    : apply360MappingColumnOrder
                }
                defaultColumnConfig={
                  is360NearboundAccountsUnlocked
                    ? defaultNearboundAccountsColumnConfig
                    : default360MappingColumnConfig
                }
                fields={(fields as { [key: string]: FieldType }) ?? {}}
                setColumns={setColumns}
                availableFieldsDefaultConfig={availableFields}
                nonDeactivatableColumnsList={[
                  AvailableNearboundAccountsColumns.Name,
                ]}
                footer={<WidgetFooter integration={sources[0]} />}
              />
            }
            sort={sort}
            setSort={setSort}
            sortWidget={
              <SortWidget
                partnerName={partnerDataLabel}
                fields={enrichedFieldsForFilterAndSort}
                sort={sort}
                onSortUpdate={setSort}
                openedWidget={openedWidget}
                setOpenedWidget={setOpenedWidget}
                viewType={DataTableType.MAPPING_360}
              />
            }
            quickViews={
              <QuickViews
                onSelect={selectQuickFilter}
                sources={sources}
                selectedSourceId={selectedSourceId}
              />
            }
            emptyRowsView={
              <EmptyRowView
                loading={loading}
                isImporting={isImporting}
                hasFilters={filters.length > 0}
              />
            }
            setOpenedWidget={setOpenedWidget}
            smallScreenText={
              <>
                <FormattedHTMLMessage {...i18n.screenToSmall} />
              </>
            }
            rightChipName={partnerDataLabel}
            loading={loading}
            availableSources={
              defaultView?.pageType === PageType.mapping360Expand
                ? []
                : sources.filter(
                    (integration) =>
                      !isCrmFileType(integration.provider) ||
                      (integration.mappingConfigured &&
                        !integration.deletedAt &&
                        !integration.disabledAt)
                  )
            }
            setSelectedSourceId={setSelectedSourceId}
            selectedSourceId={selectedSourceId}
            // title={<Title />}
            leftSideTitle={<SideTitle side="left" />}
            rightSideTitle={<SideTitle side="right" />}
            actions={headerActions}
            hiddenRows={hiddenRows}
            onRowClick={handleOpenDrawer}
            viewType={DataTableType.MAPPING_360}
          />
        )}
      </SelectedRowsProvider>
    </>
  );
};

export default EcosystemSignalsTableContent;

const useStyles = makeStyles()((theme) => ({
  goalsContainer: {
    margin: theme.spacing(0, 2.5),
    [theme.breakpoints.down("lg")]: {
      margin: theme.spacing(2.5, 3, 0, 3),
    },
  },
}));

/// I18N

const i18n = defineMessages({
  actions: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.actions",
    defaultMessage: "Actions",
  },
  collabs: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.collabs",
    defaultMessage: "Collabs",
  },
  country: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.country",
    defaultMessage: "Country",
  },
  defaultFilterTooltip: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.defaultFilterTooltip",
    defaultMessage:
      'Filters are pre-applied to this Goal. Go to "Identify my overlaps" to view all your accounts without pre-applied filters.',
  },
  openFirstIdentificationDateTooltip: {
    id:
      "crm.Ecosystem.EcosystemSignalsTableContent.openFirstIdentificationDateTooltip",
    defaultMessage: "Date the new prospect was first identified.",
  },
  partnerDataLabel: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.partnerDataLabel",
    defaultMessage: "Partner",
  },
  screenToSmall: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.screenToSmall",
    defaultMessage: `
      Your screen is too small to display My Accounts table.
      <br/><br/>
      Please try it from a wider device.
    `,
  },
  isACustomerof: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.isACustomerof",
    defaultMessage: "Is a customer of",
  },
  numberOfEmployees: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.numberOfEmployees",
    defaultMessage: "# of employees",
  },
  numberIsCustomerOf: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.numberIsCustomerOf",
    defaultMessage: "# Is customer of",
  },
  requestButton: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.requestButton",
    defaultMessage: "Request 360° New Prospects",
  },
  strategicAccount: {
    id: "crm.Ecosystem.EcosystemSignalsTableContent.strategicAccount",
    defaultMessage: "Is strategic",
  },
});
