import {
  array,
  boolean,
  constant,
  guard,
  iso8601,
  nullable,
  number,
  object,
  optional,
  string,
} from "decoders";
import {
  FilterType,
  PersistedColumnType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  columnDecoder,
  filterDecoder,
  sortDecoder,
} from "screens/Frontoffice/screens/DataTables/shared/types/decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

export enum PageType {
  partnerAnalytics = 1,
  pipeline = 2,
  mapping360Overlaps = 3,
  mapping360Sourcing = 4,
  mapping360Influence = 5,
  mapping360Expand = 6,
  mapping360Upsell = 7,
  mapping360CoSell = 8,
  mapping360NonGoalBased = 9,
  pipelineAttribute = 10,
  mapping360OverlapsNearboundAccounts = 11,
  mapping360SourcingNearboundAccounts = 12,
  mapping360InfluenceNearboundAccounts = 13,
  mapping360UpsellNearboundAccounts = 15,
  mapping360CoSellNearboundAccounts = 16,
  mapping360NonGoalBasedNearboundAccounts = 17,
}

const apiPageViewDecoder = object({
  type: constant("page_views" as const),
  id: string,
  attributes: object({
    columns: array(columnDecoder),
    filter_order_list: nullable(array(number)),
    filters: array(filterDecoder),
    partnership_id: nullable(number),
    user_id: number,
    sort: array(sortDecoder),
    created_at: nullable(iso8601),
    updated_at: nullable(iso8601),
    last_visited_at: nullable(iso8601),
    page_type: number,
    pinned: optional(nullable(boolean)),
  }),
});

const apiPageViewGuard = guard(apiPageViewDecoder);

class PageView extends Record<"page_views"> {
  columns: PersistedColumnType[];
  filterOrderList?: number[] | null;
  filters: FilterType[];
  lastVisitedAt?: Date | null;
  sort: SortType[];
  userId: number;
  pageType: PageType;
  pinned?: boolean | null;

  constructor(resource: JSONAPIResourceOrRecord<"page_views">) {
    super(resource);
    const { attributes } = apiPageViewGuard(resource);
    this.columns = attributes.columns;
    this.filterOrderList = attributes.filter_order_list;
    this.filters = attributes.filters;
    this.lastVisitedAt = attributes.last_visited_at;
    this.sort = attributes.sort;
    this.userId = attributes.user_id;
    this.pageType = attributes.page_type;
    this.pinned = attributes.pinned;
  }
}

export default PageView;
