import {
  FieldType,
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { MatchFilterType } from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  prepareReportSort,
  stripFilters,
} from "screens/Frontoffice/screens/DataTables/shared/utils";
import {
  snakeCaseNameForNearboundAccountField,
  snakeCaseWithDoubleUnderscore,
} from "screens/Frontoffice/shared/helpers/snakeCase";

const prepareReportFilters = (
  filters: FilterType[],
  sourceId?: number | null,
  fields?: {
    [fieldname: string]: FieldType;
  }
): FilterType[] => {
  const result = stripFilters(filters).map((filterItem) => ({
    ...filterItem,
    fieldname:
      (fields?.[filterItem.fieldname]?.jsonPath?.length ?? 0) > 0
        ? snakeCaseNameForNearboundAccountField(
            filterItem.fieldname,
            fields?.[filterItem.fieldname].jsonPath ?? []
          )
        : snakeCaseWithDoubleUnderscore(filterItem.fieldname),
  }));
  if (sourceId) {
    result.push({
      value: sourceId,
      fieldname: "integration",
      type: MatchFilterType._NO_OPERATOR,
    });
  }
  return result;
};

export const prepareReportParameters = (
  columns: { key: string }[],
  filters: FilterType[],
  sort: SortType[],
  sourceId?: number | null,
  fields?: {
    [fieldname: string]: FieldType;
  }
) => ({
  columns: columns.map((col) =>
    (fields?.[col.key]?.jsonPath?.length ?? 0) > 0
      ? snakeCaseNameForNearboundAccountField(
          col.key,
          fields?.[col.key].jsonPath ?? []
        )
      : snakeCaseWithDoubleUnderscore(col.key)
  ),
  filters: prepareReportFilters(filters, sourceId, fields),
  sort: prepareReportSort(sort, fields),
});
