import { Box } from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { MessageOutlined, Plus } from "components/icons";
import Button from "components/ui/Button";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { T } from "components/ui/Typography";
import * as Page from "config/routes/Pages";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUpdateAttributesForAllRecords from "hooks/useUpdateAttributesForAllRecords";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import { JSONAPIResource } from "models/types";
import { MouseEvent, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { create } from "redux/api/thunks";
import { NotificationActionType } from "redux/notifications/typing";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { PipelineEvent, PipelineItemSource } from "tracking";

type Props = {
  partnerName: string | null;
  match: Match;
  hasPartnerConnection: boolean;
};

// TODO: the component got too complex, extract the logic into a hook
const AccountMappingBookmark = ({
  partnerName,
  match,
  hasPartnerConnection,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const accountId = match.rawCompanyId;

  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const webHookService = new WebhookService();
  const history = useHistory();

  const updateAllMatches = useUpdateAttributesForAllRecords("matches");

  const handleCancelEvent = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const createPartnerConnection = async (e: MouseEvent) => {
    handleCancelEvent(e);
    if (hasPartnerConnection) {
      return;
    }
    if (match.partnershipId === "demo") {
      pushNotification({ ...i18n.notOnDemo });
      return;
    }
    setLoading(true);
    const result = await dispatch(
      create({
        type: "partner_connections",
        attributes: {
          user_id: profile.id,
          raw_company_id: +accountId,
          partnership_id: match.partnershipId,
          source: PipelineItemSource.accountMapping,
          partner_raw_company_id: null,
        },
        options: { include: ["discussion_participants"] },
      })
    );
    if (isFulfilled(result)) {
      const payload = result.payload as JSONAPIResponse<"partner_connections">;
      const partnerConnection = new PartnerConnection(payload.data);
      history.push({ search: `?discussion=${partnerConnection.id}` });
      pushNotification(
        {
          status: NotificationStatus.success,
          message: i18n.partnerConnectionCreated,
        },
        {
          accountName: match.leftName,
          partnershipId: match.partnershipId,
          partnerName: partnerName,
        },
        {
          type: NotificationActionType.addedToPipelineActions,
          partnerConnections: [partnerConnection],
        }
      );
      updateAllMatches(
        { hasPartnerConnection: true },
        { partnershipId: match.partnershipId, rawCompanyId: +accountId }
      );
      track(PipelineEvent.addToPipeline, {
        from: Page.toString(Page.Enum.AccountMapping),
        bulk: false,
        number_created: 1,
      });
    }
    if (isRejected(result)) {
      if (result.error.message === "409") {
        pushNotification(i18n.partnerConnectionsCreationDuplicated, {
          accountName: match.leftName,
          partnerName: partnerName,
        });
      } else {
        pushNotification(i18n.partnerConnectionCreationFailed, {
          accountName: match.leftName,
          partnerName: partnerName,
        });
      }
    }
    setLoading(false);
  };

  const handleOpenCollabDrawer = async (e: MouseEvent) => {
    setLoading(true);
    handleCancelEvent(e);
    const service = new JSONAPIService("partner_connections");
    let res;
    try {
      res = await service.rawPost<{
        data: JSONAPIResource<"partner_connections">;
      }>("", "latest-partner-connection/", {
        data: {
          type: "partner_connections",
          attributes: {
            raw_company_id: match.rawCompanyId,
            partnership_id: match.partnershipId,
          },
        },
      });
    } catch (error) {
      pushNotification(i18n.partnerConnectionFetchFailed);
      return;
    } finally {
      setLoading(false);
    }
    const partnerConnection = new PartnerConnection(res.data.data);
    webHookService.track({
      profile: profile,
      eventName: WebhookEventName.RevealOpenedMessageDrawer,
      partnerConnection: partnerConnection,
      rawCompanyId: partnerConnection.rawCompanyId,
      rawCompanyProviderKey: partnerConnection.rawCompanyProviderKey,
      companyId: partnerConnection.companyId,
      partnershipId: partnerConnection.partnershipId,
    });
    track("Opened message drawer");
    history.push({ search: `?discussion=${res.data.data.id}` });
  };

  return (
    <>
      {hasPartnerConnection && (
        <Button
          disabled={loading}
          label={i18n.openCollab}
          loading={loading}
          LeftIcon={MessageOutlined}
          variant="tertiary"
          onClick={handleOpenCollabDrawer}
          size="xSmall"
        />
      )}
      {!hasPartnerConnection && (
        <Button
          disabled={loading}
          label={
            <Box className={classes.alpha}>
              <T>
                <Plus className={classes.icon} />
                <FormattedMessage {...i18n.addCollab} />
              </T>
            </Box>
          }
          loading={loading}
          onClick={createPartnerConnection}
          size="xSmall"
          variant="quinary"
          classes={{
            btn: classes.quinaryButton,
            content: classes.quinaryButtonContent,
          }}
        />
      )}
    </>
  );
};

export default AccountMappingBookmark;

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
  icon: {
    height: 12,
    marginRight: 4,
    width: 12,
  },
  quinaryButton: {
    "&:hover": {
      backgroundColor: `${theme.palette.taupe} !important`,
    },
  },
  quinaryButtonContent: {
    paddingLeft: 4,
    paddingRight: 6,
  },
}));

const i18n = defineMessages({
  addCollab: {
    id: "bookmarks.AccountMappingBookmark.addCollab",
    defaultMessage: "Add collab",
  },
  notOnDemo: {
    id: "bookmarks.AccountMappingBookmark.notOnDemo",
    defaultMessage: "Not available on demo",
  },
  openCollab: {
    id: "bookmarks.AccountMappingBookmark.openCollab",
    defaultMessage: "Open collab",
  },
  partnerConnectionCreationFailed: {
    id: "bookmarks.AccountMappingBookmark.partnerConnectionCreationFailed",
    defaultMessage:
      "{accountName} could not be added to Collaborate with {partnerName}",
  },
  partnerConnectionFetchFailed: {
    id: "bookmarks.AccountMappingBookmark.partnerConnectionFetchFailed",
    defaultMessage: "Something went wrong, could not open collab",
  },
  partnerConnectionsCreationDuplicated: {
    id: "bookmarks.AccountMappingBookmark.partnerConnectionsCreationDuplicated",
    defaultMessage:
      "{accountName} x {partnerName} is already added to Collaborate. It can't be added twice",
  },
  partnerConnectionCreated: {
    id: "bookmarks.AccountMappingBookmark.partnerConnectionCreated",
    defaultMessage:
      "{accountName} has successfully been added to Collaborate with {partnerName}",
  },
});
